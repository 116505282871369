// You can write more code here

import { numberWithCommas } from "@/ota-slots/utils/formatter";

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class StoreListItem extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? -134, y ?? -38);

        // listing_card
        const listing_card = scene.add.image(134, 38, "listing-card");
        this.add(listing_card);

        // coins
        const coins = scene.add.image(-14, 21, "coins");
        coins.scaleX = 0.6921693034428194;
        coins.scaleY = 0.6921693034428194;
        this.add(coins);

        // goldAmount
        const goldAmount = scene.add.text(-29, 36, "", {});
        goldAmount.text = "9,200,000";
        goldAmount.setStyle({
            "fontFamily": "Oxanium",
            "fontSize": "24px",
            "fontStyle": "bold",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 4,
            "shadow.blur": 1,
            "shadow.stroke": true,
        });
        goldAmount.setLineSpacing(1);
        this.add(goldAmount);

        // button
        const button = scene.add.container(195, 41);
        this.add(button);

        // buttonBackground
        const buttonBackground = scene.add.image(32.5, 0, "green-button");
        button.add(buttonBackground);

        // star
        const star = scene.add.image(-9.5, -2, "star");
        star.scaleX = 0.6006730974396951;
        star.scaleY = 0.6006730974396951;
        button.add(star);

        // starAmount
        const starAmount = scene.add.text(28, -16, "", {});
        starAmount.text = "1";
        starAmount.setStyle({
            "align": "center",
            "fontFamily": "Oxanium",
            "fontSize": "24px",
            "fontStyle": "bold",
            "stroke": "#000",
            "strokeThickness": 1,
            "shadow.offsetY": 4,
            "shadow.blur": 1,
            "shadow.stroke": true,
        });
        starAmount.setWordWrapWidth(144);
        button.add(starAmount);

        this.goldAmount = goldAmount;
        this.buttonBackground = buttonBackground;
        this.starAmount = starAmount;
        this.button = button;

        /* START-USER-CTR-CODE */
        buttonBackground.setInteractive({ useHandCursor: true }).on("pointerdown", this.onClick, this);
        /* END-USER-CTR-CODE */
    }

    private goldAmount: Phaser.GameObjects.Text;
    private buttonBackground: Phaser.GameObjects.Image;
    private starAmount: Phaser.GameObjects.Text;
    private button: Phaser.GameObjects.Container;

    /* START-USER-CODE */
    public setStoreData(data: { coins: string; stars: string }): void {
        this.goldAmount.setText(numberWithCommas(+data.coins));
        this.starAmount.setText(data.stars);
    }

    onClick(): void {
        this.emit("click");
        this.buttonBackground.disableInteractive();
        this.scene.time.delayedCall(3000, () => {
            this.buttonBackground.setInteractive({ useHandCursor: true });
        });
    }

    destroy() {
        this.buttonBackground.off("pointerdown", this.onClick, this);
        super.destroy();
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
