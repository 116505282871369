declare global {
    interface Window {
        imota?: ImotaWebView;
    }
}

interface ImotaWebView {
    send: (message: { method: string; params?: Object }) => void;
}

type ImotaWebViewEventType = "purchase-product" | "verify-purchase-successful";

export const purchaseProduct = (productId: string) => {
    if (!window.imota) return;
    window.imota.send({
        method: "purchase-product" as ImotaWebViewEventType,
        params: { productId },
    });
};

export const purchaseVerificationSuccessful = () => {
    if (!window.imota) return;
    window.imota.send({
        method: "verify-purchase-successful" as ImotaWebViewEventType,
    });
};
