import { components } from "@/lib/api/telegram-backend/generated/schema";
import Phaser from "phaser";

export type PickBonusType = components["schemas"]["SlotMachinePiggyJackpotReward"]["type"];

const basicTypes = ["mini", "minor", "major", "grand", "upgrade_chance"] as PickBonusType[];

export const randomPickBonus = (length: number, isUpgrade: boolean) => {
    const nonUpgradeTypes = basicTypes.filter((t) => t !== "upgrade_chance");

    if (isUpgrade) {
        const randomList: PickBonusType[] = [];
        // Add 3 'upgrade' types at the beginning
        for (let i = 0; i < length; i++) {
            let randomType = Phaser.Math.RND.pick(basicTypes);
            const totalUpgrade = randomList.filter((t) => t === "upgrade_chance").length;

            if (i > 1 && i <= 8 && totalUpgrade < 3) {
                randomType = Phaser.Math.RND.pick(["upgrade_chance", "upgrade_chance", randomType]);
            }
            randomList.push(randomType);
        }

        return randomList;
    } else {
        const randomList: PickBonusType[] = [];
        for (let i = 0; i < length; i++) {
            let randomType = Phaser.Math.RND.pick(basicTypes);
            const totalUpgrade = randomList.filter((t) => t === "upgrade_chance").length;
            if (randomType === "upgrade_chance" && totalUpgrade >= 2) {
                randomType = Phaser.Math.RND.pick(nonUpgradeTypes);
            }
            randomList.push(randomType);
        }

        return randomList;
    }
};
