// player config
export const PLAYER_LIVES = 3;
export const PLAYER_HEALTH = 10;
// player bullet config
export const PLAYER_BULLET_DAMAGE = 1;
export const PLAYER_BULLET_SPEED = 500;
export const PLAYER_BULLET_INTERVAL = 500;
export const PLAYER_BULLET_LIFESPAN = 3;
export const PLAYER_BULLET_MAX_COUNT = 10;
export const PLAYER_MOVEMENT_HORIZONTAL_VELOCITY = 20;

// creep config
export const CREEP_SCORE = 100;
export const CREEP_HEALTH = 2;
export const CREEP_MOVEMENT_HORIZONTAL_VELOCITY = 9;
// creep weapon config
export const CREEP_WEAPON_DAMAGE = 1;
export const CREEP_WEAPON_INTERVAL = 1000;
// mining config
export const MINING_PROGRESS_DURATION = 10000;
export const MINING_COIN_RATE_PER_MINUTE = 100;
export const MINING_RMX_RATE_PER_MINUTE = 10;
// creep group config
export const CREEP_GROUP_SPAWN_INTERVAL = 6000;
export const CREEP_GROUP_SPAWN_START = 1000;
export const CREEP_GROUP_SPAWN_MAX_COUNT = 10;
export const CREEP_GROUP_SPAWN_MIN_COUNT = 10;

// component config
export const COMPONENT_MOVEMENT_VERTICAL_DRAG = 0.01;
export const COMPONENT_MOVEMENT_VERTICAL_MAX_VELOCITY = 200;
export const COMPONENT_MOVEMENT_HORIZONTAL_DRAG = 0.01;
export const COMPONENT_MOVEMENT_HORIZONTAL_MAX_VELOCITY = 200;
