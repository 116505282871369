/**
 * A simple component for keeping track of the health of a game object.
 */
export class HealthComponent {
    public startingLife: number;
    public currentLife: number;
    public isDead: boolean;

    constructor(life: number) {
        this.startingLife = life;
        this.currentLife = life;
        this.isDead = false;
    }

    get life(): number {
        return this.currentLife;
    }

    get dead(): boolean {
        return this.isDead;
    }

    reset(): void {
        this.currentLife = this.startingLife;
        this.isDead = false;
    }

    hit(damage: number): void {
        if (this.isDead) {
            return;
        }

        this.currentLife -= damage;
        if (this.currentLife <= 0) {
            this.isDead = true;
        }
    }

    die(): void {
        this.currentLife = 0;
        this.isDead = true;
    }
}
