// You can write more code here

/* START OF COMPILED CODE */

import Jackpot from "../prefabs/normal-spin/Jackpot";
import Reels from "../prefabs/normal-spin/Reels";
import SpinButton from "../prefabs/normal-spin/SpinButton";
import TotalBet from "../prefabs/normal-spin/TotalBet";
import SlotsBalance from "../prefabs/normal-spin/SlotsBalance";
import TotalWin from "../prefabs/normal-spin/TotalWin";
import LootBox from "../prefabs/normal-spin/LootBox";
import Store from "../../shared/prefabs/telegram-star/Store";
/* START-USER-IMPORTS */
import {
    SlotMachineResponse,
    spinPiggyJackpot,
    SpinPiggyJackpotResponse,
    spinSlotMachine,
} from "@/lib/api/telegram-backend/client";
import WinningPopup from "../prefabs/popup/WinningPopup";
import { DEPTH_PRIORITY, SCENE_KEYS } from "@/ota-slots/constants";
import { getWinType } from "../utils/get-win-type";
import { setupBackground } from "../helpers/setup-background";
import PushComponent from "@/ota-mining/game/components/animations/PushComponent";
import { EventBus, EVENTS } from "@/lib/event-bus";
import ActionDialog from "@/shared/prefabs/action-dialog/ActionDialog";
import { getLootBoxInfo } from "@/lib/api/telegram-backend/methods/loot-box";
import { LoginData } from "@/shared/registry/user";
/* END-USER-IMPORTS */

export default class Game extends Phaser.Scene {
    constructor() {
        super("SlotsGame");

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // bg
        const bg = this.add.image(195, 422, "slots_bg");
        bg.visible = false;

        // bottom_console_png
        this.add.image(195, 675, "normal_spin", "bottom console.png");

        // reel_png
        this.add.image(195, 335, "normal_spin", "reel.png");

        // top_console_png
        this.add.image(195, 73, "normal_spin", "top console.png");

        // jackpot
        const jackpot = new Jackpot(this, 195, 120);
        this.add.existing(jackpot);

        // reels
        const reels = new Reels(this, 10, 227);
        this.add.existing(reels);

        // spinButton
        const spinButton = new SpinButton(this, 0, 710);
        this.add.existing(spinButton);

        // totalBet
        const totalBet = new TotalBet(this, 108, 703);
        this.add.existing(totalBet);

        // maxBetButton
        const maxBetButton = this.add.image(348, 786, "buttons_interface", "max_bet_01.png");
        maxBetButton.scaleX = 0.3;
        maxBetButton.scaleY = 0.3;

        // slotsBalance
        const slotsBalance = new SlotsBalance(this, 139, 512);
        this.add.existing(slotsBalance);

        // totalWin
        const totalWin = new TotalWin(this, 189, 8);
        this.add.existing(totalWin);
        totalWin.visible = false;

        // lootBox
        const lootBox = new LootBox(this, 187, 612);
        this.add.existing(lootBox);

        // menu
        const menu = this.add.image(36, 40, "buttons_interface", "back_btn.png");

        // menuLabel
        const menuLabel = this.add.text(35, 72, "", {});
        menuLabel.setOrigin(0.5, 0.5);
        menuLabel.text = "Back";
        menuLabel.setStyle({
            "fontFamily": "Oxanium",
            "fontSize": "14px",
            "fontStyle": "bold",
            "stroke": "#000",
            "strokeThickness": 1,
        });

        // productsText
        const productsText = this.add.text(138, 574, "", {});
        productsText.setOrigin(0.5, 0.5);
        productsText.text = "Products";
        productsText.setStyle({ "fontFamily": "Oxanium", "fontStyle": "bold" });

        // remisWheelText
        const remisWheelText = this.add.text(248, 574, "", {});
        remisWheelText.setOrigin(0.5, 0.5);
        remisWheelText.visible = false;
        remisWheelText.text = "Remis Wheel";
        remisWheelText.setStyle({ "fontFamily": "Oxanium", "fontStyle": "bold" });

        // storePopup
        const storePopup = new Store(this, 195, 95);
        this.add.existing(storePopup);
        storePopup.visible = false;

        this.reels = reels;
        this.spinButton = spinButton;
        this.totalBet = totalBet;
        this.maxBetButton = maxBetButton;
        this.slotsBalance = slotsBalance;
        this.totalWin = totalWin;
        this.lootBox = lootBox;
        this.menu = menu;
        this.productsText = productsText;
        this.remisWheelText = remisWheelText;
        this.storePopup = storePopup;

        this.events.emit("scene-awake");
    }

    private reels!: Reels;
    private spinButton!: SpinButton;
    private totalBet!: TotalBet;
    private maxBetButton!: Phaser.GameObjects.Image;
    private slotsBalance!: SlotsBalance;
    private totalWin!: TotalWin;
    private lootBox!: LootBox;
    private menu!: Phaser.GameObjects.Image;
    private productsText!: Phaser.GameObjects.Text;
    private remisWheelText!: Phaser.GameObjects.Text;
    private storePopup!: Store;

    /* START-USER-CODE */
    private isSlotsGameReady: boolean = false;
    private isAuto: boolean = false;
    private spinData:
        | (SlotMachineResponse & {
              totalBet: number;
              isAuto: boolean;
              piggyJackpotResponse?: SpinPiggyJackpotResponse;
          })
        | undefined;
    private resolveFreeSpin?: (value: unknown) => void;
    private resolvePickBonus?: (value: unknown) => void;
    private actionDialog?: ActionDialog;

    create() {
        this.editorCreate();

        this.setupBackground();
        this.setupSpinButton();
        this.setupMaxBetButton();
        this.setupMenuButton();
        this.setupRemisWheelButton();
        this.setupActionDialog();
        this.setupLootBox();

        const user = this.userManager.getData();
        const config = this.userManager.getConfigData();
        const loginData = this.userManager.getLoginData();

        if (user) {
            this.spinButton.resetSpinButton();
            this.isSlotsGameReady = true;

            if (loginData) {
                this.setupStorefront({
                    provider: loginData.provider,
                    platform: loginData.platform,
                });
            }
            this.slotsBalance.setBalance(user.otaGold);
        }

        if (config) {
            this.totalBet.setAllowedBets(config.allowedBets);
        }

        this.events.on("wake", this.onWake, this);
        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            setupBackground();
        });

        // preload pick bonus asset pack
        this.load.pack("pick-bonus-asset-pack", "ota-slots/pick-bonus-asset-pack.json");
        this.load.start();
    }

    async onWake(
        _: Phaser.Scene,
        data: {
            finalReward: number;
            fromScene: typeof SCENE_KEYS.FREE_SPIN | typeof SCENE_KEYS.PICK_BONUS;
        },
    ) {
        this.setupBackground();
        if (!this.spinData || !data) return;
        const totalWinAmount = data.finalReward + this.totalWin.currentTotalWin;

        this.totalWin.setAmount(totalWinAmount);

        // if user back from PickBonus and free spin is triggered
        if (data.fromScene === SCENE_KEYS.PICK_BONUS && this.spinData.isFreeSpinTrigger) {
            // update balance & total win from PickBonus
            await this.slotsBalance.updateBalance(data.finalReward);
            // continue run free spin flow
            this.scene.sleep(SCENE_KEYS.GAME).launch(SCENE_KEYS.FREE_SPIN, this.spinData);
            return;
        }

        const winType = getWinType(totalWinAmount, this.spinData.totalBet);
        if (winType === "none") {
            this.spinData = undefined;
            return;
        }

        await this.handleShowWinningPopup({
            winType,
            totalWinAmount,
            isAuto: this.spinData.isAuto,
        });
        this.spinData = undefined;
        await this.slotsBalance.updateBalance(data.finalReward);
        if (this.resolveFreeSpin) {
            this.resolveFreeSpin(true);
            this.resolveFreeSpin = undefined;
        }
        if (this.resolvePickBonus) {
            this.resolvePickBonus(true);
            this.resolvePickBonus = undefined;
        }
    }

    setupLootBox() {
        this.lootBox.on(
            "action-dialog:show:battle-confirmation",
            ({ chance, level }: { chance: number; level: number }) => {
                if (this.actionDialog == null) return;
                this.actionDialog.open({
                    type: "battle-confirmation",
                    data: { chance, level },
                });
            },
        );

        this.lootBox.on("sync-user", async () => {
            const updatedUser = await this.userManager.syncWithBackend();
            if (!updatedUser) return;
            this.slotsBalance.setBalance(updatedUser.otaGold);
        });
    }

    setupActionDialog() {
        this.actionDialog = new ActionDialog(this, 195, 720);
        this.add.existing(this.actionDialog);
    }

    setupBackground() {
        setupBackground("./ota-slots/assets/slots_bg.png");
    }

    setupMaxBetButton() {
        new PushComponent(
            this.maxBetButton,
            () => {
                if (!this.isSlotsGameReady || this.isAuto) return;

                this.maxBetButton.setScale(0.25);
                this.totalBet.setMaxBet();
                this.time.delayedCall(200, () => {
                    this.maxBetButton.setScale(0.3);
                });
            },
            this,
        ).active();
    }

    setupMenuButton() {
        new PushComponent(
            this.menu,
            () => {
                if (this.spinButton.getIsInteractive() && !this.isAuto) {
                    this.scene.start("MiningGame");
                }
            },
            this,
        ).active();
    }

    setupRemisWheelButton() {
        this.remisWheelText.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
            // this.scene.start("FortuneWheelScene");
            EventBus.emit(EVENTS.OPEN_REMIS_WHEEL);
        });
    }

    setupSpinButton() {
        this.spinButton.setSpinButtonInteractive();

        this.spinButton.on("normal-spin", this.handleNormalSpin);
        this.spinButton.on("auto-spin", this.handleAutoSpin);
        this.spinButton.on("auto-spin-stop", this.handleAutoSpinStop);
    }

    setupStorefront(authData: LoginData) {
        this.storePopup.on("set-balance", (balance: number) => {
            this.slotsBalance.setBalance(balance);
        });
        this.storePopup.on("hidden", () => {
            this.productsText.setInteractive({ useHandCursor: true });
        });
        this.productsText.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
            this.productsText.disableInteractive();
            this.storePopup?.openStore(authData);
        });
    }

    async doSpin(isAuto: boolean) {
        this.totalWin.hide();
        const totalBet = this.totalBet.getCurrentBet();
        const balance = this.slotsBalance.getCurrentBalance();

        if (balance < totalBet) {
            alert("Not enough balance");
            return;
        } else {
            this.slotsBalance.updateBalance(-totalBet);
        }

        this.reels.cancelHighlightPaylines();

        const stopMockSpinReels = this.reels.mockSpinReels();

        const data = await spinSlotMachine(totalBet);

        stopMockSpinReels();

        if (!data) {
            console.error("No slot result");
            this.spinButton.resetSpinButton();
            return;
        }

        this.spinData = {
            ...data,
            totalBet,
            isAuto,
        };

        // prefetch pick bonus data
        if (data.isPiggyJackpotTrigger) {
            spinPiggyJackpot(data.spinId).then((res) => {
                if (this.spinData) {
                    this.spinData.piggyJackpotResponse = res as SpinPiggyJackpotResponse;
                }
            });
        }

        await this.reels.spinReels(data);
        // show win popup if free spin is triggered it will be handled in onWake
        await this.handleWinPopup();
        // update balance & total win
        await this.handleBalanceAndTotalWin(data);
        const lootBoxInfo = await getLootBoxInfo();
        if (lootBoxInfo) {
            const progressIncrement = Math.min((totalBet / lootBoxInfo.coinsRequiredPerLootBox) * 100, 100);
            this.lootBox.updateProgress(progressIncrement);
        }
    }

    handleNormalSpin = async () => {
        await this.doSpin(false);

        this.time.delayedCall(500, () => {
            this.spinButton.resetSpinButton();
        });
    };

    handleAutoSpin = async () => {
        this.isAuto = true;
        while (this.isAuto) {
            await this.doSpin(true);

            if (this.spinData?.isPiggyJackpotTrigger) {
                await this.waitPickBonus();
            }

            if (this.spinData?.isFreeSpinTrigger) {
                await this.waitFreeSpin();
            }
        }

        this.time.delayedCall(500, () => {
            this.spinButton.resetSpinButton();
        });
    };

    handleAutoSpinStop = () => {
        this.isAuto = false;
    };

    waitFreeSpin = () => {
        return new Promise((resolve) => {
            this.resolveFreeSpin = resolve;
        });
    };

    waitPickBonus = () => {
        return new Promise((resolve) => {
            this.resolvePickBonus = resolve;
        });
    };

    handleShowWinningPopup({
        winType,
        totalWinAmount,
        isAuto,
    }: {
        winType: "big_win" | "you_win";
        totalWinAmount: number;
        isAuto: boolean;
    }) {
        return new Promise<void>((resolve) => {
            const popupContainer = new WinningPopup(this, 0, 0);
            popupContainer.winAmount = totalWinAmount;
            popupContainer.winType = winType;
            popupContainer.isAuto = isAuto;

            popupContainer.setAlpha(0);
            popupContainer.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
            this.add.existing(popupContainer);
            this.tweens.add({
                targets: popupContainer,
                alpha: { from: 0, to: 1 },
                duration: 300,
                onComplete: () => {
                    popupContainer.playWinAnimation();
                },
            });

            popupContainer.once("destroy", () => {
                resolve();
            });
        });
    }

    async handleWinPopup() {
        return new Promise<void>(async (resolve) => {
            if (!this.spinData) {
                resolve();
                return;
            }

            if (this.spinData.isPiggyJackpotTrigger || this.spinData.isFreeSpinTrigger) {
                await this.slotsBalance.updateBalance(this.spinData.totalPaylineReward);
            }

            if (this.spinData.isPiggyJackpotTrigger) {
                this.scene.sleep(SCENE_KEYS.GAME).launch(SCENE_KEYS.PICK_BONUS, {
                    sceneKey: SCENE_KEYS.GAME,
                    spinData: this.spinData,
                });
                resolve();
                return;
            } else if (this.spinData.isFreeSpinTrigger) {
                this.scene.sleep(SCENE_KEYS.GAME).launch(SCENE_KEYS.FREE_SPIN, this.spinData);
                resolve();
                return;
            } else {
                const { totalPaylineReward: totalWinAmount, totalBet } = this.spinData;
                const winType = getWinType(totalWinAmount, totalBet);

                if (winType === "none") {
                    this.spinData = undefined;
                    resolve();
                    return;
                }

                await this.handleShowWinningPopup({
                    winType,
                    totalWinAmount,
                    isAuto: this.spinData.isAuto,
                });
                this.spinData = undefined;
                resolve();
            }
        });
    }

    async handleBalanceAndTotalWin({
        totalPaylineReward,
    }: {
        totalPaylineReward: SlotMachineResponse["totalPaylineReward"];
    }) {
        if (totalPaylineReward > 0) this.totalWin.setAmount(totalPaylineReward);
        if (!(this.spinData?.isPiggyJackpotTrigger || this.spinData?.isFreeSpinTrigger)) {
            await this.slotsBalance.updateBalance(totalPaylineReward);
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
