import BattleConfirmation, { type BattleConfirmationPayloadType } from "./BattleConfirmation";

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class ActionDialog extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 196, y ?? 124);

        // mainPanel
        const mainPanel = scene.add.container(-0.5, 0);
        mainPanel.alpha = 0;
        this.add(mainPanel);

        // panel
        const panel = scene.add.image(0, 0, "panel");
        mainPanel.add(panel);

        // panel_grid
        const panel_grid = scene.add.image(0, 2, "panel-grid");
        mainPanel.add(panel_grid);

        this.mainPanel = mainPanel;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private mainPanel: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    open(payload: BattleConfirmationPayloadType) {
        switch (payload.type) {
            case "battle-confirmation": {
                const battleConfirmation = new BattleConfirmation(this.scene, 0, 0);
                battleConfirmation.setContent(payload.data);
                this.add(battleConfirmation);
                battleConfirmation.once("retry", () => {
                    this.fadeOut(500, () => {
                        this.destroy();
                    });
                });

                battleConfirmation.once("fight", () => {
                    this.scene.scene.start("MiningBoss");
                });
                break;
            }
        }

        this.fadeIn();
    }

    fadeIn(duration = 500) {
        this.scene.tweens.add({
            targets: this.mainPanel,
            alpha: 1,
            duration: duration,
            ease: "Power2",
        });
    }

    fadeOut(duration = 500, onComplete?: () => void) {
        this.scene.tweens.add({
            targets: this.mainPanel,
            alpha: 0,
            duration: duration,
            ease: "Power2",
            onComplete,
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
