import {
    getLootBoxInfo,
    type LootBoxReward,
    openLootBox,
    performLootBoxAction,
} from "@/lib/api/telegram-backend/methods/loot-box";
import LootBoxPopup from "./LootBoxPopup";
import type { InventoryItem } from "@/shared/types/loot-box";
import { getItemDisplayName } from "@/shared/utils/loot-box";
import SoldItemPopup from "./SoldItemPopup";
import { getMiningBossFightInfo } from "@/lib/api/telegram-backend/methods/mining-boss";
import { SCENE_KEYS } from "@/ota-slots/constants";

/* START OF COMPILED CODE */

import Text from "../../../ota-slots/prefabs/Text";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class LootBoxIcon extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? -23.741949830176083);

        // icon
        const icon = scene.add.container(0, 6);
        this.add(icon);

        // image_8
        const image_8 = scene.add.image(0, 17.7419490814209, "mining", "chest img.png");
        icon.add(image_8);

        // ellipse_1
        const ellipse_1 = scene.add.ellipse(20, 0, 24, 24);
        ellipse_1.isFilled = true;
        ellipse_1.fillColor = 15869735;
        icon.add(ellipse_1);

        // boxNumber
        const boxNumber = new Text(scene, 20, 0);
        boxNumber.text = "0";
        boxNumber.setStyle({ "fontSize": "12px" });
        icon.add(boxNumber);

        this.boxNumber = boxNumber;
        this.icon = icon;

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    private boxNumber: Text;
    private icon: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    public async init(disabled?: boolean) {
        const lootBoxInfo = await getLootBoxInfo();
        if (lootBoxInfo?.totalLootBox) {
            this.boxNumber.setText(`${lootBoxInfo.totalLootBox}`);
        }
        this.icon
            .setInteractive({
                hitArea: new Phaser.Geom.Circle(3, 12, 31.19328784273037),
                hitAreaCallback: Phaser.Geom.Circle.Contains,
                useHandCursor: true,
            })
            .on("pointerdown", async () => {
                if (disabled) return;
                const boxNumber = this.getBoxNumber();
                if (boxNumber === 0) return;

                const reward = await openLootBox();
                if (reward) {
                    this.openPopup(reward.lootBox);
                    this.setBoxNumber(boxNumber - 1);
                }
            });
    }

    async openPopup(lootBoxReward: LootBoxReward) {
        const sceneName = this.scene.scene.key;
        const popupY = sceneName === SCENE_KEYS.MINING_GAME ? -100 : 0;
        const popup = new LootBoxPopup(this.scene, 0, popupY);

        const rewardItem: InventoryItem = lootBoxReward.item;
        popup.open(rewardItem);

        this.scene.add.existing(popup);

        popup.once("collect", async () => {
            await performLootBoxAction({ id: lootBoxReward.id, decision: "equip" });
            await this.scene.userManager.syncWithBackend();
        });

        popup.once("sell", async () => {
            const name = getItemDisplayName(rewardItem);
            const actionResponse = await performLootBoxAction({ id: lootBoxReward.id, decision: "sell" });

            if (name && rewardItem.sellingPrice) {
                const soldItemPopup = new SoldItemPopup(this.scene, 0, 0);
                soldItemPopup.setDisplayData(name, rewardItem.sellingPrice);
                soldItemPopup.fadeIn();
                this.scene.add.existing(soldItemPopup);
                this.scene.time.delayedCall(2000, () => {
                    soldItemPopup.fadeOut(500, () => soldItemPopup.destroy());
                });

                soldItemPopup.once("destroy", () => {
                    if (actionResponse) {
                        this.emit("sync-user");
                    }
                });
            }
        });

        popup.once("equip", async () => {
            performLootBoxAction({ id: lootBoxReward.id, decision: "equip" });
            await this.scene.userManager.syncWithBackend();
            const response = await getMiningBossFightInfo();
            if (response && response.winChance >= 50) {
                this.emit("action-dialog:show:battle-confirmation", {
                    chance: response.winChance,
                    level: rewardItem.level,
                });
            }
        });

        popup.on("destroy", () => {
            popup.off("collect");
            popup.off("equip");
            popup.off("sell");
        });
    }

    public getBoxNumber() {
        return Number(this.boxNumber.text);
    }

    public setBoxNumber(number: number) {
        this.boxNumber.setText(`${number}`);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
