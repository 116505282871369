export const winChanceToColor = (winChance: number) => {
    if (winChance < 50) {
        return "#d42f3a";
    }
    if (winChance >= 50 && winChance < 80) {
        return "#DEA614";
    }
    if (winChance >= 80) {
        return "#219B76";
    }

    return "#219B76";
};
