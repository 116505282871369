// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class TotalBet extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 100, y ?? 88);

        // betMinusIcon
        const betMinusIcon = scene.add.image(39, -3, "buttons_interface", "minus_button_01.png");
        betMinusIcon.scaleX = 0.2;
        betMinusIcon.scaleY = 0.2;
        this.add(betMinusIcon);

        // betPlusIcon
        const betPlusIcon = scene.add.image(142, -3, "buttons_interface", "plus_button_01.png");
        betPlusIcon.scaleX = 0.2;
        betPlusIcon.scaleY = 0.2;
        this.add(betPlusIcon);

        // totalBetText
        const totalBetText = new Text(scene, 89, -2);
        totalBetText.text = "1000";
        totalBetText.setStyle({ "fontSize": "18px" });
        this.add(totalBetText);

        // totalBetLabel
        const totalBetLabel = new Text(scene, 91, -23);
        totalBetLabel.text = "Total Bet";
        totalBetLabel.setStyle({ "fontSize": "14px" });
        this.add(totalBetLabel);

        this.betMinusIcon = betMinusIcon;
        this.betPlusIcon = betPlusIcon;
        this.totalBetText = totalBetText;

        /* START-USER-CTR-CODE */
        this.initialize();
        /* END-USER-CTR-CODE */
    }

    private betMinusIcon: Phaser.GameObjects.Image;
    private betPlusIcon: Phaser.GameObjects.Image;
    private totalBetText: Text;

    /* START-USER-CODE */

    private allowedBets: number[] = [];
    private currentBetIndex: number = 1; // Initialized to 1000, which is at index 1

    initialize() {
        this.betPlusIcon.setInteractive();
        this.betMinusIcon.setInteractive();
        this.betPlusIcon.on("pointerdown", this.increment, this);
        this.betMinusIcon.on("pointerdown", this.decrement, this);
        this.updateUIState();
    }

    public setAllowedBets(allowedBets: number[]) {
        this.allowedBets = allowedBets;
        this.updateTotalBetText();
        this.updateUIState();
    }

    public setMaxBet() {
        const maxBetIndex = this.allowedBets.length - 1;
        this.currentBetIndex = maxBetIndex;
        this.updateTotalBetText();
        this.updateUIState();
    }

    public getCurrentBet() {
        return +this.totalBetText.text;
    }

    private increment() {
        // @ts-ignore
        if (!this.allowedBets.length || this.scene.isAuto) return;

        if (this.currentBetIndex < this.allowedBets.length - 1) {
            this.currentBetIndex += 1;
            this.updateTotalBetText();
            this.updateUIState();
        }
    }

    private decrement() {
        // @ts-ignore
        if (!this.allowedBets.length || this.scene.isAuto) return;

        if (this.currentBetIndex > 0) {
            this.currentBetIndex -= 1;
            this.updateTotalBetText();
            this.updateUIState();
        }
    }

    private updateTotalBetText() {
        if (!this.allowedBets.length) return;

        const currentBet = this.allowedBets[this.currentBetIndex] + "";
        this.totalBetText.setText(currentBet);
    }

    private updateUIState() {
        if (!this.allowedBets.length) return;

        if (this.currentBetIndex === 0) {
            this.betMinusIcon.setTint(0x888888); // Gray out
            this.betMinusIcon.disableInteractive();
        } else {
            this.betMinusIcon.clearTint();
            this.betMinusIcon.setInteractive({ useHandCursor: true });
        }

        if (this.currentBetIndex === this.allowedBets.length - 1) {
            this.betPlusIcon.setTint(0x888888); // Gray out
            this.betPlusIcon.disableInteractive();
        } else {
            this.betPlusIcon.clearTint();
            this.betPlusIcon.setInteractive({ useHandCursor: true });
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
