const IS_DISABLED = false;

export const setupBackground = (url?: string, opacity: number = 0) => {
    if (IS_DISABLED) return;
    const div = document.getElementById("game-container");
    if (div) {
        if (!url) {
            div.style.background = "";
            return;
        }
        div.style.background = `linear-gradient(rgba(0, 0, 0, ${opacity}), rgba(0, 0, 0, ${opacity})), url(${url}) no-repeat center center / cover`;
    }
};
