import { telegramBackendApi } from "../client";
import { components } from "../generated/schema";

type LootBoxLevelInfoResponse = components["schemas"]["LootBoxLevelInfoResponseDto"];

export const getLootBoxInfo = async (): Promise<LootBoxLevelInfoResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/loot-box");

    if (error) {
        console.error("[getLootBoxInfo]", error);
        return;
    }

    return data as LootBoxLevelInfoResponse;
};

// type LootBoxHistoryResponse = components["schemas"]["PageResponseDto"] & {
//     data: components["schemas"]["LootBoxPageMetaDto"][];
// };
// interface LootBoxHistoryParams {
//     pageSize?: number;
//     lastKey?: string;
//     transactionId?: string;
// }
//
// export const getLootBoxHistory = async (
//     params: LootBoxHistoryParams = {}
// ): Promise<LootBoxHistoryResponse | undefined> => {
//     const { data, error } = await telegramBackendApi.GET("/loot-box/histories", {
//         params: {
//             query: params,
//         },
//     });
//
//     if (error) {
//         console.error("[getLootBoxHistory]", error);
//         return;
//     }
//
//     return data as LootBoxHistoryResponse;
// };

type MiningRewardResponse = components["schemas"]["MiningRewardDto"];

export const sellLootBox = async (): Promise<MiningRewardResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/loot-box/selling");

    if (error) {
        console.error("[sellLootBox]", error);
        return;
    }

    return data as MiningRewardResponse;
};

type LootBoxOpenResponse = components["schemas"]["LootBoxOpenResponseDto"];
export type LootBoxReward = LootBoxOpenResponse["lootBox"];

export const openLootBox = async (): Promise<LootBoxOpenResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/loot-box/open-box");

    if (error) {
        console.error("[openLootBox]", error);
        return;
    }

    return data as LootBoxOpenResponse;
};

type LootBoxActionRequest = components["schemas"]["LootBoxActionRequestDto"];
export type LootBoxActionResponse = components["schemas"]["MiningRewardDto"];

export const performLootBoxAction = async (
    actionData: LootBoxActionRequest,
): Promise<LootBoxActionResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/loot-box/action", {
        body: actionData,
    });

    if (error) {
        console.error("[performLootBoxAction]", error);
        return;
    }

    return data as LootBoxActionResponse;
};
