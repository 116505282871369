// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { CUSTOM_EVENTS, EventBusComponent } from "../../components/events/EventBusComponent";
import PushComponent from "../../components/animations/PushComponent";
import { winChanceToColor } from "@/ota-mining/utils/boss";
/* END-USER-IMPORTS */

export default class RetryConsole extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 687);

        // image_1
        const image_1 = scene.add.image(0, 32, "retry_console", "Frame 427318414.png");
        this.add(image_1);

        // retryButton
        const retryButton = scene.add.image(-85, 92, "retry_console", "retry-button.png");
        this.add(retryButton);

        // upgradeButton
        const upgradeButton = scene.add.image(95, 92, "mining", "Button Upgrade 2@2x.png");
        this.add(upgradeButton);

        // text
        const text = new Text(scene, -20, -28);
        text.text = "Current win chance";
        text.setStyle({ fontSize: "14px" });
        this.add(text);

        // winChance
        const winChance = new Text(scene, 49, -35);
        winChance.setOrigin(0, 0);
        winChance.text = "0%";
        winChance.setStyle({
            color: "#68DEBB",
            fontSize: "14px",
            stroke: "#68DEBB",
        });
        this.add(winChance);

        // text_2
        const text_2 = new Text(scene, -28, -2);
        text_2.text = "Attack power";
        text_2.setStyle({ fontSize: "14px" });
        this.add(text_2);

        // attack
        const attack = new Text(scene, 20, -9);
        attack.setOrigin(0, 0);
        attack.text = "0 dmg";
        attack.setStyle({
            color: "#DEA614",
            fontSize: "14px",
            stroke: "#DEA614",
        });
        this.add(attack);

        this.retryButton = retryButton;
        this.upgradeButton = upgradeButton;
        this.winChance = winChance;
        this.attack = attack;

        /* START-USER-CTR-CODE */
        new PushComponent(
            this.retryButton,
            () => {
                this.eventBusComponent.emit(CUSTOM_EVENTS.RETRY_BOSS_FIGHT);
            },
            this,
        ).active();

        new PushComponent(
            this.upgradeButton,
            () => {
                this.scene.scene.start("SlotsBoot");
            },
            this,
        ).active();

        this.setupInfo();
        /* END-USER-CTR-CODE */
    }

    private retryButton: Phaser.GameObjects.Image;
    private upgradeButton: Phaser.GameObjects.Image;
    private winChance: Text;
    private attack: Text;

    /* START-USER-CODE */
    private eventBusComponent: EventBusComponent;

    init(eventBusComponent: EventBusComponent) {
        this.eventBusComponent = eventBusComponent;
    }

    setupInfo() {
        const miningBoss = this.scene.miningBossManager.getData();
        if (miningBoss) {
            const winChance = Number(miningBoss?.winChance || 0).toFixed(2);
            this.winChance.setText(`${winChance}%`);
            this.winChance.setColor(winChanceToColor(Number(winChance)));
        }
        const weapon = this.scene.userManager.getWeapon();
        if (weapon) {
            this.attack.setText(`${weapon.minAtk} - ${weapon.maxAtk} dmg`);
        }
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
