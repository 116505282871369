export const chapter1 = {
    id: 1,
    boss: {
        idleAnimationKey: "boss_01_attack", // attack is idle
        walkAnimationKey: "boss_01_walk",
        attackAnimationKey: "boss_01_attack",
        gethitAnimationKey: "boss_01_gethit",
        destroyedAnimationKey: "boss_01_dead",
        size: {
            scale: 0.7,
        },
        texture: ["boss_01_walk", "Property 1=boss_01_walk_F_01.png"],
    },
    creep: {
        walkAnimationKey: "creep_01_walk",
        attackAnimationKey: "creep_01_attack",
        destroyedAnimationKey: "creep_01_die",
        size: {
            scale: 1,
        },
        texture: ["creep_01_walk", "frame=F_01.png"],
    },
    background: {
        mining: "chapter_01_bg",
        boss: "chapter_01_bg",
    },
    nextChapter: {
        name: "Chapter 2",
        boss: {
            name: "Boss 2",
            texture: ["chapter_01_info", "next_boss.png"],
        },
        creep: {
            name: "Creep 2",
            texture: ["chapter_01_info", "next_creep.png"],
        },
    },
    currentChapter: {
        name: "Chapter 1",
        boss: {
            name: "Boss 1",
            texture: ["chapter_01_info", "current_boss.png"],
            avatar: ["boss_base", "Frame 427318435.png"],
        },
    },
};
