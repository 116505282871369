import { Scene } from "phaser";
import { SCENE_KEYS } from "../constants";
import { setupBackground } from "../helpers/setup-background";
export default class Boot extends Scene {
    constructor() {
        super(SCENE_KEYS.BOOT);
    }

    preload() {
        //  The Boot Scene is typically used to load in any assets you require for your Preloader, such as a game logo or background.
        //  The smaller the file size of the assets, the better, as the Boot Scene itself has no preloader.

        this.load.pack("boot-asset-pack", "ota-slots/boot-asset-pack.json");
    }

    init() {
        this.setupBackground();
    }

    create() {
        this.scene.start(SCENE_KEYS.PRELOADER);
    }

    setupBackground() {
        setupBackground("./ota-slots/assets/slots_bg.png");
    }
}
