import Phaser from "phaser";
import { InputComponent } from "../input/InputComponent";
import { CUSTOM_EVENTS, EventBusComponent } from "../events/EventBusComponent";
import Creep from "../../prefab/Creep";

interface CreepWeaponConfig {
    damage: number;
}

/**
 * The `WeaponComponent` is used for enabling a game object to fire bullets
 * in our game. When input is detected from the provided `InputComponent`, this
 * component will check if we are able to fire a new bullet and do so based on the
 * fire interval and the max number of bullets that can be on screen at a time for
 * the attached game object.
 *
 * The bullet game objects are managed via a Phaser 3 Group, that way we can create
 * a simple object pool.
 */
export class CreepWeaponComponent {
    private gameObject: Creep;
    private weaponConfig: CreepWeaponConfig;
    private eventBusComponent: EventBusComponent;
    private isAttacking: boolean = false;

    constructor(gameObject: Creep, weaponConfig: CreepWeaponConfig, eventBusComponent: EventBusComponent) {
        this.gameObject = gameObject;
        this.weaponConfig = weaponConfig;
        this.eventBusComponent = eventBusComponent;
        this.isAttacking = false;
    }

    get damage(): number {
        return this.weaponConfig.damage;
    }

    reset(): void {
        this.isAttacking = false;
        this.gameObject.creepObject.off(Phaser.Animations.Events.ANIMATION_REPEAT, this.onAnimationRepeat);
        this.gameObject.creepObject.play({
            key: this.gameObject.walkAnimationKey,
        });
    }

    attackPlayer(): void {
        if (this.isAttacking) {
            return;
        }

        this.gameObject.creepObject.play({
            key: this.gameObject.attackAnimationKey,
        });

        this.gameObject.creepObject.on(Phaser.Animations.Events.ANIMATION_REPEAT, this.onAnimationRepeat);

        this.isAttacking = true;
    }

    private onAnimationRepeat = () => {
        this.eventBusComponent.emit(CUSTOM_EVENTS.PLAYER_HIT, this.gameObject);
    };
}
