// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
/* START-USER-IMPORTS */
import { numberWithCommas, parseFormattedNumber } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class Jackpot extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 22);

        // jackpotImage
        const jackpotImage = scene.add.image(0, 50, "jackpot", "jackpot.png");
        this.add(jackpotImage);

        // grandPoint
        const grandPoint = new Text(scene, 0, 21);
        grandPoint.text = "0";
        grandPoint.setStyle({ fontSize: "22px" });
        this.add(grandPoint);

        // majorPoint
        const majorPoint = new Text(scene, 0, 70);
        majorPoint.text = "0";
        majorPoint.setStyle({ fontSize: "14px" });
        this.add(majorPoint);

        // minorPoint
        const minorPoint = new Text(scene, -134, 70);
        minorPoint.text = "0";
        minorPoint.setStyle({ fontSize: "14px" });
        this.add(minorPoint);

        // miniPoint
        const miniPoint = new Text(scene, 134, 70);
        miniPoint.text = "0";
        miniPoint.setStyle({ fontSize: "14px" });
        this.add(miniPoint);

        this.jackpotImage = jackpotImage;
        this.grandPoint = grandPoint;
        this.majorPoint = majorPoint;
        this.minorPoint = minorPoint;
        this.miniPoint = miniPoint;

        /* START-USER-CTR-CODE */
        this.scene.events.once(Phaser.Scenes.Events.UPDATE, this.start, this);
        /* END-USER-CTR-CODE */
    }

    private jackpotImage: Phaser.GameObjects.Image;
    private grandPoint: Text;
    private majorPoint: Text;
    private minorPoint: Text;
    private miniPoint: Text;

    /* START-USER-CODE */

    intervals: NodeJS.Timeout[] = [];
    animations: Phaser.Tweens.Tween[] = [];

    // Write your code here

    runInit(source: Phaser.GameObjects.Text) {
        source.setText(numberWithCommas(Phaser.Math.Between(50061504, 60061504)));
    }

    runUpdate() {
        const points = [this.grandPoint, this.miniPoint, this.majorPoint, this.minorPoint];

        points.forEach((source) => {
            const currentScore = parseFormattedNumber(source.text);
            const newScore = currentScore + Phaser.Math.Between(-10000, 10000);

            this.scene.tweens.addCounter({
                from: currentScore,
                to: newScore,
                duration: 300,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    source.setText(`${numberWithCommas(value)}`);
                },
            });
        });
    }

    start() {
        const points = [this.grandPoint, this.miniPoint, this.majorPoint, this.minorPoint];

        points.forEach((point) => this.runInit(point));

        this.scene.time.addEvent({
            delay: 600,
            callback: this.runUpdate,
            callbackScope: this,
            loop: true,
        });
    }

    doUpgrade() {
        this.jackpotImage.setTexture("jackpot", "upgraded jackpot.png");
        this.jackpotImage.setY(58);
    }

    reset() {
        this.jackpotImage.setTexture("jackpot", "jackpot.png");
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
