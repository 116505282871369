import { Loader } from "phaser";
import { WebFontFile } from "./shared/fileloaders/WebFontFile";

declare module "phaser" {
    namespace Loader {
        interface LoaderPlugin {
            webfont(key: string, config?: WebFont.Config): LoaderPlugin;
        }
    }
}

Loader.LoaderPlugin.prototype.webfont = function (key: string, config?: WebFont.Config) {
    this.addFile(new WebFontFile(this, key, config));
    return this;
};
