import type { GemHexColor, InventoryItem, ItemAssetName } from "@/shared/types/loot-box";
import {
    getAverageAttackPower,
    getItemDisplayName,
    getItemTextureName,
    getRarityColor,
    getSecondaryRarityGradientColor,
} from "@/shared/utils/loot-box";
/* START OF COMPILED CODE */

import Text from "../../../ota-slots/prefabs/Text";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class NewItem extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? -35.696540807871855);

        // itemStats
        const itemStats = scene.add.container(-19, 146);
        itemStats.alpha = 0;
        this.add(itemStats);

        // itemTexture
        const itemTexture = scene.add.image(17.666671752929688, 188.00000762939453, "sniper-rifle");
        itemTexture.scaleX = 0.3812759473344469;
        itemTexture.scaleY = 0.3812759473344469;
        itemStats.add(itemTexture);

        // text
        const text = new Text(scene, 17.666671752929688, 0);
        text.text = "New Item";
        text.setStyle({});
        itemStats.add(text);

        // itemName
        const itemName = new Text(scene, 17.666671752929688, 39.00000762939453);
        itemName.text = "Rifle";
        itemName.setStyle({ "color": "#C851E5", "fontSize": "44px" });
        itemStats.add(itemName);

        // gradientFx
        const gradientFx = itemName.preFX!.addGradient(13128165, 7286143, 0.2, 0, 0, 1, 0, 0);

        // itemLevel
        const itemLevel = new Text(scene, 17.666671752929688, 80.00000762939453);
        itemLevel.text = "Level: 2";
        itemLevel.setStyle({ "color": "#C851E5" });
        itemStats.add(itemLevel);

        // itemRarity
        const itemRarity = new Text(scene, 17.666671752929688, 108.00000762939453);
        itemRarity.text = "Rarity: 2";
        itemRarity.setStyle({ "color": "#C851E5" });
        itemStats.add(itemRarity);

        // collectButton
        const collectButton = scene.add.image(17.666671752929688, 409.00000762939453, "collect");
        itemStats.add(collectButton);

        // powerChange
        const powerChange = new Text(scene, 75, 263);
        powerChange.text = "+50";
        powerChange.setStyle({});
        itemStats.add(powerChange);

        // bullets
        const bullets = scene.add.image(0, 262, "bullets");
        bullets.scaleX = 0.4380157978823598;
        bullets.scaleY = 0.4380157978823598;
        itemStats.add(bullets);

        this.itemTexture = itemTexture;
        this.gradientFx = gradientFx;
        this.itemName = itemName;
        this.itemLevel = itemLevel;
        this.itemRarity = itemRarity;
        this.collectButton = collectButton;
        this.powerChange = powerChange;
        this.itemStats = itemStats;

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    private itemTexture: Phaser.GameObjects.Image;
    private gradientFx: Phaser.FX.Gradient;
    private itemName: Text;
    private itemLevel: Text;
    private itemRarity: Text;
    private collectButton: Phaser.GameObjects.Image;
    private powerChange: Text;
    private itemStats: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    setItem(item: InventoryItem) {
        const textureName = getItemTextureName(item);
        const displayName = getItemDisplayName(item);
        const averageAttackPower = getAverageAttackPower(item);
        if (textureName) this.itemTexture.setTexture(textureName);
        if (displayName) this.itemName.setText(displayName);
        this.itemLevel.setText(`Level: ${item.level}`);
        this.itemRarity.setText(`Rarity: ${item.level}`);
        this.powerChange.setText(`+${averageAttackPower}`);

        if (item.name === "gem") {
            const rarityColor = getRarityColor(item);
            this.itemName.setStyle({ fontSize: 34, color: rarityColor });
            this.itemLevel.setStyle({ color: rarityColor });
            this.itemRarity.setStyle({ color: rarityColor });
            this.itemTexture.setScale(0.2428407392079012, 0.2428407392079012);
            this.replaceItemNameGradientFx(rarityColor);
        }

        this.collectButton.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
            this.emit("collect");
        });
    }

    private replaceItemNameGradientFx(rarityColor: GemHexColor) {
        const secondGradientColor = getSecondaryRarityGradientColor(rarityColor);
        const primaryColor = Phaser.Display.Color.HexStringToColor(rarityColor).color;
        const secondaryColor = Phaser.Display.Color.HexStringToColor(secondGradientColor).color;

        if (this.gradientFx) {
            this.itemName.preFX!.remove(this.gradientFx);
        }

        this.gradientFx = this.itemName.preFX!.addGradient(primaryColor, secondaryColor, 0.2, 0, 0, 1, 0, 0);
    }

    fadeIn(duration = 500) {
        this.scene.tweens.add({
            targets: this.itemStats,
            alpha: 1,
            duration: duration,
            ease: "Power2",
        });
    }

    fadeOut(duration = 500, onComplete?: () => void) {
        this.scene.tweens.add({
            targets: this.itemStats,
            alpha: 0,
            duration: duration,
            ease: "Power2",
            onComplete,
        });
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
