// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../shared/prefabs/Text";
import Player from "../prefab/Player";
import ChargingCountdown from "../prefab/boss/ChargingCountdown";
import Boss from "../prefab/Boss";
/* START-USER-IMPORTS */
import PushComponent from "../components/animations/PushComponent";
import WeaponPopup from "../prefab/WeaponPopup";
import { InventoryItem } from "@/shared/types/loot-box";
import Gem from "@/shared/prefabs/loot-box/Gem";
import { getItemTextureName, getRarityColor } from "@/shared/utils/loot-box";
import { winChanceToColor } from "@/ota-mining/utils/boss";
/* END-USER-IMPORTS */

export default class BossCharger extends Phaser.Scene {
    constructor() {
        super("MiningBossCharger");

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // bg
        const bg = this.add.image(195, 422, "boss_charger", "_bg.png");

        // playerWrapper
        const playerWrapper = this.add.image(195, 152, "boss_charger", "Frame 427318497.png");

        // upgradeButton
        const upgradeButton = this.add.image(107, 774, "mining", "Button Upgrade 2@2x.png");

        // frame_427318440_png
        this.add.image(292, 768, "boss_charger", "Frame 427318440.png");

        // charging
        const charging = new Text(this, 306, 767);
        this.add.existing(charging);
        charging.text = "Charging...";
        charging.setStyle({ "fontSize": "10px" });

        // winChance
        const winChance = new Text(this, 195, 458);
        this.add.existing(winChance);
        winChance.text = "Win chance 55%";
        winChance.setStyle({ "color": "#68DEBB", "fontSize": "20px", "stroke": "#68DEBB" });

        // playerName
        const playerName = new Text(this, 196, 234);
        this.add.existing(playerName);
        playerName.text = "player name";
        playerName.setStyle({ "fontSize": "12px" });

        // player
        const player = new Player(this, 195, 146);
        this.add.existing(player);
        player.scaleX = 0.7;
        player.scaleY = 0.7;

        // fightButton
        const fightButton = this.add.image(309, 768, "boss_charger", "fight_button.png");
        fightButton.visible = false;

        // bossLevel
        const bossLevel = new Text(this, 195, 663);
        this.add.existing(bossLevel);
        bossLevel.text = "Boss Level 1";
        bossLevel.setStyle({ "fontSize": "12px" });

        // bossHealth
        const bossHealth = new Text(this, 193, 688);
        this.add.existing(bossHealth);
        bossHealth.text = "6500HP";
        bossHealth.setStyle({ "fontSize": "18px" });

        // chargingCountdown
        const chargingCountdown = new ChargingCountdown(this, 305, 797);
        this.add.existing(chargingCountdown);

        // weapon
        const weapon = this.add.image(120, 297, "magnum");
        weapon.scaleX = 0.25;
        weapon.scaleY = 0.25;

        // weaponRank
        const weaponRank = new Text(this, 252, 268);
        this.add.existing(weaponRank);
        weaponRank.setOrigin(0, 0);
        weaponRank.text = "N";
        weaponRank.setStyle({ "color": "#DEA614", "fontSize": "12px", "stroke": "#DEA614" });

        // weaponLevel
        const weaponLevel = new Text(this, 252, 291);
        this.add.existing(weaponLevel);
        weaponLevel.setOrigin(0, 0);
        weaponLevel.text = "level 0";
        weaponLevel.setStyle({ "fontSize": "12px" });

        // weaponAttack
        const weaponAttack = new Text(this, 252, 316);
        this.add.existing(weaponAttack);
        weaponAttack.setOrigin(0, 0);
        weaponAttack.text = "Attack: 0";
        weaponAttack.setStyle({ "color": "#DEA614", "fontSize": "12px", "stroke": "#DEA614" });

        // backButton
        const backButton = this.add.image(36, 40, "back_btn");

        // menuLabel
        const menuLabel = this.add.text(37, 72, "", {});
        menuLabel.setOrigin(0.5, 0.5);
        menuLabel.text = "Back";
        menuLabel.setStyle({ "fontFamily": "Oxanium", "fontSize": "8px", "fontStyle": "bold" });

        // polygon
        const polygon = this.add.image(195, 570, "polygon");
        polygon.scaleX = 0.7;
        polygon.scaleY = 0.7;

        // boss
        const boss = new Boss(this, 195, 552);
        this.add.existing(boss);
        boss.scaleX = 0.6;
        boss.scaleY = 0.6;

        this.bg = bg;
        this.playerWrapper = playerWrapper;
        this.upgradeButton = upgradeButton;
        this.charging = charging;
        this.winChance = winChance;
        this.playerName = playerName;
        this.player = player;
        this.fightButton = fightButton;
        this.bossLevel = bossLevel;
        this.bossHealth = bossHealth;
        this.chargingCountdown = chargingCountdown;
        this.weapon = weapon;
        this.weaponRank = weaponRank;
        this.weaponLevel = weaponLevel;
        this.weaponAttack = weaponAttack;
        this.backButton = backButton;

        this.events.emit("scene-awake");
    }

    private bg!: Phaser.GameObjects.Image;
    private playerWrapper!: Phaser.GameObjects.Image;
    private upgradeButton!: Phaser.GameObjects.Image;
    private charging!: Text;
    private winChance!: Text;
    private playerName!: Text;
    private player!: Player;
    private fightButton!: Phaser.GameObjects.Image;
    private bossLevel!: Text;
    private bossHealth!: Text;
    private chargingCountdown!: ChargingCountdown;
    private weapon!: Phaser.GameObjects.Image;
    private weaponRank!: Text;
    private weaponLevel!: Text;
    private weaponAttack!: Text;
    private backButton!: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    create() {
        this.editorCreate();

        new PushComponent(this.upgradeButton, this.handleUpgrade, this).active();
        new PushComponent(this.fightButton, this.handleFight, this).active();

        this.player.playPlayerIdleAnimation();

        this.chargingCountdown.updateBossFightStatus(this.handleCountdownDone.bind(this));

        this.setupUser();
        this.setupWeapon();
        this.setupBoss();
        this.setupGems();

        new PushComponent(
            this.backButton,
            () => {
                this.scene.start("MiningGame");
            },
            this,
        ).active();
    }

    setupUser() {
        const user = this.userManager.getData();

        this.playerName.text = user?.username ?? "Player Name";
    }

    setupWeapon() {
        const userWeapon = this.userManager.getWeapon();
        if (userWeapon) {
            const itemTextureName = getItemTextureName(userWeapon);
            if (itemTextureName) this.weapon.setTexture(itemTextureName);
            this.weaponRank.text = userWeapon.rarity.toUpperCase();
            this.weaponRank.setStyle({ color: getRarityColor(userWeapon) });
            this.weaponLevel.text = `Level ${userWeapon.level}`;
            this.weaponAttack.text = `Attack: ${userWeapon.minAtk} - ${userWeapon.maxAtk}`;
        } else {
            this.weapon.visible = false;
            this.weaponRank.visible = false;
            this.weaponLevel.visible = false;
            this.weaponAttack.visible = false;
        }
    }

    setupBoss() {
        const miningBoss = this.miningBossManager.getData();
        const winChance = Number(miningBoss?.winChance || 0).toFixed(2);

        this.winChance.text = `Win chance ${winChance}%`;
        this.bossHealth.text = `${miningBoss?.bossHealth || 0}HP`;

        this.winChance.setColor(winChanceToColor(Number(winChance)));
    }

    setupGems() {
        const container = new Phaser.GameObjects.Container(this, 90, 385);

        const fightingEquipments = this.userManager.getInventoryItems();
        if (fightingEquipments) {
            fightingEquipments
                .filter((equipment: InventoryItem) => equipment.name === "gem")
                .sort((a: InventoryItem, b: InventoryItem) => a.slot - b.slot)
                .forEach((equipment: InventoryItem, index: number) => {
                    const gem = new Gem(this, index * 55, 0);
                    gem.init(equipment, {
                        hideLevel: false,
                        hideRank: false,
                    });
                    gem.scale = 0.6;
                    new PushComponent(gem.gemItem, this.handleWeaponPopup, this).active();
                    container.add(gem);
                });
        }

        this.add.existing(container);
    }

    handleWeaponPopup() {
        const popupContainer = new WeaponPopup(this, 195, 422);
        popupContainer.setDepth(4);
        this.add.existing(popupContainer);
    }

    handleUpgrade() {
        this.scene.start("SlotsBoot");
    }

    handleFight() {
        this.scene.start("MiningBoss");
    }

    handleCountdownDone() {
        this.fightButton.visible = true;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
