/* START OF COMPILED CODE */

import Phaser from "phaser";
import BottomConsole from "../prefab/mining/BottomConsole";
import TopConsole from "../prefab/mining/TopConsole";
import BossStack from "../prefab/mining/BossStack";
import Text from "../../../ota-slots/prefabs/Text";
import BoostMining from "../prefab/boss/BoostMining";
/* START-USER-IMPORTS */
import Player from "../prefab/Player";
import Creep from "../prefab/Creep";
import { EventBusComponent } from "../components/events/EventBusComponent";
import { CreepSpawnerComponent } from "../components/spawners/CreepSpawnerComponent";
import { CreepDestroyedComponent } from "../components/spawners/CreepDestroyedComponent";
import * as CONFIG from "../config";
import CollectMaterials from "../prefab/mining/CollectMaterials";
import { getStoragePercent } from "@/ota-mining/utils/get-storage-percentage";
import ClaimPopup from "../prefab/ClaimPopup";
import PushComponent from "../components/animations/PushComponent";
import { generateReferralLink, shareReferralLink } from "@/ota-mining/utils/referral-link";
import { EventBus, EVENTS } from "@/lib/event-bus";
/* END-USER-IMPORTS */

export default class Game extends Phaser.Scene {
    constructor() {
        super("MiningGame");

        /* START-USER-CTR-CODE */
        this.eventBusComponent = new EventBusComponent();
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // bg
        const bg = this.add.image(195, 422, "chapter_01_bg");
        bg.visible = false;

        // mining_hold_png
        const mining_hold_png = this.add.image(195, 578, "mining", "mining_hold.png");
        mining_hold_png.visible = false;

        // bottomConsole
        const bottomConsole = new BottomConsole(this, 195, 425);
        this.add.existing(bottomConsole);

        // topConsole
        const topConsole = new TopConsole(this, 195, 81);
        this.add.existing(topConsole);

        // bossStack
        const bossStack = new BossStack(this, 46, 200);
        this.add.existing(bossStack);

        // disableButton
        const disableButton = this.add.image(363, 29, "yellow_btn");
        disableButton.scaleX = 0.4;
        disableButton.scaleY = 0.4;

        // text_5
        const text_5 = new Text(this, 363, 28);
        this.add.existing(text_5);
        text_5.text = "A";
        text_5.setStyle({ color: "#000", fontSize: "14px" });

        // referralSquare
        const referralSquare = this.add.rectangle(159, 97, 128, 128);
        referralSquare.scaleX = 0.3898214945222077;
        referralSquare.scaleY = 0.33209009458699285;
        referralSquare.fillAlpha = 0;

        // boostMining
        const boostMining = new BoostMining(this, 312, 230);
        this.add.existing(boostMining);

        // soundButton
        const soundButton = this.add.image(30, 29, "yellow_btn");
        soundButton.scaleX = 0.4;
        soundButton.scaleY = 0.4;

        // text_7
        const text_7 = new Text(this, 30, 28);
        this.add.existing(text_7);
        text_7.text = "S";
        text_7.setStyle({ color: "#000", fontSize: "14px" });

        this.bg = bg;
        this.bottomConsole = bottomConsole;
        this.topConsole = topConsole;
        this.disableButton = disableButton;
        this.referralSquare = referralSquare;
        this.boostMining = boostMining;
        this.soundButton = soundButton;

        this.events.emit("scene-awake");
    }

    private bg!: Phaser.GameObjects.Image;
    private bottomConsole!: BottomConsole;
    private topConsole!: TopConsole;
    private disableButton!: Phaser.GameObjects.Image;
    private referralSquare!: Phaser.GameObjects.Rectangle;
    private boostMining!: BoostMining;
    private soundButton!: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    private player!: Player;
    private creep!: Creep;
    private creepSpawner!: CreepSpawnerComponent;
    private disableAnimation: boolean = false;
    public eventBusComponent: EventBusComponent;

    create() {
        this.editorCreate();
        this.setupPlayer();
        this.setupBackground();
        this.setupDisableAnimation();
        this.setupSound();

        this.setupReferralClick();
        this.updateMining();
        this.updateBoostMining();

        // Listen for scene destroy event
        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            this.shutdown();
        });

        EventBus.emit(EVENTS.CURRENT_SCENE_READY, this);
    }

    setupReferralClick() {
        const user = this.userManager.getData();
        if (!user) return;

        this.referralSquare.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
            this.handleReferralClick(user.referralCode);
        });
    }

    handleReferralClick(userRefCode: string) {
        const referralLink = generateReferralLink(userRefCode);

        shareReferralLink(referralLink);
    }

    shutdown() {
        // Clean up any resources
        this.eventBusComponent.removeAllListeners();
        this.eventBusComponent.destroy();
    }

    updateBoostMining() {
        const miningBoss = this.miningBossManager.getData();
        if (miningBoss) {
            this.boostMining.updateBossFightStatus();
        }
    }

    async updateMining() {
        const user = this.userManager.getData();
        if (!user) return;

        this.topConsole.init(this.eventBusComponent);

        const now = Date.now();

        const { storagePercent } = getStoragePercent(user.lastClaimedAt, now);

        if (storagePercent >= 100) {
            this.handleCollectMaterials();
            return;
        }

        this.bottomConsole.init(this.eventBusComponent);
        this.handleStartMiningAnimation();
    }

    handleStartMiningAnimation() {
        this.disableAnimation = false;
        const player = this.player;
        player.startMining();

        const creepSpawner = new CreepSpawnerComponent(
            this,
            player,
            Creep,
            {
                interval: CONFIG.CREEP_GROUP_SPAWN_INTERVAL,
                spawnAt: CONFIG.CREEP_GROUP_SPAWN_START,
                spawnCount: CONFIG.CREEP_GROUP_SPAWN_MAX_COUNT,
            },
            this.eventBusComponent,
        );
        this.creepSpawner = creepSpawner;
        new CreepDestroyedComponent(this, this.eventBusComponent);

        // creep attack player
        this.physics.add.collider(player, creepSpawner.phaserGroup, ((
            playerGameObject: Player,
            creepGameObject: Creep,
        ) => {
            if (!creepGameObject.active || !playerGameObject.active) {
                return;
            }

            creepGameObject.weaponComponent.attackPlayer();
        }) as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback);

        // player attack creep
        this.physics.add.overlap(player.weaponGameObjectGroup, creepSpawner.phaserGroup, ((
            creepGameObject: Creep,
            projectileGameObject: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody,
        ) => {
            if (!creepGameObject.active) {
                return;
            }
            player.weaponComponent.destroyBullet(projectileGameObject, {
                animationKey: "explosion",
                scale: 0.25,
            });
            creepGameObject.colliderComponent.collideWithEnemyWeapon(player.weaponComponent.damage);
        }) as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback);
    }

    handleDestroyMiningAnimation() {
        this.disableAnimation = true;
        this.player.destroy();
        this.creepSpawner.destroy();
        this.physics.world.colliders.destroy();
    }

    handleCollectMaterials() {
        const collectMaterials = new CollectMaterials(this, 0, 0);
        collectMaterials.setDepth(4);
        this.add.existing(collectMaterials);
        collectMaterials.once("destroy", this.handleClaimPopup, this);
    }

    handleClaimPopup() {
        const popupContainer = new ClaimPopup(this, 0, 0);
        popupContainer.setAlpha(0);
        popupContainer.setDepth(4);
        this.add.existing(popupContainer);
        this.tweens.add({
            targets: popupContainer,
            alpha: { from: 0, to: 1 },
            duration: 300,
            onComplete: () => {
                popupContainer.claimReward();
            },
        });

        popupContainer.once("destroy", () => {
            this.time.delayedCall(500, () => {
                this.updateMining();
            });
        });
    }

    setupBackground() {
        const chapterConfig = this.chapterManager.getData();
        if (chapterConfig) {
            this.bg.setTexture(chapterConfig.background.mining);
        }
        this.bg.visible = true;
    }

    setupPlayer() {
        const player = new Player(this, 80, 500);
        player.init(this.eventBusComponent);
        this.add.existing(player);
        this.player = player;
    }

    setupDisableAnimation() {
        new PushComponent(
            this.disableButton,
            () => {
                if (this.disableAnimation) {
                    this.setupPlayer();
                    this.handleStartMiningAnimation();
                } else {
                    this.handleDestroyMiningAnimation();
                }
            },
            this,
        ).active();
    }

    setupSound() {
        new PushComponent(
            this.soundButton,
            () => {
                const latestSettings = this.settingsManager.getData();
                if (latestSettings?.sound) {
                    this.settingsManager.setData({ sound: false });
                    this.sys.game.audio.disableSound();
                } else {
                    this.settingsManager.setData({ sound: true });
                    this.sys.game.audio.enableSound();
                }
            },
            this,
        ).active();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
