import Phaser from "phaser";
import { InputComponent } from "../input/InputComponent";
import Boss from "../../prefab/Boss";

/**
 * Component that allows for horizontal movement in a Phaser 3 game.
 * This component relies on the built in Phaser 3 Arcade Physics system
 * to move the Phaser 3 game objects around the scene. When input is detected
 * (via the InputComponent), this component will update the physics body
 * velocity.
 */
export class BossHorizontalMovementComponent {
    private gameObject: Boss;
    private inputComponent: InputComponent;
    private velocity: number;
    private isPaused: boolean = false;
    private maxPosition: number;

    constructor(
        gameObject: Boss,
        inputComponent: InputComponent,
        {
            velocity,
            maxVelocity,
            maxPosition,
        }: {
            velocity: number;
            maxVelocity?: number;
            maxPosition?: number;
        },
    ) {
        this.gameObject = gameObject;
        this.inputComponent = inputComponent;
        this.velocity = velocity;
        this.maxPosition = maxPosition || 0;
        const body = (this.gameObject as Boss).body as Phaser.Physics.Arcade.Body;
        if (!body) {
            return;
        }

        body.setDamping(true);
        body.setDrag(0.99);
        body.setMaxVelocity(maxVelocity || 300);

        gameObject.bossObject.play(gameObject.walkAnimationKey);
    }

    reset(): void {
        this.isPaused = false;
        const body = (this.gameObject as Boss).body as Phaser.Physics.Arcade.Body;
        if (!body) {
            return;
        }
        body.velocity.x = 0;
        body.setAngularAcceleration(0);
    }

    pause(): void {
        this.gameObject.bossObject.play(this.gameObject.idleAnimationKey);
        this.isPaused = true;
    }

    resume(): void {
        this.isPaused = false;
        this.maxPosition = 0;
        this.gameObject.bossObject.play(this.gameObject.walkAnimationKey);
    }

    update(): void {
        const body = (this.gameObject as Boss).body as Phaser.Physics.Arcade.Body;

        if (!body || this.isPaused) {
            return;
        }

        if (this.maxPosition && body.position.x <= this.maxPosition) {
            body.velocity.x = 0;
            this.pause();
            return;
        }

        if (this.inputComponent.leftIsDown) {
            body.velocity.x -= this.velocity;
        } else if (this.inputComponent.rightIsDown) {
            body.velocity.x += this.velocity;
        } else {
            body.setAngularAcceleration(0);
        }
    }
}
