import Phaser from "phaser";
import { InputComponent } from "./InputComponent";

/**
 * A custom `InputComponent` that relies on the Phaser 3 Keyboard
 * Plugin to detect input in the web browser.
 */
export class KeyboardInputComponent extends InputComponent {
    cursorKeys: Phaser.Types.Input.Keyboard.CursorKeys;
    inputLocked: boolean;

    constructor(scene: Phaser.Scene) {
        super();
        this.cursorKeys = scene.input.keyboard?.createCursorKeys() ?? scene.input.keyboard!.createCursorKeys();
        this.inputLocked = false;
    }

    set lockInput(val: boolean) {
        this.inputLocked = val;
    }

    get shootIsDown(): boolean {
        return this._shoot;
    }

    set shootIsDown(val: boolean) {
        this._shoot = val;
    }

    /**
     * Updates the input values based on the Phaser 3 keyboard implementation.
     */
    update(): void {
        if (this.inputLocked) {
            // this.reset();
            return;
        }

        this._up = this.cursorKeys.up.isDown;
        this._down = this.cursorKeys.down.isDown;
        this._left = this.cursorKeys.left.isDown;
        this._right = this.cursorKeys.right.isDown;
        this._shoot = this.cursorKeys.space.isDown;
    }
}
