// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../ota-slots/prefabs/Text";
/* START-USER-IMPORTS */
import { CUSTOM_EVENTS } from "../../components/events/EventBusComponent";
import { EventBusComponent } from "../../components/events/EventBusComponent";
import PushComponent from "../../components/animations/PushComponent";
/* END-USER-IMPORTS */

export default class WinningConsole extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 424);

        // image_1
        const image_1 = scene.add.image(0, 314, "winning_console", "dialog.png");
        this.add(image_1);

        // claimButton
        const claimButton = scene.add.image(0, 369, "winning_console", "remis_claim_btn 1.png");
        this.add(claimButton);

        // text
        const text = new Text(scene, 15, 255);
        text.text =
            "Inventore excepturi rerum sunt asperiores nemo ullam. Ipsa aliquam quibusdam accusantium. Ad amet dolores distinctio ducimus magnam ullam velit.";
        text.setLineSpacing(10.5);
        text.setWordWrapWidth(360);
        this.add(text);

        this.claimButton = claimButton;

        /* START-USER-CTR-CODE */
        new PushComponent(
            this.claimButton,
            () => {
                this.eventBusComponent.emit(CUSTOM_EVENTS.CLAIM_BOSS_REWARD);
            },
            this,
        ).active();
        /* END-USER-CTR-CODE */
    }

    private claimButton: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    private eventBusComponent: EventBusComponent;

    init(eventBusComponent: EventBusComponent) {
        this.eventBusComponent = eventBusComponent;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
