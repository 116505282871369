// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Reels from "../prefabs/normal-spin/Reels";
import Text from "../prefabs/Text";
import TotalWin from "../prefabs/normal-spin/TotalWin";
/* START-USER-IMPORTS */
import {
    SlotMachineFreeSpin,
    SlotMachineFreeSpinResponse,
    spinPiggyJackpot,
    SpinPiggyJackpotResponse,
    spinSlotMachineFreeSpin,
} from "@/lib/api/telegram-backend/client";
import { sleep } from "@/lib/utils";
import WinningPopup from "../prefabs/popup/WinningPopup";
import { DEPTH_PRIORITY, SCENE_KEYS } from "@/ota-slots/constants";
import { setupBackground } from "../helpers/setup-background";
/* END-USER-IMPORTS */

export default class FreeSpin extends Phaser.Scene {
    constructor() {
        super("SlotsFreeSpin");

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // free_spin_bg
        const free_spin_bg = this.add.image(195, 422, "free_spin_bg");
        free_spin_bg.visible = false;

        // free_spin_reel_png
        this.add.image(195, 401, "free_spin", "free spin reel.png");

        // free_spin_bottom_console_png
        this.add.image(195, 717, "free_spin", "free spin bottom console.png");

        // free_spin_top_console_png
        this.add.image(195, 83, "free_spin", "free spin top console.png");

        // reels
        const reels = new Reels(this, 12, 220);
        this.add.existing(reels);

        // spinTotal
        const spinTotal = new Text(this, 195, 198);
        this.add.existing(spinTotal);
        spinTotal.text = "0/6 spins";
        spinTotal.setStyle({ "fontSize": "32px" });

        // totalWin
        const totalWin = new TotalWin(this, 189, 8);
        this.add.existing(totalWin);
        totalWin.visible = false;

        this.reels = reels;
        this.spinTotal = spinTotal;
        this.totalWin = totalWin;

        this.events.emit("scene-awake");
    }

    private reels!: Reels;
    private spinTotal!: Text;
    private totalWin!: TotalWin;

    /* START-USER-CODE */
    public slotMachineFreeSpinResponse: SlotMachineFreeSpinResponse;
    public totalPiggyJackpotReward: number = 0;
    public spinData: { spinId: string; isAuto: boolean };
    public resolve: (value: unknown) => void;

    init(spinData: { spinId: string; isAuto: boolean }) {
        this.spinData = spinData;

        this.setupBackground();
    }

    async create() {
        this.editorCreate();
        const slotMachineFreeSpinResponse = await spinSlotMachineFreeSpin(this.spinData.spinId);
        if (slotMachineFreeSpinResponse) {
            this.slotMachineFreeSpinResponse = slotMachineFreeSpinResponse;
            this.setupSpinTotal(0);
            this.doFreeSpin();
        } else {
            this.scene.start(SCENE_KEYS.GAME);
        }

        this.events.on("wake", this.onWake, this);
    }

    setupBackground() {
        setupBackground("./ota-slots/assets/free_spin_bg.png");
    }

    async onWake(sys: Phaser.Scene, data: { finalReward: number }) {
        this.setupBackground();
        if (!data) return;
        this.totalPiggyJackpotReward = data.finalReward + this.totalPiggyJackpotReward;

        const totalWinAmount = data.finalReward + this.totalWin.currentTotalWin;
        this.totalWin.setAmount(totalWinAmount);

        this.resolve(true);
        this.resolve = () => {};
    }

    setupSpinTotal(spinCount: number) {
        this.spinTotal.text = `${spinCount}/${this.slotMachineFreeSpinResponse.length} spins`;
    }

    async doFreeSpin() {
        this.totalWin.hide();
        for (let i = 0; i < this.slotMachineFreeSpinResponse.length; i++) {
            this.setupSpinTotal(i + 1);
            const spinData = this.slotMachineFreeSpinResponse[i];
            // prefetch pick bonus data
            if (spinData.isPiggyJackpotTrigger) {
                spinPiggyJackpot(spinData.spinId).then((res) => {
                    (spinData as any).piggyJackpotResponse = res as SpinPiggyJackpotResponse;
                });
            }
            this.reels.cancelHighlightPaylines();
            await this.reels.spinReels(spinData);
            const totalWinAmount = spinData.totalFreeSpinReward + this.totalWin.currentTotalWin;

            this.totalWin.setAmount(totalWinAmount);

            if (spinData.isPiggyJackpotTrigger) {
                await this.handlePickBonus(spinData);
                await sleep(1500);
            } else {
                await sleep(2500);
            }
        }
        this.handleWinPopup();
    }

    handlePickBonus(spinData: SlotMachineFreeSpin) {
        return new Promise((resolve) => {
            this.scene.sleep(SCENE_KEYS.FREE_SPIN).launch(SCENE_KEYS.PICK_BONUS, {
                sceneKey: SCENE_KEYS.FREE_SPIN,
                spinData: {
                    ...spinData,
                    isAuto: this.spinData.isAuto,
                },
            });

            this.resolve = resolve;
        });
    }

    handleWinPopup() {
        const totalFreeSpinReward = this.slotMachineFreeSpinResponse.reduce(
            (sum, spinData) => sum + spinData.totalFreeSpinReward,
            0,
        );
        const totalWinAmount = totalFreeSpinReward + this.totalPiggyJackpotReward;

        const popupContainer = new WinningPopup(this, 0, 0);
        popupContainer.winAmount = totalWinAmount;
        popupContainer.winType = "you_win";
        popupContainer.isAuto = this.spinData.isAuto;

        popupContainer.setAlpha(0);
        popupContainer.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        this.add.existing(popupContainer);
        this.tweens.add({
            targets: popupContainer,
            alpha: { from: 0, to: 1 },
            duration: 300,
            onComplete: () => {
                popupContainer.playWinAnimation();
            },
        });

        popupContainer.once("destroy", () => {
            this.scene.stop(SCENE_KEYS.FREE_SPIN).wake(SCENE_KEYS.GAME, {
                finalReward: totalWinAmount,
                fromScene: SCENE_KEYS.FREE_SPIN,
                isAuto: this.spinData.isAuto,
            });
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
