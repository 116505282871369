import Phaser from "phaser";
import { CUSTOM_EVENTS, EventBusComponent } from "../events/EventBusComponent";
import Player from "../..//prefab/Player";

/**
 * Creates a Phaser 3 Group that is used for spawning enemy
 * game objects in our game. This spawner component will generate
 * new enemies on an interval, and spawn those enemies in random
 * locations outside our Phaser 3 Scene.
 *
 * The Phaser 3 Group is used to create a simple object pool that allows
 * us to reuse the enemy game objects that are created.
 */
export class CreepSpawnerComponent {
    private scene: Phaser.Scene;
    private player: Player;
    private spawnInterval: number;
    private spawnAt: number;
    private spawnCount: number;
    private group: Phaser.GameObjects.Group;
    private disableSpawning: boolean;
    private eventBusComponent: EventBusComponent;
    private spawnTimers: Phaser.Time.TimerEvent[] = [];

    constructor(
        scene: Phaser.Scene,
        player: Player,
        enemyClass: typeof Phaser.Physics.Arcade.Sprite | typeof Phaser.GameObjects.Container,
        spawnConfig: {
            interval: number;
            spawnAt: number;
            spawnCount: number;
        },
        eventBusComponent: EventBusComponent,
    ) {
        this.scene = scene;
        this.player = player;
        this.eventBusComponent = eventBusComponent;
        // create group
        this.group = this.scene.add.group({
            name: `${this.constructor.name}-${Phaser.Math.RND.uuid()}`,
            classType: enemyClass as unknown as Function,
            runChildUpdate: true,
            createCallback: (enemy) => {
                // @ts-ignore
                enemy.init(eventBusComponent);
            },
        });

        this.spawnInterval = spawnConfig.interval;
        this.spawnAt = spawnConfig.spawnAt;
        this.spawnCount = spawnConfig.spawnCount;
        this.disableSpawning = false;

        // handle automatic call to update
        this.scene.events.on(Phaser.Scenes.Events.UPDATE, this.update, this);
        this.scene.physics.world.on(Phaser.Physics.Arcade.Events.WORLD_STEP, this.worldStep, this);

        this.scene.events.once(
            Phaser.Scenes.Events.DESTROY,
            () => {
                this.scene.events.off(Phaser.Scenes.Events.UPDATE, this.update, this);
                this.scene.physics.world.off(Phaser.Physics.Arcade.Events.WORLD_STEP, this.worldStep, this);
            },
            this,
        );
        this.scene.events.once(
            Phaser.Scenes.Events.SHUTDOWN,
            () => {
                this.scene.events.off(Phaser.Scenes.Events.UPDATE, this.update, this);
                this.spawnTimers.forEach((timer) => timer.destroy());
                this.spawnTimers = [];
            },
            this,
        );
    }

    get phaserGroup(): Phaser.GameObjects.Group {
        return this.group;
    }

    destroy() {
        this.disableSpawning = true;
        this.scene.events.off(Phaser.Scenes.Events.UPDATE, this.update, this);
        this.scene.physics.world.off(Phaser.Physics.Arcade.Events.WORLD_STEP, this.worldStep, this);
        this.spawnTimers.forEach((timer) => timer.destroy());
        this.spawnTimers = [];

        // Destroy all active enemies
        this.group.getChildren().forEach((enemy: Phaser.GameObjects.GameObject) => {
            if (enemy.active) {
                enemy.destroy();
            }
        });

        // Clear the group
        this.group.clear(true, true);
    }

    update(ts: DOMHighResTimeStamp, dt: number): void {
        if (this.disableSpawning) {
            return;
        }

        this.spawnAt -= dt;
        if (this.spawnAt > 0) {
            return;
        }

        const creepsToSpawn = Phaser.Math.RND.between(5, 7); // Adjust this number as needed
        const spawnDelay = this.spawnInterval / creepsToSpawn; // Delay between each spawn

        for (let i = 0; i < creepsToSpawn; i++) {
            const timer = this.scene.time.addEvent({
                delay: i * spawnDelay,
                callback: () => {
                    const y = Phaser.Math.RND.between(this.player.y, this.player.y + 10);
                    const enemy = this.group.get(this.scene.scale.width + 30, y) as
                        | Phaser.Physics.Arcade.Sprite
                        | Phaser.GameObjects.Container;
                    // @ts-ignore
                    enemy.reset();
                    this.eventBusComponent.emit(CUSTOM_EVENTS.ENEMY_SPAWNED, enemy);
                },
                callbackScope: this,
            });
            this.spawnTimers.push(timer);
        }

        this.spawnAt = this.spawnInterval;
    }

    worldStep(delta: number): void {
        (this.group.getChildren() as (Phaser.Physics.Arcade.Sprite | Phaser.GameObjects.Container)[]).forEach(
            (enemy) => {
                if (!enemy.active) {
                    return;
                }

                if (enemy.x <= -50) {
                    enemy.setActive(false);
                    enemy.setVisible(false);
                }
            },
        );
    }
}
