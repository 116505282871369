// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../../ota-slots/prefabs/Text";
import LootBoxIcon from "../../../../shared/prefabs/loot-box/LootBoxIcon";
/* START-USER-IMPORTS */
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import { CUSTOM_EVENTS, EventBusComponent } from "../../components/events/EventBusComponent";
/* END-USER-IMPORTS */

export default class TopConsole extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 79);

        // image_1
        const image_1 = scene.add.image(0, 0, "mining", "console.png");
        this.add(image_1);

        // image_2
        const image_2 = scene.add.image(-35, 12, "mining", "Frame 427318260.png");
        this.add(image_2);

        // image
        const image = scene.add.image(83, 12, "mining", "Frame 427318260.png");
        this.add(image);

        // image_3
        const image_3 = scene.add.image(24, 12, "mining", "Frame 427318264.png");
        this.add(image_3);

        // image_4
        const image_4 = scene.add.image(-100, 14, "mining", "Frame 427318379.png");
        this.add(image_4);

        // image_5
        const image_5 = scene.add.image(-105, -58, "mining", "Head 1.png");
        this.add(image_5);

        // image_6
        const image_6 = scene.add.image(1, -67, "mining", "Coin2 1.png");
        image_6.scaleX = 0.5;
        image_6.scaleY = 0.5;
        this.add(image_6);

        // image_7
        const image_7 = scene.add.image(2, -47, "mining", "artifacts_05.png");
        this.add(image_7);

        // text
        const text = new Text(scene, -102, 34);
        text.text = "Event Boss";
        text.setStyle({ "fontSize": "8px" });
        this.add(text);

        // text_1
        const text_1 = new Text(scene, -35, 34);
        text_1.text = "Referral";
        text_1.setStyle({ "fontSize": "8px" });
        this.add(text_1);

        // text_2
        const text_2 = new Text(scene, 25, 34);
        text_2.text = "Wallet";
        text_2.setStyle({ "fontSize": "8px" });
        this.add(text_2);

        // text_3
        const text_3 = new Text(scene, 84, 33);
        text_3.text = "Mission";
        text_3.setStyle({ "fontSize": "8px" });
        this.add(text_3);

        // playerName
        const playerName = new Text(scene, -83, -69);
        playerName.setOrigin(0, 0);
        playerName.text = "Player";
        playerName.setStyle({ "fontSize": "10px" });
        this.add(playerName);

        // playerLevel
        const playerLevel = new Text(scene, -83, -51);
        playerLevel.setOrigin(0, 0);
        playerLevel.text = "Level 0";
        playerLevel.setStyle({ "fontSize": "10px" });
        this.add(playerLevel);

        // goldAmount
        const goldAmount = new Text(scene, 21, -70);
        goldAmount.setOrigin(0, 0);
        goldAmount.text = "0 Coin";
        goldAmount.setStyle({ "fontSize": "10px" });
        this.add(goldAmount);

        // rmxAmount
        const rmxAmount = new Text(scene, 22, -51);
        rmxAmount.setOrigin(0, 0);
        rmxAmount.text = "0 RMX";
        rmxAmount.setStyle({ "fontSize": "10px" });
        this.add(rmxAmount);

        // lootBoxIcon
        const lootBoxIcon = new LootBoxIcon(scene, 104, -73);
        this.add(lootBoxIcon);

        // text_8
        const text_8 = new Text(scene, 104, -27);
        text_8.text = "LOOTBOX";
        text_8.setStyle({ "fontSize": "8px" });
        this.add(text_8);

        this.playerName = playerName;
        this.playerLevel = playerLevel;
        this.goldAmount = goldAmount;
        this.rmxAmount = rmxAmount;
        this.lootBoxIcon = lootBoxIcon;

        /* START-USER-CTR-CODE */
        this.setupLootBoxIcon();
        /* END-USER-CTR-CODE */
    }

    private playerName: Text;
    private playerLevel: Text;
    private goldAmount: Text;
    private rmxAmount: Text;
    private lootBoxIcon: LootBoxIcon;

    /* START-USER-CODE */
    public eventBusComponent: EventBusComponent;

    setupLootBoxIcon() {
        this.lootBoxIcon.on("sync-user", async () => {
            const cachedUser = this.scene.userManager.getData();
            const updatedUser = await this.scene.userManager.syncWithBackend();
            if (!cachedUser || !updatedUser) return;

            this.playerLevel.setText(`Level ${updatedUser.miningLevel}`);

            this.scene.tweens.addCounter({
                from: cachedUser.otaGold,
                to: updatedUser.otaGold,
                duration: 500,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    this.goldAmount.setText(numberWithCommas(Number(tween.getValue().toFixed(3))));
                },
            });

            this.scene.tweens.addCounter({
                from: cachedUser.rmx,
                to: updatedUser.rmx,
                duration: 600,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    this.rmxAmount.setText(numberWithCommas(Number(tween.getValue().toFixed(3))));
                },
            });
        });
    }

    updateUser() {
        const user = this.scene.userManager.getData();
        if (user) {
            this.playerName.setText(user.username);
            this.playerLevel.setText(`Level ${user.miningLevel}`);

            this.scene.tweens.addCounter({
                from: 0,
                to: user.otaGold,
                duration: 500,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    this.goldAmount.setText(numberWithCommas(Number(tween.getValue().toFixed(3))));
                },
            });

            this.scene.tweens.addCounter({
                from: 0,
                to: user.rmx,
                duration: 600,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    this.rmxAmount.setText(numberWithCommas(Number(tween.getValue().toFixed(3))));
                },
            });
        }
    }

    init(eventBusComponent: EventBusComponent, disableLootBox?: boolean) {
        const updateUser = this.updateUser.bind(this);
        this.eventBusComponent = eventBusComponent;
        eventBusComponent.on(CUSTOM_EVENTS.CLAIM_REWARD_SUCCESS, updateUser);

        this.updateUser();
        this.lootBoxIcon.init(disableLootBox);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
