import type { components } from "@/lib/api/telegram-backend/generated/schema";
import { OPTIONS, PAYLINES } from "./constants";
import Reels from "./prefabs/normal-spin/Reels";
import { SlotMachineResponse } from "@/lib/api/telegram-backend/client";

export interface PaylineSymbol {
    symbol: string;
    match: boolean;
    position: number;
}

type WinningPayline = components["schemas"]["WinningPaylineDto"];

export class PaylineAnimationCancelledError extends Error {
    constructor() {
        super();
        this.name = "PaylineAnimationCancelledError";
    }
}

export class HighlightPaylinesAnimationsManager {
    reel: Reels;
    public animationController: AbortController | null = null;
    public isHighlightingPaylines: boolean = false;

    constructor(reel: Reels) {
        this.reel = reel;
    }

    createAnimationController() {
        this.animationController = new AbortController();
        return this.animationController.signal;
    }

    public async cancelHighlightPaylines() {
        if (this.animationController) {
            this.animationController.abort();
            this.isHighlightingPaylines = false;
        }
    }

    private isSymbolMatch(symbol: string, winningSymbol: string): boolean {
        return symbol === winningSymbol;
    }

    private isWildSymbol(symbol: string) {
        return symbol === "symbol_11_black_hole";
    }

    private isBonusSymbol(symbol: string) {
        return symbol === "symbol_10_spaceship";
    }

    private isFreeSymbol(symbol: string) {
        return symbol === "symbol_12_scatter";
    }

    private isSpecialSymbol(symbol: string) {
        return this.isBonusSymbol(symbol) || this.isFreeSymbol(symbol) || this.isWildSymbol(symbol);
    }

    public getPaylineSymbols(spinData: SlotMachineResponse, winningPayline: WinningPayline): PaylineSymbol[] {
        const result: PaylineSymbol[] = [];

        for (const position of winningPayline.paylineDetail) {
            const col = position % 5;
            const row = Math.floor(position / 5);
            const symbol = spinData.spinResult[col][row];
            const match = this.isSymbolMatch(symbol, winningPayline.symbol);
            result.push({ symbol, match, position });
        }

        return result;
    }

    public getBonusFreeSymbols(spinData: SlotMachineResponse): {
        bonus: PaylineSymbol[];
        free: PaylineSymbol[];
    } {
        const bonus: PaylineSymbol[] = [];
        const free: PaylineSymbol[] = [];

        for (let colIndex = 0; colIndex < spinData.spinResult.length; colIndex++) {
            const col = spinData.spinResult[colIndex];
            for (let rowIndex = 0; rowIndex < col.length; rowIndex++) {
                const row = col[rowIndex];
                if (this.isBonusSymbol(row)) {
                    bonus.push({
                        symbol: row,
                        match: spinData.isPiggyJackpotTrigger,
                        position: colIndex + rowIndex * OPTIONS.cols,
                    });
                }
                if (this.isFreeSymbol(row)) {
                    free.push({
                        symbol: row,
                        match: spinData.isFreeSpinTrigger,
                        position: colIndex + rowIndex * OPTIONS.cols,
                    });
                }
            }
        }
        return {
            bonus,
            free,
        };
    }

    public animateSymbol = (
        paylineSymbol: PaylineSymbol,
        showFrameRound?: boolean,
        signal?: AbortSignal,
    ): Promise<() => void> => {
        return new Promise((resolve, reject) => {
            let frameRound: Phaser.GameObjects.Sprite;
            let symbolId: string;
            let symbol: Phaser.GameObjects.Sprite;

            const stopSymbol = () => {
                if (symbol && symbolId) {
                    symbol.stop();
                    symbol.setFrame(0);
                    symbol.setTexture("symbols", `${symbolId}.png`);
                }
            };

            const stopFrameRound = () => {
                frameRound?.destroy();
            };

            const abort = () => {
                stopSymbol();
                stopFrameRound();
                reject(new PaylineAnimationCancelledError());
            };

            signal?.addEventListener("abort", abort);

            if (signal?.aborted || !this.isHighlightingPaylines) {
                abort();
                return;
            }

            try {
                if (!paylineSymbol?.match && !this.isWildSymbol(paylineSymbol.symbol)) {
                    resolve(() => {});
                    return;
                }

                const row = Math.floor(paylineSymbol.position / this.reel.reelContainers.length);
                const col = paylineSymbol.position % this.reel.reelContainers.length;
                if (!this.reel.reelContainers[col]) return;

                const rowIdx = Math.abs(row - (OPTIONS.rows + 1));
                symbol = this.reel.reelContainers[col].list.find(
                    (item: any) => item.idx === rowIdx && item.frame.name.replace(".png", "") === paylineSymbol.symbol,
                ) as Phaser.GameObjects.Sprite;

                if (!symbol) {
                    reject(`Can't fint symbol ${paylineSymbol}`);
                    return;
                }

                if (showFrameRound) {
                    frameRound = this.reel.scene.add.sprite(
                        symbol.x,
                        symbol.y + 5,
                        "frame_round",
                        "264x264_round_00000.png",
                    );
                    frameRound.scale = 0.225;
                    frameRound.play("frame_round");
                    this.reel.reelContainers[col].addAt(frameRound, 0);
                }

                symbolId = symbol.frame.name.replace(".png", "");
                const repeat = this.isSpecialSymbol(paylineSymbol.symbol) ? 0 : 1;

                // idx 4 -> row 0;
                // idx 3 -> row 1;
                // idx 2 -> row 2;

                symbol.play({
                    key: symbolId,
                    repeat,
                });

                symbol.once("animationcomplete", () => {
                    if (signal?.aborted || !this.isHighlightingPaylines) {
                        abort();
                        return;
                    }
                    stopSymbol();
                    resolve(() => {
                        if (showFrameRound) {
                            this.reel.scene.tweens.add({
                                targets: frameRound,
                                alpha: { from: 1, to: 0 },
                                duration: 400,
                                delay: 100,
                                onComplete: () => {
                                    stopFrameRound();
                                },
                            });
                        }
                    });
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    public animatePayline = (win: WinningPayline, signal?: AbortSignal) => {
        const paylineNumber = PAYLINES.findIndex((p) => p.toString() === win.paylineDetail.toString()) + 1;
        let paylineSprite: Phaser.GameObjects.Image;

        signal?.addEventListener("abort", () => {
            paylineSprite?.destroy?.();
        });

        if (!paylineNumber || signal?.aborted || !this.isHighlightingPaylines) {
            return () => {};
        }

        paylineSprite = this.reel.scene.add.image(16, 50, "lines", `Line${paylineNumber}.png`);
        paylineSprite.scale = 0.25;
        paylineSprite.setOrigin(0, 0);
        paylineSprite.setDepth(-1);
        this.reel.addAt(paylineSprite, 0);

        return () => {
            paylineSprite.destroy();
        };
    };
}
