export abstract class DataManager<T> extends Phaser.Plugins.ScenePlugin {
    public abstract key: string;

    constructor(scene: Phaser.Scene, pluginManager: Phaser.Plugins.PluginManager, pluginKey: string) {
        super(scene, pluginManager, pluginKey);
    }

    abstract syncWithBackend(...args: any[]): Promise<T | undefined>;

    setData(value: T): void {
        this.game.registry.set(this.key, value);
    }

    getData(): T | undefined {
        return this.game.registry.get(this.key);
    }

    removeData(): void {
        this.game.registry.set(this.key, undefined);
    }

    // Subscribe to data changes
    on(event: string, callback: (data: T) => void): void {
        this.game.registry.events.on(
            event,
            (_: any, key: string, data: T) => {
                if (key === this.key) {
                    callback(data);
                }
            },
            this,
        );
    }
}
