import Phaser from "phaser";
import { CUSTOM_EVENTS, EventBusComponent } from "../events/EventBusComponent";
import Creep from "../../prefab/Creep";

/**
 * Creates a Phaser 3 Group that is used for spawning game
 * objects when an enemy game object is destroyed. These
 * game objects will play the destroyed animation that is associated
 * with that enemy game object.
 *
 * The Phaser 3 Group is used to create a simple object pool that allows
 * us to reuse the enemy game objects that are created.
 */
export class CreepDestroyedComponent {
    private scene: Phaser.Scene;
    private group: Phaser.GameObjects.Group;
    private eventBusComponent: EventBusComponent;

    constructor(scene: Phaser.Scene, eventBusComponent: EventBusComponent) {
        this.scene = scene;
        this.eventBusComponent = eventBusComponent;

        // create group
        this.group = this.scene.add.group({
            name: `${this.constructor.name}-${Phaser.Math.RND.uuid()}`,
        });

        this.eventBusComponent.on(CUSTOM_EVENTS.CREEP_DEAD, (creep: Creep) => {
            const gameObject = this.group.get(
                creep.x + 100,
                creep.y,
                creep.creepAssetKey,
                0,
            ) as Phaser.GameObjects.Sprite;
            gameObject.play({
                key: creep.destroyedAnimationKey,
            });

            const dropX = creep.x;
            const dropY = creep.y;
            const coinReward = this.scene.add.sprite(dropX, dropY, "coin_icon");

            // Add tween for coin reward animation moving up then down
            this.scene.tweens.add({
                targets: coinReward,
                y: {
                    value: dropY - 50, // Move up first
                    duration: 200,
                    ease: "Quad.easeOut",
                },
                onComplete: () => {
                    // Second tween to move down
                    this.scene.tweens.add({
                        targets: coinReward,
                        x: 75,
                        y: 685, // Move down to final position
                        scale: 0,
                        duration: 500,
                        ease: "Quad.easeIn",
                        onComplete: () => {
                            coinReward.destroy();
                        },
                    });

                    const rmxReward = this.scene.add.sprite(dropX, dropY, "rmx_icon");
                    this.scene.tweens.add({
                        targets: rmxReward,
                        y: {
                            value: dropY - 50, // Move up first
                            duration: 200,
                            ease: "Quad.easeOut",
                        },
                        onComplete: () => {
                            // Second tween to move down
                            this.scene.tweens.add({
                                targets: rmxReward,
                                x: 200,
                                y: 685, // Move down to final position
                                scale: 0,
                                duration: 500,
                                ease: "Quad.easeIn",
                                onComplete: () => {
                                    rmxReward.destroy();
                                },
                            });
                        },
                    });
                },
            });
        });
    }
}
