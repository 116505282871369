// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import { numberWithCommas } from "@/ota-slots/utils/formatter";
import Text from "../Text";
/* END-USER-IMPORTS */

export default class WinningPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // background
        const background = scene.add.rectangle(195, 422, 390, 844);
        background.isFilled = true;
        background.fillColor = 0;
        background.fillAlpha = 0.8;
        this.add(background);

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    public winAmount: number = 0;
    public winType: "you_win" | "big_win" = "you_win";
    public isAuto: boolean = false;

    /* START-USER-CODE */

    playWinAnimation() {
        let win;
        if (this.winType === "big_win") {
            win = this.scene.add.sprite(
                195,
                422, // 130
                "big_win_1",
                "big_win_animation_00000.png",
            );
        } else if (this.winType === "you_win") {
            win = this.scene.add.sprite(
                195,
                468, // 97
                "you_win",
                "you_win_coins_anim_00000.png",
            );
        }

        if (!win) return;

        win.scaleX = 0.4;
        win.scaleY = 0.4;
        win.play(this.winType);
        this.scene.time.delayedCall(1000, () => {
            // winAmountText
            const winAmountText = new Text(this.scene, 195, 485);

            winAmountText.setOrigin(0.5, 0.5);
            winAmountText.text = "0";
            winAmountText.setStyle({
                color: "#f3e192",
                fontSize: "24px",
                stroke: "#f3e192",
            });
            this.add(winAmountText);

            // collect
            const collect = this.scene.add.image(135, 540, "popup", "collect_button_01.png");
            collect.scaleX = 0.4;
            collect.scaleY = 0.4;
            this.add(collect);

            // share_button_01_png
            const share = this.scene.add.image(255, 540, "popup", "share_button_01.png");
            share.scaleX = 0.4;
            share.scaleY = 0.4;
            this.add(share);

            // coin_icon_png
            const coin = this.scene.add.image(110, 485, "coin_icon");
            coin.scaleX = 0.5;
            coin.scaleY = 0.5;
            this.add(coin);

            this.scene.tweens.addCounter({
                from: 0,
                to: this.winAmount,
                duration: 500,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    winAmountText.setText(`${numberWithCommas(value)}`);
                },
                onComplete: () => {
                    collect.setInteractive();
                    collect.once("pointerdown", () => {
                        this.destroy();
                    });

                    this.scene.time.delayedCall(500, () => {
                        if (this.isAuto) {
                            this.destroy();
                        }
                    });
                },
            });
        });

        this.add(win);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
