import { DataManager } from "./data-manager";
import { ChapterConfig, chapters } from "@/ota-mining/game/chapters";

export class ChapterManager extends DataManager<ChapterConfig> {
    public key = "chapter";

    async syncWithBackend(chapter: number) {
        const chapterConfig = chapters[chapter];
        this.setData(chapterConfig);
        return chapterConfig;
    }
}
