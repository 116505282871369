import { telegramBackendApi } from "../client";
import { components } from "../generated/schema";

export type MiningClaimResponse = components["schemas"]["MiningClaimResponseDto"];

export const claimMiningReward = async (): Promise<MiningClaimResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/mining/claim");

    if (error) {
        alert(`The /mining/claim API returned an error: ${(error as Error).message}. Please try again.`);
        console.error("[verifyAppleIAPTransaction]", error);
        return;
    }

    return data as MiningClaimResponse;
};
