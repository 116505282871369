import { Scene } from "phaser";

export default class Boot extends Scene {
    constructor() {
        super("MiningBoot");
    }

    preload() {
        //  The Boot Scene is typically used to load in any assets you require for your Preloader, such as a game logo or background.
        //  The smaller the file size of the assets, the better, as the Boot Scene itself has no preloader.

        this.load.pack("mining-boot-asset-pack", "ota-mining/mining-boot-asset-pack.json");

        this.load.webfont("Oxanium", {
            custom: {
                families: ["Oxanium"],
            },
            active: () => {
                console.log("Oxanium font loaded");
            },
        });

        this.load.webfont("Saiba", {
            custom: {
                families: ["Saiba"],
            },
            active: () => {
                console.log("Saiba font loaded");
            },
        });
    }

    create() {
        this.scene.start("MiningPreloader");
    }
}
