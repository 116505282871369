export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function capitalize<T extends string>(str: T): Capitalize<T> {
    if (!str) return str as Capitalize<T>;
    return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
}

export function getUrlParams(): URLSearchParams {
    return new URLSearchParams(window.location.search);
}
