import { numberWithCommas } from "@/ota-slots/utils/formatter";
import { DEPTH_PRIORITY } from "@/ota-slots/constants";

/* START OF COMPILED CODE */

import Text from "../../../ota-slots/prefabs/Text";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class SoldItemPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // content
        const content = scene.add.container(192.6666717529297, 238);
        content.alpha = 0;
        this.add(content);

        // background
        const background = scene.add.rectangle(2.3333282470703125, 184, 390, 844);
        background.isFilled = true;
        background.fillColor = 0;
        background.fillAlpha = 0.8;
        content.add(background);

        // sold_item
        const sold_item = scene.add.image(22.333328247070312, 242, "sold-item");
        content.add(sold_item);

        // youReceived
        const youReceived = new Text(scene, 0, 0);
        youReceived.text = "you received";
        youReceived.setStyle({ "fontSize": "19px" });
        content.add(youReceived);

        // forSellingWeapon
        const forSellingWeapon = new Text(scene, 0, 83);
        forSellingWeapon.text = "of selling rifle";
        forSellingWeapon.setStyle({ "fontSize": "19px" });
        content.add(forSellingWeapon);

        // amount
        const amount = new Text(scene, 0, 43);
        amount.text = "1,000 gold";
        amount.setStyle({ "color": "#DEA614", "fontSize": "32px" });
        content.add(amount);

        // plusRemis
        const plusRemis = new Text(scene, 9.333328247070312, 367);
        plusRemis.text = "+remis";
        plusRemis.setStyle({ "color": "#E8DCB6", "fontSize": "19px" });
        content.add(plusRemis);

        this.forSellingWeapon = forSellingWeapon;
        this.amount = amount;
        this.content = content;

        /* START-USER-CTR-CODE */
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        /* END-USER-CTR-CODE */
    }

    private forSellingWeapon: Text;
    private amount: Text;
    private content: Phaser.GameObjects.Container;

    /* START-USER-CODE */
    setDisplayData(itemName: string, amount: number) {
        this.forSellingWeapon.setText(`for selling ${itemName}`);
        this.amount.setText(`${numberWithCommas(amount)} gold`);
    }

    fadeIn(duration = 500) {
        this.scene.tweens.add({
            targets: this.content,
            alpha: 1,
            duration: duration,
            ease: "Power2",
        });
    }

    fadeOut(duration = 500, onComplete?: () => void) {
        this.scene.tweens.add({
            targets: this.content,
            alpha: 0,
            duration: duration,
            ease: "Power2",
            onComplete,
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
