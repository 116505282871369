export enum Symbols {
    SATELLITE = 1,
    RED_PLANET = 2,
    PLANET_EARTH = 3,
    GREEN_ALIEN = 4,
    GIRL_ANDROID = 5,
    BATTERY = 6,
    SPACE_GIRL = 7,
    GIRL_ALIEN = 8,
    SPACE_CAPTAIN = 9,
    SPACESHIP = 10,
    BLACK_HOLE = 11,
    SCATTER = 12,
}

export const symbolMappingId = {
    "1": "satellite",
    "2": "red_planet",
    "3": "planet_Earth",
    "4": "green_alien",
    "5": "girl_android",
    "6": "battery",
    "7": "space_girl",
    "8": "girl_alien",
    "9": "space_captain",
    "10": "spaceship",
    "11": "black_hole",
    "12": "scatter",
};

// Higher weight means higher likelihood. Lower weight means lower likelihood.
const symbolWeights: Record<Symbols, number> = {
    [Symbols.SATELLITE]: 100,
    [Symbols.RED_PLANET]: 100,
    [Symbols.PLANET_EARTH]: 100,
    [Symbols.GREEN_ALIEN]: 100,
    [Symbols.GIRL_ANDROID]: 100,
    [Symbols.BATTERY]: 100,
    [Symbols.SPACE_GIRL]: 100,
    [Symbols.GIRL_ALIEN]: 100,
    [Symbols.SPACE_CAPTAIN]: 100,
    [Symbols.SPACESHIP]: 100,
    [Symbols.BLACK_HOLE]: 100,
    [Symbols.SCATTER]: 100,
};

export const OPTIONS = {
    symbolHeight: 77,
    duration: 100,
    repeat: [5, 10, 15, 20, 25], // repeat % totalSymbols = 0
    totalSymbols: 5, // > 3,
    rows: 3,
    cols: 5,
    weightedRandomSymbols: Object.entries(symbolWeights).map(([symbol, weight]) => ({
        symbol: parseInt(symbol) as Symbols,
        weight,
    })),
};

// The grid is a 5x3 grid representing the symbols on the slot machine.
// The grid positions are numbered from 0 to 14, left to right, top to bottom, as follows:
// --------------
//  0  1  2  3  4
//  5  6  7  8  9
// 10 11 12 13 14
// --------------
export const PAYLINES = [
    [5, 6, 7, 8, 9], // Payline 1
    [0, 1, 2, 3, 4], // Payline 2
    [10, 11, 12, 13, 14], // Payline 3
    [0, 6, 12, 8, 4], // Payline 4
    [10, 6, 2, 8, 14], // Payline 5
    [5, 1, 7, 3, 9], // Payline 6
    [5, 11, 7, 13, 9], // Payline 7
    [0, 1, 7, 13, 14], // Payline 8
    [10, 11, 7, 3, 4], // Payline 9
    [5, 11, 7, 3, 9], // Payline 10
    [5, 1, 7, 13, 9], // Payline 11
    [0, 6, 7, 8, 4], // Payline 12
    [10, 6, 7, 8, 14], // Payline 13
    [0, 6, 2, 8, 4], // Payline 14
    [10, 6, 12, 8, 14], // Payline 15
    [5, 6, 2, 8, 9], // Payline 16
    [5, 6, 12, 8, 9], // Payline 17
    [0, 1, 12, 3, 4], // Payline 18
    [10, 11, 2, 13, 14], // Payline 19
    [0, 11, 12, 13, 4], // Payline 20
];

export const DEPTH_PRIORITY = {
    DISPLAY_POPUP: 4,
};

export const SCENE_KEYS = {
    PRELOADER: "SlotsPreloader",
    BOOT: "SlotsBoot",
    GAME: "SlotsGame",
    PICK_BONUS: "SlotsPickBonus",
    FREE_SPIN: "SlotsFreeSpin",
    MINING_GAME: "MiningGame",
};
