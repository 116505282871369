// You can write more code here

/* START OF COMPILED CODE */

import { ScriptNode } from "@phaserjs/editor-scripts-core";
import Phaser from "phaser";
/* START-USER-IMPORTS */
import DurationConfigComp from "./DurationConfigComp";
/* END-USER-IMPORTS */

export default class PushComponent extends ScriptNode {
    constructor(
        parent: ScriptNode | Phaser.GameObjects.GameObject | Phaser.Scene,
        onExecute: () => void,
        context?: any,
    ) {
        super(parent);

        /* START-USER-CTR-CODE */
        this.onExecute = onExecute;
        this.context = context;
        /* END-USER-CTR-CODE */
    }

    /* START-USER-CODE */

    private _executing = false;
    private onExecute: () => void;
    private context: any;

    active(args?: any): void {
        const obj = this.getActionTargetObject(args) as Phaser.GameObjects.Sprite;

        if (!obj) return;

        obj.setInteractive().on("pointerdown", () => {
            console.log("pointerdown", this._executing);
            if (this._executing) {
                return;
            }

            this._executing = true;

            const duration = DurationConfigComp.getDuration(this, 80);
            const { scaleX, scaleY } = obj;

            this.scene.add.tween({
                targets: obj,
                scaleX: scaleX * 0.8,
                scaleY: scaleY * 0.8,
                duration,
                yoyo: true,
                onComplete: async () => {
                    await this.onExecute.call(this.context || obj);
                    this._executing = false;
                },
            });
        });
    }

    deactivate(args?: any): void {
        const obj = this.getActionTargetObject(args) as Phaser.GameObjects.Sprite;
        if (!obj) return;

        obj.off("pointerdown");
        obj.removeInteractive();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
