export const getWinType = (totalWinAmount: number, totalBet: number): "none" | "big_win" | "you_win" => {
    if (!totalWinAmount) {
        return "none";
    }
    const multiplier = totalWinAmount / totalBet;

    if (multiplier >= 10) {
        return "big_win";
    } else {
        return "you_win";
    }
};
