import { Game } from "phaser";
import { isTMA } from "@telegram-apps/bridge";

import { gameConfig } from "./game.config";
import { gameScenes } from "./game.scenes";
import { gamePlugins } from "./game.plugins";
import type { PlatformOSType, AuthInitData } from "../types";
import { EventBus, EVENTS } from "./lib/event-bus";
import { mockTelegramEnvironment } from "./lib/telegram/mock";
import { AudioComponent } from "./ota-mining/game/components/audio/AudioComponent";

declare module "phaser" {
    interface Game {
        audio: AudioComponent;
    }
}

class GameInitializer {
    private game: Game | undefined = undefined;

    private getAuthInitializationData(): AuthInitData {
        const urlParams = new URLSearchParams(window.location.search);
        const imotaAccessToken = urlParams.get("iat");
        const platform = urlParams.get("platform") as PlatformOSType;

        if (imotaAccessToken) {
            return {
                provider: "imota",
                payload: imotaAccessToken,
                platform: platform ?? "web",
            };
        }

        return {
            provider: "telegram",
            payload: window.Telegram.WebApp.initData,
            platform: "web",
        };
    }

    public start() {
        if (this.game) {
            return;
        }

        try {
            const isTelegramMiniApp = isTMA("simple");
            if (!isTelegramMiniApp) {
                mockTelegramEnvironment();
            }
            const authData = this.getAuthInitializationData();

            this.game = new Game({
                ...gameConfig,
                scene: gameScenes,
                plugins: { scene: [...gamePlugins.scene] },
                parent: "game-container",
            });

            this.game.audio = new AudioComponent(this.game);

            EventBus.on(EVENTS.READY_FOR_AUTH_DATA, () => {
                EventBus.emit(EVENTS.AUTH_INIT_DATA, authData);
            });
        } catch (error) {
            console.error("Failed to initialize game:", error);
        }

        // Handle window resize
        window.addEventListener("resize", () => {
            this.game?.scale.refresh();
        });
    }

    public destroy() {
        if (!this.game) {
            return;
        }
        EventBus.off(EVENTS.READY_FOR_AUTH_DATA);
        EventBus.off(EVENTS.AUTH_INIT_DATA);

        this.game.destroy(true);
        this.game = undefined;
    }
}

export const gameInitializer = new GameInitializer();
