import Text from "./Text";

interface ProgressbarOptions {
    progress?: number;
    orientation?: "vertical" | "horizontal";
    reverse?: boolean;
    radius?: number;
    backgroundColor?: number;
    backgroundAlpha?: number;
    color?: number;
    flat?: boolean;
    indicators?: {
        enabled: boolean;
        color: number;
        alpha: number;
        size: number;
        distance: number;
    };
    text?: {
        enabled: boolean;
        format: (progress: number) => string;
        style: Phaser.Types.GameObjects.Text.TextStyle;
        origin: {
            x: number;
            y: number;
        };
        align: {
            x: string;
            y: string;
        };
    };
    padding?: number;
    container?: Phaser.GameObjects.Container;
}

interface ForegroundRadii {
    tl: number;
    tr: number;
    bl: number;
    br: number;
}

interface Rectangle {
    x: number;
    y: number;
    width: number;
    height: number;
}

export default class EasyProgressbar extends Phaser.GameObjects.Graphics {
    private width: number;
    private height: number;
    private progress: number;
    private orientation: string;
    private reverse: boolean;
    private radius: number;
    private backgroundColor: number;
    private backgroundAlpha: number;
    private color: number;
    private flat: boolean;
    private indicatorsEnabled: boolean;
    private indicatorsColor: number;
    private indicatorsAlpha: number;
    private indicatorsSize: number;
    private indicatorsDistance: number;
    private textShow: boolean;
    private textFormat: (progress: number) => string;
    private textStyle: Phaser.Types.GameObjects.Text.TextStyle;
    private textOriginX: number;
    private textOriginY: number;
    private textAlignX: string;
    private textAlignY: string;
    private shadeColor: number;
    private tintColor: number;
    private padding: number;
    private text?: Phaser.GameObjects.Text;
    private container?: Phaser.GameObjects.Container;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        width: number,
        height: number,
        options: ProgressbarOptions = {},
    ) {
        super(scene);

        this.setPosition(x, y);
        this.width = width;
        this.height = height;
        this.progress = options.progress || 0;
        this.orientation = options.orientation || "horizontal";
        this.reverse = options.reverse || false;
        this.radius = options.radius || 0;
        this.backgroundColor = options.backgroundColor || 0x000000;
        this.backgroundAlpha = options.backgroundAlpha || 1;
        this.color = options.color || 0xffffff;
        this.flat = options.flat || false;
        this.indicatorsEnabled = options.indicators?.enabled || false;
        this.indicatorsColor = options.indicators?.color || 0x000000;
        this.indicatorsAlpha = options.indicators?.alpha || 1;
        this.indicatorsSize = options.indicators?.size || 1;
        this.indicatorsDistance = options.indicators?.distance || 0.1;
        this.textShow = options.text?.enabled || false;
        this.textFormat = options.text?.format || ((progress: number) => `${Math.round(progress * 100)}%`);
        this.textStyle = options.text?.style || {};
        this.textOriginX = options.text?.origin?.x || 0.5;
        this.textOriginY = options.text?.origin?.y || 0.5;
        this.textAlignX = options.text?.align?.x || "center";
        this.textAlignY = options.text?.align?.y || "center";
        this.padding = options.padding || 0;
        this.container = options.container;

        this.shadeColor = this._getShadeColor();
        this.tintColor = this._getTintColor();

        scene.add.existing(this);
        this._drawProgressbar();

        if (this.textShow) {
            this.text = new Text(scene, x, y);
            this.text.text = this.textFormat(this.progress);
            this.text.setStyle(this.textStyle);
            this.text.setOrigin(this.textOriginX, this.textOriginY);
            this.setTextAlign({ x: this.textAlignX, y: this.textAlignY });
            if (this.container) {
                this.container.add(this.text);
            } else {
                scene.add.existing(this.text);
            }
        }
    }

    public destroy(fromScene?: boolean): void {
        super.destroy(fromScene);
        if (this.text) {
            this.text.destroy();
        }
    }

    public setPosition(x: number, y: number): this {
        super.setPosition(x, y);
        if (this.text) {
            this.text.setPosition(x, y);
            this.setTextAlign({ x: this.textAlignX, y: this.textAlignY });
        }
        return this;
    }

    public setProgress(progress: number, animate: boolean = false): void {
        const _drawProgressbar = this._drawProgressbar.bind(this);
        if (!animate) {
            this.progress = Math.max(Math.min(progress, 1), 0);
            this._drawProgressbar();
        } else {
            const tween = this.scene.tweens.add({
                targets: this,
                progress: {
                    from: this.progress,
                    to: EasyProgressbar._limitProgress(progress),
                },
                onUpdate: function (this: EasyProgressbar) {
                    _drawProgressbar();
                },
                onUpdateScope: this,
                onComplete: function () {
                    tween.remove();
                },
                ease: "Cubic.Out",
                duration: 300,
                repeat: 0,
                yoyo: false,
            });
        }
    }

    public setText(text: string): void {
        if (this.text) {
            this.text.setText(text);
        }
    }

    public setTextAlign(align: { x?: string; y?: string }): void {
        if (this.text && align) {
            const allowedAligns = [
                { name: "start", value: 0 },
                { name: "center", value: 0.5 },
                { name: "end", value: 1 },
            ];

            const x = allowedAligns.find((entry) => entry.name === align.x);
            if (x) {
                this.text.x = this.width * x.value + this.x;
            }

            const y = allowedAligns.find((entry) => entry.name === align.y);
            if (y) {
                this.text.y = this.height * y.value + this.y;
            }
        }
    }

    private _drawProgressbar(): void {
        this.clear();

        this.fillStyle(this.backgroundColor, this.backgroundAlpha);
        if (this.radius > 0) {
            this.fillRoundedRect(0, 0, this.width, this.height, this.radius);
        } else {
            this.fillRect(0, 0, this.width, this.height);
        }

        this._updateProgressText();

        if (this.progress > 0) {
            if (this.flat) {
                this.fillStyle(this.color, 1);

                const rect = this._getForeGroundRectangleFromOrientation();
                if (this.radius > 0) {
                    this.fillRoundedRect(
                        rect.x,
                        rect.y,
                        rect.width,
                        rect.height,
                        this._getForegroundRadiiFromOrientation(),
                    );
                } else {
                    this.fillRect(rect.x, rect.y, rect.width, rect.height);
                }
            } else {
                const radii = this._getForegroundRadiiFromOrientation();
                const vertical = this.orientation === "vertical";
                const distanceInPercent = 0.1;
                const space = ((vertical ? this.width : this.height) - 2 * this.padding) * distanceInPercent;
                const rect = this._getForeGroundRectangleFromOrientation();

                /* middle */
                this.fillStyle(this.color, 1);
                this.fillRect(
                    vertical ? rect.x + Math.max(this.radius, space) : rect.x,
                    vertical ? rect.y : rect.y + Math.max(this.radius, space),
                    vertical ? rect.width - 2 * Math.max(space, this.radius) : rect.width,
                    vertical ? rect.height : rect.height - 2 * Math.max(space, this.radius),
                );

                /* top */
                this.fillStyle(this.tintColor, 1);

                if (this.radius > 0) {
                    this.fillRoundedRect(
                        vertical ? rect.x : rect.x,
                        vertical ? rect.y : rect.y,
                        vertical ? Math.max(space, this.radius) : rect.width,
                        vertical ? rect.height : Math.max(space, this.radius),
                        {
                            br: 0,
                            bl: vertical ? radii.bl : 0,
                            tr: vertical ? 0 : radii.tr,
                            tl: radii.tl,
                        },
                    );
                } else {
                    this.fillRect(
                        vertical ? rect.x : rect.x,
                        vertical ? rect.y : rect.y,
                        vertical ? Math.max(space, this.radius) : rect.width,
                        vertical ? rect.height : Math.max(space, this.radius),
                    );
                }

                /* bottom */
                this.fillStyle(this.shadeColor, 1);
                if (this.radius > 0) {
                    this.fillRoundedRect(
                        vertical ? rect.x + rect.width - Math.max(space, this.radius) : rect.x,
                        vertical ? rect.y : rect.y + rect.height - Math.max(space, this.radius),
                        vertical ? Math.max(space, this.radius) : rect.width,
                        vertical ? rect.height : Math.max(space, this.radius),
                        {
                            tr: vertical ? radii.tr : 0,
                            tl: 0,
                            br: radii.br,
                            bl: vertical ? 0 : radii.bl,
                        },
                    );
                } else {
                    this.fillRect(
                        vertical ? rect.x + rect.width - Math.max(space, this.radius) : rect.x,
                        vertical ? rect.y : rect.y + rect.height - Math.max(space, this.radius),
                        vertical ? Math.max(space, this.radius) : rect.width,
                        vertical ? rect.height : Math.max(space, this.radius),
                    );
                }
            }

            if (this.indicatorsEnabled) {
                const vertical = this.orientation === "vertical";
                const step = this.indicatorsDistance;
                const stepSize = vertical ? this.height * step : this.width * step;

                this.fillStyle(this.indicatorsColor, this.indicatorsAlpha);
                const rect = this._getForeGroundRectangleFromOrientation();

                for (let i = 1; i < (vertical ? rect.height / stepSize : rect.width / stepSize); i += 1) {
                    this.fillRect(
                        vertical ? rect.x : rect.x + i * stepSize,
                        vertical ? rect.y + i * stepSize : rect.y,
                        vertical ? rect.width : this.indicatorsSize,
                        vertical ? this.indicatorsSize : rect.height,
                    );
                }
            }
        }

        // Ensure text is always drawn on top
        if (this.text) {
            this.text.setVisible(true);
            this.text.setDepth(1);
        }
    }

    private _updateProgressText(): void {
        if (this.text) {
            this.text.setText(this.textFormat(this.progress));
            this.text.setVisible(this.textShow);
        }
    }

    private _getForeGroundRectangleFromOrientation(): Rectangle {
        if (this.orientation === "vertical") {
            return {
                x: 0 + this.padding,
                y: 0 + this.padding + (this.reverse ? (1 - this.progress) * (this.height - 2 * this.padding) : 0),
                width: this.width - 2 * this.padding,
                height: (this.height - 2 * this.padding) * this.progress,
            };
        }

        return {
            x: 0 + this.padding + (this.reverse ? (1 - this.progress) * (this.width - 2 * this.padding) : 0),
            y: 0 + this.padding,
            width: (this.width - 2 * this.padding) * this.progress,
            height: this.height - 2 * this.padding,
        };
    }

    private _getForegroundRadiiFromOrientation(): ForegroundRadii {
        if (this.orientation === "vertical") {
            return {
                tl: this.reverse ? (this.progress < 1 ? 0 : this.radius) : this.radius,
                tr: this.reverse ? (this.progress < 1 ? 0 : this.radius) : this.radius,
                bl: this.reverse ? this.radius : this.progress < 1 ? 0 : this.radius,
                br: this.reverse ? this.radius : this.progress < 1 ? 0 : this.radius,
            };
        }

        return {
            tl: this.reverse ? (this.progress < 1 ? 0 : this.radius) : this.radius,
            bl: this.reverse ? (this.progress < 1 ? 0 : this.radius) : this.radius,
            br: this.reverse ? this.radius : this.progress < 1 ? 0 : this.radius,
            tr: this.reverse ? this.radius : this.progress < 1 ? 0 : this.radius,
        };
    }

    private _getShadeColor(): number {
        const color = Phaser.Display.Color.ValueToColor(this.color);
        color.darken(10);
        return Phaser.Display.Color.GetColor(color.red, color.green, color.blue);
    }

    private _getTintColor(): number {
        const color = Phaser.Display.Color.ValueToColor(this.color);
        color.lighten(25);
        return Phaser.Display.Color.GetColor(color.red, color.green, color.blue);
    }

    private static _limitProgress(progress: number): number {
        return Math.max(Math.min(progress, 1), 0);
    }
}
