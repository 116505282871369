import { chapter1 } from "./1";

export interface NextChapterConfig {
    name: string;
    boss: {
        name: string;
        texture: string[];
    };
    creep: {
        name: string;
        texture: string[];
    };
}

export interface CurrentChapterConfig {
    name: string;
    boss: {
        name: string;
        texture: string[];
        avatar: string[];
    };
}

export interface BossConfig {
    idleAnimationKey: string;
    walkAnimationKey: string;
    attackAnimationKey: string;
    gethitAnimationKey: string;
    destroyedAnimationKey: string;
    size: {
        scale: number;
    };
    texture: string[];
}

export interface CreepConfig {
    walkAnimationKey: string;
    attackAnimationKey: string;
    destroyedAnimationKey: string;
    size: {
        scale: number;
    };
    texture: string[];
}

export interface ChapterConfig {
    id: number;
    boss: BossConfig;
    creep: CreepConfig;
    background: {
        mining: string;
        boss: string;
    };
    nextChapter?: NextChapterConfig;
    currentChapter: CurrentChapterConfig;
}

export const chapters: Record<number, ChapterConfig> = {
    [chapter1.id]: chapter1,
};
