// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { getStoragePercent } from "@/ota-mining/utils/get-storage-percentage";
import ProgressBar from "../ProgressBar";
import { CUSTOM_EVENTS, EventBusComponent } from "../../components/events/EventBusComponent";
import ClaimPopup from "../ClaimPopup";
import PushComponent from "../../components/animations/PushComponent";
import { EventBus } from "@/lib/event-bus";
/* END-USER-IMPORTS */

export default class BottomConsole extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // bottom_console_png
        const bottom_console_png = scene.add.image(0, 354, "mining", "nav bottom.png");
        this.add(bottom_console_png);

        // image_1
        const image_1 = scene.add.image(0, 269, "mining", "wrapper.png");
        this.add(image_1);

        // image_4
        const image_4 = scene.add.image(-83, 271, "mining", "Frame 427318380@2x.png");
        this.add(image_4);

        // image_3
        const image_3 = scene.add.image(-163, 275, "mining", "Frame 427318381@2x.png");
        this.add(image_3);

        // progressBar
        const progressBar = scene.add.image(0, 297, "mining", "bar@2x.png");
        this.add(progressBar);

        // loadingStripe
        const loadingStripe = scene.add.sprite(23, 297, "loading_stripe", "frame=F_01.png");
        loadingStripe.visible = false;
        loadingStripe.play("loading_stripe");
        this.add(loadingStripe);

        // circleSpin
        const circleSpin = scene.add.sprite(148, 293, "circle_spin", "prop=F_01.png");
        this.add(circleSpin);

        // image_5
        const image_5 = scene.add.image(-166, 236, "mining", "Coin4 1.png");
        this.add(image_5);

        // image_6
        const image_6 = scene.add.image(-11, 240, "mining", "artifacts_05@2x.png");
        this.add(image_6);

        // upgradeButton
        const upgradeButton = scene.add.image(0, 382, "mining", "Button Upgrade 2@2x.png");
        this.add(upgradeButton);

        // text
        const text = new Text(scene, -99.41622161865234, 224.4605712890625);
        text.text = "Stacking Coin";
        text.setStyle({ "fontSize": "14px" });
        this.add(text);

        // text_1
        const text_1 = new Text(scene, 48, 225);
        text_1.text = "Stacking RMX";
        text_1.setStyle({ "fontSize": "14px" });
        this.add(text_1);

        // otaGoldAmount
        const otaGoldAmount = new Text(scene, -142.41621780395508, 244.4605712890625);
        otaGoldAmount.setOrigin(0, 0.5);
        otaGoldAmount.text = "0";
        otaGoldAmount.setStyle({ "fontSize": "16px" });
        this.add(otaGoldAmount);

        // rmxGoldAmount
        const rmxGoldAmount = new Text(scene, 6, 237);
        rmxGoldAmount.setOrigin(0, 0);
        rmxGoldAmount.text = "0";
        rmxGoldAmount.setStyle({ "fontSize": "16px" });
        this.add(rmxGoldAmount);

        // text_4
        const text_4 = new Text(scene, 152, 223);
        text_4.text = "Storage";
        text_4.setStyle({ "fontSize": "14px" });
        this.add(text_4);

        // storagePercent
        const storagePercent = new Text(scene, 151, 244);
        storagePercent.text = "0%";
        storagePercent.setStyle({ "fontSize": "16px" });
        this.add(storagePercent);

        // text_6
        const text_6 = new Text(scene, -129.41622161865234, 358.4605712890625);
        text_6.text = "Mining Rate";
        text_6.setStyle({ "fontSize": "14px" });
        this.add(text_6);

        // rmxRate
        const rmxRate = new Text(scene, -130.41622161865234, 377.4605712890625);
        rmxRate.text = "10 RMX /1 Min";
        rmxRate.setStyle({ "fontSize": "14px" });
        this.add(rmxRate);

        // goldRate
        const goldRate = new Text(scene, -129.41622161865234, 396.4605712890625);
        goldRate.text = "100 Coin /1 Min";
        goldRate.setStyle({ "fontSize": "14px" });
        this.add(goldRate);

        // countDown
        const countDown = new Text(scene, 0, 296);
        countDown.visible = false;
        countDown.text = "Fulfilled in 00:59";
        countDown.setStyle({ "color": "#fff", "fontSize": "10px" });
        this.add(countDown);

        // storage
        const storage = scene.add.image(148, 291, "mining", "bonus_icon_01.png");
        this.add(storage);

        // redDot
        const redDot = scene.add.ellipse(163, 275, 12, 12);
        redDot.visible = false;
        redDot.isFilled = true;
        redDot.fillColor = 15869735;
        this.add(redDot);

        this.progressBar = progressBar;
        this.loadingStripe = loadingStripe;
        this.circleSpin = circleSpin;
        this.upgradeButton = upgradeButton;
        this.otaGoldAmount = otaGoldAmount;
        this.rmxGoldAmount = rmxGoldAmount;
        this.storagePercent = storagePercent;
        this.rmxRate = rmxRate;
        this.goldRate = goldRate;
        this.countDown = countDown;
        this.storage = storage;
        this.redDot = redDot;

        /* START-USER-CTR-CODE */
        // Create progress bar graphics
        const easyProgressBar = new ProgressBar(
            scene,
            this.progressBar,
            0,
            0,
            // "#C851E5"
        );
        this.easyProgressBar = easyProgressBar;
        this.addAt(easyProgressBar, 6);
        /* END-USER-CTR-CODE */
    }

    private progressBar: Phaser.GameObjects.Image;
    private loadingStripe: Phaser.GameObjects.Sprite;
    private circleSpin: Phaser.GameObjects.Sprite;
    private upgradeButton: Phaser.GameObjects.Image;
    private otaGoldAmount: Text;
    private rmxGoldAmount: Text;
    private storagePercent: Text;
    private rmxRate: Text;
    private goldRate: Text;
    private countDown: Text;
    private storage: Phaser.GameObjects.Image;
    private redDot: Phaser.GameObjects.Ellipse;

    /* START-USER-CODE */
    private easyProgressBar: ProgressBar;
    public eventBusComponent: EventBusComponent;
    public storagePushComp: PushComponent;
    public totalCoin: number = 0;
    public totalRmx: number = 0;
    public timerEvent?: Phaser.Time.TimerEvent;

    public init(eventBusComponent: EventBusComponent) {
        this.eventBusComponent = eventBusComponent;

        const user = this.scene.userManager.getData();
        if (user?.miningRate) {
            this.rmxRate.setText(`${user.miningRate.rmx} RMX /1 Min`);
            this.goldRate.setText(`${user.miningRate.otaGold} Coin /1 Min`);
        }

        new PushComponent(this.upgradeButton, () => {
            this.scene.scene.start("SlotsBoot");
        }).active();

        const storagePushComp = new PushComponent(this.storage, this.handleClaim, this);
        this.storagePushComp = storagePushComp;

        this.updateBottomConsole();
    }

    private updateBottomConsole() {
        const now = Date.now();
        const user = this.scene.userManager.getData();
        if (!user) return;

        this.loadingStripe.visible = false;
        this.loadingStripe.disableInteractive();

        const { storagePercent, safeDiffMinutes, safeDiffSeconds } = getStoragePercent(user.lastClaimedAt, now);

        console.log({
            storagePercent,
            safeDiffMinutes,
            safeDiffSeconds,
        });

        if (safeDiffMinutes >= 1) {
            this.redDot.visible = true;
            this.circleSpin.play("circle_spin");
            this.storagePushComp.active();
        } else {
            this.redDot.visible = false;
            this.circleSpin.stop();
            this.circleSpin.setFrame(0);
            this.storagePushComp.deactivate();
        }

        this.scene.tweens.addCounter({
            from: 0,
            to: storagePercent,
            duration: 400,
            onUpdate: (tween) => {
                this.storagePercent.text = `${tween.getValue().toFixed(2)}%`;
            },
        });

        const currentOtaGold = Number(this.otaGoldAmount.text);
        const currentRmx = Number(this.rmxGoldAmount.text);

        const stackingOtaGold = (user.miningRate?.otaGold || 0) * safeDiffMinutes;
        const stackingRmx = (user.miningRate?.rmx || 0) * safeDiffMinutes;

        this.scene.tweens.addCounter({
            from: currentOtaGold,
            to: stackingOtaGold,
            duration: 400,
            onUpdate: (tween) => {
                this.otaGoldAmount.text = tween.getValue().toFixed(3);
            },
        });

        this.scene.tweens.addCounter({
            from: currentRmx,
            to: stackingRmx,
            duration: 400,
            onUpdate: (tween) => {
                this.rmxGoldAmount.text = tween.getValue().toFixed(3);
            },
        });

        this.startCountdown(safeDiffSeconds);
    }

    private startCountdown(currentSeconds: number) {
        const totalSeconds = 60;
        const remainingSeconds = totalSeconds - currentSeconds;
        const endTime = Date.now() + remainingSeconds * 1000;

        const updateProgress = () => {
            const now = Date.now();
            const remainingTime = endTime - now;
            const progress = (totalSeconds - remainingTime / 1000) / totalSeconds;

            if (remainingTime <= 0) {
                this.countDown.text = "Sending to storage";
                this.easyProgressBar.setProgress(1);

                this.loadingStripe.visible = true;
                this.loadingStripe.setDepth(3);

                // Cancel the timer when countdown is done
                if (this.timerEvent) {
                    this.timerEvent.destroy();
                    this.timerEvent = undefined;
                }

                this.scene.time.delayedCall(2000, () => {
                    this.updateBottomConsole();
                });
            } else {
                const minutes = Math.floor(remainingTime / 60000);
                const seconds = Math.floor((remainingTime % 60000) / 1000);
                this.countDown.text = `Fulfilled in ${minutes}:${seconds.toString().padStart(2, "0")}`;
                this.countDown.visible = true;

                this.easyProgressBar.setProgress(progress);

                if (progress >= 0.3) {
                    this.countDown.setStyle({
                        color: "#ffffff",
                    });
                }
            }
        };

        // Initial update
        updateProgress();

        // Update every second
        this.timerEvent = this.scene.time.addEvent({
            delay: 1000,
            callback: updateProgress,
            loop: true,
            repeat: Math.floor(remainingSeconds),
        });
    }

    async handleClaim() {
        const eventBusComponent = this.eventBusComponent;
        EventBus.emit(CUSTOM_EVENTS.CLAIM_REWARD);
        const popupContainer = new ClaimPopup(this.scene, 0, 0);
        popupContainer.setAlpha(0);
        popupContainer.setDepth(4);
        this.scene.add.existing(popupContainer);
        this.scene.tweens.add({
            targets: popupContainer,
            alpha: { from: 0, to: 1 },
            duration: 150,
            onComplete: () => {
                popupContainer.claimReward();
            },
        });

        popupContainer.once("destroy", () => {
            if (this.timerEvent) {
                this.timerEvent.destroy();
                this.timerEvent = undefined;
            }
            this.updateBottomConsole();
            eventBusComponent.emit(CUSTOM_EVENTS.CLAIM_REWARD_SUCCESS);
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
