// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import PreloadBarUpdaterScript from "../script-nodes/PreloadBarUpdaterScript";
import { EventBus, EVENTS } from "@/lib/event-bus";
/* END-USER-IMPORTS */

export default class Preloader extends Phaser.Scene {
    constructor() {
        const sceneConfig = {
            key: "MiningPreloader",
            pack: {
                files: [
                    {
                        type: "plugin",
                        key: "rexawaitloaderplugin",
                        url: "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexawaitloaderplugin.min.js",
                        start: true,
                    },
                ],
            },
        };
        super(sceneConfig);

        /* START-USER-CTR-CODE */

        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // image_1
        const image_1 = this.add.image(195, 422, "mining_bg");
        image_1.visible = false;

        this.events.emit("scene-awake");
    }

    /* START-USER-CODE */

    // Write your code here
    init() {
        this.editorCreate();

        // // progressBar
        const progressBar = this.add.rectangle(this.cameras.main.centerX, this.cameras.main.centerY, 256, 20);
        progressBar.setOrigin(0.5, 0.5);
        progressBar.isFilled = true;
        progressBar.fillColor = 14737632;

        // preloadUpdater
        new PreloadBarUpdaterScript(progressBar);

        // progressBarBg
        const progressBarBg = this.add.rectangle(this.cameras.main.centerX, this.cameras.main.centerY, 256, 20);
        progressBarBg.setOrigin(0.5, 0.5);
        progressBarBg.fillColor = 14737632;
        progressBarBg.isStroked = true;

        // loadingText
        const loadingText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY - 32, "", {});
        loadingText.text = "Loading...";
        loadingText.setStyle({
            color: "#e0e0e0",
            fontFamily: "arial",
            fontSize: "20px",
        });
        loadingText.setOrigin(0.5, 0.5);

        this.events.emit("scene-awake");
    }
    preload() {
        (this.plugins as any).get("rexawaitloaderplugin").addToScene(this);

        // Check if loot-box assets are already loaded
        if (!this.textures.exists("loot-box-first-texture")) {
            this.load.pack("loot-box-asset-pack", "shared/loot-box-asset-pack.json");
        }

        // Load mining-specific assets
        this.load.pack("mining-preload-asset-pack", "ota-mining/mining-preload-asset-pack.json");

        // Load chapter assets after auth
        this.loadChapterAssets();
    }

    private loadChapterAssets() {
        (this.load as any).rexAwait(async (successCallback: () => void) => {
            EventBus.on(EVENTS.AUTH_INIT_DATA, this.userManager.authenticate);
            EventBus.emit(EVENTS.READY_FOR_AUTH_DATA);

            this.userManager.onAuthSuccess(async () => {
                await this.miningBossManager.syncWithBackend();
                const data = { chapter: 1, round: 1, totalRound: 6 };
                await this.chapterManager.syncWithBackend(data.chapter);
                await this.settingsManager.syncWithBackend();

                const chapterKey = data.chapter < 10 ? `0${data.chapter}` : data.chapter;

                // Load chapter assets
                this.load
                    .pack(
                        `chapter_${chapterKey}_asset_pack`,
                        `ota-mining/asset-pack/chapter_${chapterKey}_asset_pack.json`,
                    )
                    .once(Phaser.Loader.Events.FILE_COMPLETE, () => {
                        console.log(`Chapter ${chapterKey} assets loaded`);
                        successCallback();
                    });

                this.load.start();
            });
        });
    }

    create() {
        this.setupBackground();
        //  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
        //  For example, you can define global animations here, so we can use them in other scenes.

        //  Move to the MainMenu. You could also swap this for a Scene Transition, such as a camera fade.
        this.scene.start("MiningGame");
    }

    shutdown() {
        // Clean up any resources
        EventBus.off(EVENTS.AUTH_INIT_DATA);
    }

    setupBackground() {
        this.cameras.main.setBackgroundColor(0x000000);
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
