// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class ProgressBar extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, progressBarBg: Phaser.GameObjects.Image, x?: number, y?: number, color?: string) {
        super(scene, x ?? 0, y ?? 0);

        // progressBar background
        this.progressBarBg = progressBarBg;
        this.color = color ?? "#fc478b";

        /* START-USER-CTR-CODE */
        // Create progress bar graphics
        const borderWidth = 5;
        this.progressGraphics = scene.add.graphics();
        this.add(this.progressGraphics);

        // Set initial progress bar position and dimensions
        this.progressBarX = this.progressBarBg.x - this.progressBarBg.width / 2 + borderWidth;
        this.progressBarY = this.progressBarBg.y;
        this.progressBarWidth = this.progressBarBg.width - borderWidth * 2;
        this.progressBarHeight = this.progressBarBg.height - borderWidth * 2;

        // Draw initial progress bar
        this.progress = 0;
        this.drawProgressBar();
        /* END-USER-CTR-CODE */
    }

    private progressBarBg: Phaser.GameObjects.Image;

    /* START-USER-CODE */
    private progressGraphics: Phaser.GameObjects.Graphics;
    private progressBarX: number;
    private progressBarY: number;
    private progressBarWidth: number;
    private progressBarHeight: number;
    private color: string = "#fc478b";
    private progress: number = 0;

    public setProgress(progress: number, animate: boolean = true) {
        const drawProgressBar = this.drawProgressBar.bind(this);
        const limitProgress = Math.max(Math.min(progress, 1), 0);
        if (!animate) {
            this.progress = limitProgress;
            drawProgressBar();
        } else {
            const tween = this.scene.tweens.add({
                targets: this,
                progress: {
                    from: this.progress,
                    to: limitProgress,
                },
                onUpdate: function (this: ProgressBar) {
                    drawProgressBar();
                },
                onUpdateScope: this,
                onComplete: function () {
                    tween.remove();
                },
                ease: "Cubic.Out",
                duration: 300,
                repeat: 0,
                yoyo: false,
            });
        }
    }

    private drawProgressBar() {
        const progress = this.progress;
        this.progressGraphics.clear();
        this.progressGraphics.fillStyle(Phaser.Display.Color.HexStringToColor(this.color).color);

        // Draw rounded rectangle
        const radius = this.progressBarHeight / 2;
        const width = this.progressBarWidth * progress;
        const x = this.progressBarX;
        const y = this.progressBarY - this.progressBarHeight / 2;
        const height = this.progressBarHeight;

        // Left cap
        this.progressGraphics.beginPath();
        if (width > radius) {
            // Draw full left cap when width is greater than radius
            this.progressGraphics.arc(x + radius, y + radius, radius, Math.PI, Math.PI * 0.5);
            this.progressGraphics.arc(x + radius, y + height - radius, radius, Math.PI * 0.5, Math.PI);
        } else {
            // Draw circle with width as progress
            const circleProgress = Math.min(width / radius, 1);
            this.progressGraphics.arc(x + radius, y + radius, radius, Math.PI, Math.PI + Math.PI * circleProgress);

            // Mirror the arc for bottom half
            this.progressGraphics.arc(
                x + radius,
                y + height - radius,
                radius,
                Math.PI - Math.PI * circleProgress,
                Math.PI,
            );
        }
        this.progressGraphics.closePath();
        this.progressGraphics.fill();

        // Middle rectangle
        if (width > radius) {
            // Draw middle rectangle
            this.progressGraphics.fillRect(x + radius, y, width - radius, height);
        }

        // Right cap
        if (width > radius) {
            // Fade in animation for the right cap when width exceeds radius
            this.progressGraphics.beginPath();
            this.progressGraphics.moveTo(x + width, y);
            this.progressGraphics.arc(x + width, y + radius, radius, Math.PI * 1.5, 0);
            this.progressGraphics.arc(x + width, y + height - radius, radius, 0, Math.PI * 0.5);
            this.progressGraphics.lineTo(x + width, y + height);
            this.progressGraphics.closePath();
            this.progressGraphics.fill();
        }
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
