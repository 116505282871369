import { DataManager } from "./data-manager";

export class SettingsManager extends DataManager<{
    sound: boolean;
}> {
    public key = "settings";

    async syncWithBackend() {
        this.setData({
            sound: true,
        });
        return this.getData();
    }
}
