// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../Text";
/* START-USER-IMPORTS */
import { numberWithCommas } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class TotalWin extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 185, y ?? 8);

        // totalWin
        const totalWin = new Text(scene, 0, 0);
        totalWin.text = "Win";
        this.add(totalWin);

        // totalWinAmountText
        const totalWinAmountText = new Text(scene, 0, 18);
        totalWinAmountText.text = "0";
        totalWinAmountText.setStyle({ "fontSize": "18px" });
        this.add(totalWinAmountText);

        this.totalWinAmountText = totalWinAmountText;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private totalWinAmountText: Text;

    /* START-USER-CODE */
    private amountTween: Phaser.Tweens.Tween | null = null;
    public currentTotalWin: number = 0;

    public setAmount(delta: number) {
        this.currentTotalWin = delta;
        this.setVisible(true);

        if (this.amountTween) {
            this.amountTween.remove();
            this.amountTween = null;
        }

        this.amountTween = this.scene.tweens.addCounter({
            from: 0,
            to: delta,
            duration: 400,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = Math.round(tween.getValue());
                this.totalWinAmountText.setText(`${numberWithCommas(value)}`);
            },
            onComplete: () => {
                this.amountTween = null;
            },
        });
    }

    hide() {
        this.totalWinAmountText.setText("+0");
        this.currentTotalWin = 0;
        this.setVisible(false);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
