const PREFIX = "tba.amoti";

const key = (name: string) => `${PREFIX}:${name}`;

export const getAccessToken = () => localStorage.getItem(key("token"));

export const getRefreshToken = () => localStorage.getItem(key("refresh"));

export const setAccessToken = (newAccessToken: string) => localStorage.setItem(key("token"), newAccessToken);
export const setRefreshToken = (newRefreshToken: string) => localStorage.setItem(key("refresh"), newRefreshToken);

export const clearTokens = () => {
    localStorage.removeItem(key("token"));
    localStorage.removeItem(key("refresh"));
};

export const setTokens = (newAccessToken: string, newRefreshToken?: string) => {
    setAccessToken(newAccessToken);
    if (newRefreshToken) {
        setRefreshToken(newRefreshToken);
    }
};
