// You can write more code here

/* START OF COMPILED CODE */

import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { numberWithCommas, parseFormattedNumber } from "@/ota-slots/utils/formatter";
/* END-USER-IMPORTS */

export default class SlotsBalance extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 138, y ?? 9);

        // slotsBalance
        const slotsBalance = new Text(scene, 63, -1);
        slotsBalance.text = "Balance";
        slotsBalance.setStyle({ "fontSize": "16px" });
        this.add(slotsBalance);

        // slotsBalanceText
        const slotsBalanceText = new Text(scene, 61, 18);
        slotsBalanceText.text = "0";
        slotsBalanceText.setStyle({ "fontSize": "18px" });
        this.add(slotsBalanceText);

        this.slotsBalanceText = slotsBalanceText;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private slotsBalanceText: Text;

    /* START-USER-CODE */
    private balanceTween: Phaser.Tweens.Tween | null = null;

    public getCurrentBalance(): number {
        return parseFormattedNumber(this.slotsBalanceText.text);
    }

    public updateUserBalance = () => {
        const user = this.scene.userManager.getData();
        if (user) {
            user.otaGold = this.getCurrentBalance();
            this.scene.registry.set("user", user);
        }
    };

    public async updateBalance(delta: number) {
        return new Promise<void>((resolve) => {
            const currentBalance = this.getCurrentBalance();

            const targetBalance = currentBalance + delta;

            if (this.balanceTween) {
                this.balanceTween.remove();
                this.balanceTween = null;
            }

            const difference = Math.abs(targetBalance - currentBalance);
            const maxDuration = 400;
            const calculatedDuration = difference * 10;
            const duration = Math.min(calculatedDuration, maxDuration);

            this.balanceTween = this.scene.tweens.addCounter({
                from: currentBalance,
                to: targetBalance,
                duration,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    this.slotsBalanceText.setText(numberWithCommas(value));
                },
                onComplete: () => {
                    this.balanceTween = null;
                    this.updateUserBalance();
                    resolve();
                },
            });
        });
    }

    public async setBalance(newBalance: number) {
        return new Promise<void>((resolve) => {
            const currentBalance = this.getCurrentBalance();

            if (this.balanceTween) {
                this.balanceTween.remove();
                this.balanceTween = null;
            }

            const difference = Math.abs(newBalance - currentBalance);
            const maxDuration = 400;
            const calculatedDuration = difference * 10;
            const duration = Math.min(calculatedDuration, maxDuration);

            this.balanceTween = this.scene.tweens.addCounter({
                from: currentBalance,
                to: newBalance,
                duration,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    this.slotsBalanceText.setText(numberWithCommas(value));
                },
                onComplete: () => {
                    this.balanceTween = null;
                    this.updateUserBalance();
                    resolve();
                },
            });
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
