// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../ota-slots/prefabs/Text";
/* START-USER-IMPORTS */
import BossStackItem from "./BossStackItem";
/* END-USER-IMPORTS */

export default class BossStack extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 119, y ?? 101);

        // frame_427318375_png
        const frame_427318375_png = scene.add.image(76, 10, "boss_stack", "Frame 427318375.png");
        this.add(frame_427318375_png);

        // text_6
        const text_6 = new Text(scene, 1, 24);
        text_6.text = "0 / 6";
        text_6.setStyle({ fontSize: "12px" });
        this.add(text_6);

        // stackContainer
        const stackContainer = scene.add.container(-23, 12);
        this.add(stackContainer);

        this.stackContainer = stackContainer;

        /* START-USER-CTR-CODE */
        this.setupStack();
        /* END-USER-CTR-CODE */
    }

    private stackContainer: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    setupStack() {
        const totalStack = 6;
        const listItems = [];
        for (let i = 0; i < totalStack; i++) {
            const item = new BossStackItem(this.scene, i * 30 + 30, 0);
            item.init(i <= 2);
            listItems.push(item);
        }
        listItems.push(this.scene.add.image(totalStack * 30 + 10, -12, "boss_stack", "Frame 427318367.png"));

        this.stackContainer.add(listItems);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
