// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../ota-slots/prefabs/Text";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class ChargingCountdown extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 100);

        // countDownText
        const countDownText = new Text(scene, 0, 0);
        countDownText.text = "";
        countDownText.setStyle({ "fontSize": "12px" });
        this.add(countDownText);

        this.countDownText = countDownText;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private countDownText: Text;

    /* START-USER-CODE */
    public timer: Phaser.Time.TimerEvent;
    public cb: () => void;

    get text() {
        return this.countDownText;
    }

    updateBossFightStatus(cb: () => void) {
        this.cb = cb;
        const miningBoss = this.scene.miningBossManager.getData();
        const lastFightBossAt = miningBoss?.lastFightBossAt;
        if (lastFightBossAt) {
            const now = Date.now();
            const timeDiff = now - +new Date(lastFightBossAt);
            const cooldownTime = 90 * 1000; // 1m30s in milliseconds
            if (timeDiff < cooldownTime) {
                this.startCountdown(cooldownTime - timeDiff);
            } else {
                this.showFightButton();
            }
        } else {
            this.showFightButton();
        }
    }

    startCountdown(remainingTime: number) {
        this.updateCountdownDisplay(remainingTime);

        this.timer = this.scene.time.addEvent({
            delay: 1000,
            callback: this.updateCountdown,
            callbackScope: this,
            loop: true,
        });
    }

    updateCountdown() {
        const miningBoss = this.scene.miningBossManager.getData();
        const lastFightBossAt = miningBoss?.lastFightBossAt;
        if (lastFightBossAt) {
            const now = Date.now();
            const timeDiff = now - +new Date(lastFightBossAt);
            const cooldownTime = 90 * 1000; // 1m30s in milliseconds

            if (timeDiff < cooldownTime) {
                this.updateCountdownDisplay(cooldownTime - timeDiff);
            } else {
                this.showFightButton();
                this.timer.remove();
            }
        } else {
            this.showFightButton();
            this.timer.remove();
        }
    }

    updateCountdownDisplay(remainingTime: number) {
        const seconds = Math.ceil(remainingTime / 1000);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        this.countDownText.text = `Ready in ${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    }

    showFightButton() {
        this.countDownText.text = "Ready to fight!";
        this.cb();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
