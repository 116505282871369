import { GemHexColor, InventoryItem } from "@/shared/types/loot-box";
import {
    getAverageAttackPower,
    getItemDisplayName,
    getItemTextureName,
    getRarityColor,
    getSecondaryRarityGradientColor,
} from "@/shared/utils/loot-box";

/* START OF COMPILED CODE */

import Text from "../Text";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class DowngradeItem extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? -35.696540807871855);

        // currentItemTexture
        const currentItemTexture = scene.add.image(-69, 324, "rifle");
        currentItemTexture.scaleX = 0.24673142271303375;
        currentItemTexture.scaleY = 0.24673142271303375;
        this.add(currentItemTexture);

        // currentStats
        const currentStats = scene.add.container(85, 285.00000762939453);
        this.add(currentStats);

        // current
        const current = new Text(scene, 2.75, 0);
        current.text = "Current";
        current.setStyle({ "fontSize": "14px" });
        currentStats.add(current);

        // currentItemName
        const currentItemName = new Text(scene, 2.75, 25);
        currentItemName.text = "Bazooka";
        currentItemName.setStyle({ "color": "#219B76", "fontSize": "26px" });
        currentStats.add(currentItemName);

        // currentItemLevel
        const currentItemLevel = new Text(scene, 2.75, 55);
        currentItemLevel.text = "Level 2";
        currentItemLevel.setStyle({ "fontSize": "14px" });
        currentStats.add(currentItemLevel);

        // currentItemRarity
        const currentItemRarity = new Text(scene, 2.75, 76);
        currentItemRarity.text = "Rarity N";
        currentItemRarity.setStyle({ "color": "#219B76", "fontSize": "14px" });
        currentStats.add(currentItemRarity);

        // newStats
        const newStats = scene.add.container(-18, 354);
        newStats.alpha = 0;
        this.add(newStats);

        // newItemTexture
        const newItemTexture = scene.add.image(23, 0, "rifle");
        newItemTexture.scaleX = 0.3988107989542528;
        newItemTexture.scaleY = 0.3988107989542528;
        newStats.add(newItemTexture);

        // newItem
        const newItem = new Text(scene, 25, 61);
        newItem.text = "New Item";
        newItem.setStyle({});
        newStats.add(newItem);

        // newItemName
        const newItemName = new Text(scene, 25, 100);
        newItemName.text = "Rifle";
        newItemName.setStyle({ "color": "#C851E5", "fontSize": "44px" });
        newStats.add(newItemName);

        // gradientFx
        const gradientFx = newItemName.preFX!.addGradient(13128165, 7286143, 0.2, 0, 0, 1, 0, 0);

        // newItemLevel
        const newItemLevel = new Text(scene, 25, 141);
        newItemLevel.text = "Level: 2";
        newItemLevel.setStyle({ "color": "#C851E5" });
        newStats.add(newItemLevel);

        // newItemRarity
        const newItemRarity = new Text(scene, 25, 169);
        newItemRarity.text = "Rarity: 2";
        newItemRarity.setStyle({ "color": "#C851E5" });
        newStats.add(newItemRarity);

        // noticeText
        const noticeText = new Text(scene, 22, 238);
        noticeText.alpha = 0;
        noticeText.text = "Lower-rate";
        noticeText.setStyle({ "color": "#EB287E" });
        newStats.add(noticeText);

        // powerChangeContainer
        const powerChangeContainer = scene.add.container(0, 201);
        newStats.add(powerChangeContainer);

        // powerChange
        const powerChange = new Text(scene, 75, 2);
        powerChange.text = "-50";
        powerChange.setStyle({ "color": "#EB287E" });
        powerChangeContainer.add(powerChange);

        // bullets
        const bullets = scene.add.image(0, 0, "bullets");
        bullets.scaleX = 0.4380157978823598;
        bullets.scaleY = 0.4380157978823598;
        powerChangeContainer.add(bullets);

        // buttons
        const buttons = scene.add.container(-94, 540);
        buttons.alpha = 0;
        this.add(buttons);

        // sell
        const sell = scene.add.image(97, 0, "sell");
        buttons.add(sell);

        this.currentItemTexture = currentItemTexture;
        this.currentItemName = currentItemName;
        this.currentItemLevel = currentItemLevel;
        this.currentItemRarity = currentItemRarity;
        this.currentStats = currentStats;
        this.newItemTexture = newItemTexture;
        this.gradientFx = gradientFx;
        this.newItemName = newItemName;
        this.newItemLevel = newItemLevel;
        this.newItemRarity = newItemRarity;
        this.noticeText = noticeText;
        this.powerChange = powerChange;
        this.newStats = newStats;
        this.sell = sell;
        this.buttons = buttons;

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    private currentItemTexture: Phaser.GameObjects.Image;
    private currentItemName: Text;
    private currentItemLevel: Text;
    private currentItemRarity: Text;
    private currentStats: Phaser.GameObjects.Container;
    private newItemTexture: Phaser.GameObjects.Image;
    private gradientFx: Phaser.FX.Gradient;
    private newItemName: Text;
    private newItemLevel: Text;
    private newItemRarity: Text;
    private noticeText: Text;
    private powerChange: Text;
    private newStats: Phaser.GameObjects.Container;
    private sell: Phaser.GameObjects.Image;
    private buttons: Phaser.GameObjects.Container;

    /* START-USER-CODE */

    setComparisonItems(currentItem: InventoryItem, newItem: InventoryItem) {
        const currentItemDisplayName = getItemDisplayName(currentItem);
        const currentItemTextureName = getItemTextureName(currentItem);
        if (currentItemTextureName) this.currentItemTexture.setTexture(currentItemTextureName);
        if (currentItemDisplayName) this.currentItemName.setText(currentItemDisplayName);
        this.currentItemLevel.setText(`Level: ${currentItem.level}`);
        this.currentItemRarity.setText(`Rarity: ${currentItem.rarity}`);

        const newItemDisplayName = getItemDisplayName(newItem);
        const newItemTextureName = getItemTextureName(newItem);
        if (newItemTextureName) this.newItemTexture.setTexture(newItemTextureName);
        if (newItemDisplayName) this.newItemName.setText(newItemDisplayName);
        this.newItemLevel.setText(`Level: ${newItem.level}`);
        this.newItemRarity.setText(`Rarity: ${newItem.rarity}`);

        if (newItem.name === "gem") {
            const currentItemRarityColor = getRarityColor(currentItem);

            this.currentItemName.setStyle({ color: currentItemRarityColor });
            this.currentItemName.setScale(0.8);
            this.currentItemLevel.setStyle({ color: currentItemRarityColor });
            this.currentItemRarity.setStyle({ color: currentItemRarityColor });

            const newItemRarityColor = getRarityColor(newItem);
            this.newItemName.setStyle({ fontSize: 34, color: newItemRarityColor });
            this.newItemLevel.setStyle({ color: newItemRarityColor });
            this.newItemRarity.setStyle({ color: newItemRarityColor });
            this.newItemTexture.setScale(0.2428407392079012, 0.2428407392079012);
            this.replaceNewItemNameGradientFx(newItemRarityColor);
        }

        const currentAverage = getAverageAttackPower(currentItem);
        const newAverage = getAverageAttackPower(newItem);
        const powerChange = currentAverage - newAverage;

        if (powerChange === 0) {
            this.powerChange
                .setText(`${powerChange}`)
                .setStyle({ color: "#fff" })
                .setX(this.powerChange.x - 12);
            this.noticeText.setText("Unchanged").setStyle({ color: "#fff" });
        } else {
            this.powerChange.setText(`-${powerChange}`);
        }
    }

    private replaceNewItemNameGradientFx(rarityColor: GemHexColor) {
        const secondGradientColor = getSecondaryRarityGradientColor(rarityColor);
        const primaryColor = Phaser.Display.Color.HexStringToColor(rarityColor).color;
        const secondaryColor = Phaser.Display.Color.HexStringToColor(secondGradientColor).color;

        if (this.gradientFx) {
            this.newItemName.preFX!.remove(this.gradientFx);
        }

        this.gradientFx = this.newItemName.preFX!.addGradient(primaryColor, secondaryColor, 0.2, 0, 0, 1, 0, 0);
    }

    setupButtons() {
        this.sell.setInteractive({ useHandCursor: true }).on("pointerdown", () => {
            this.emit("sell");
        });
    }

    startTransitionAnimation = async () => {
        const tween = (config: Phaser.Types.Tweens.TweenBuilderConfig) =>
            new Promise((resolve) => this.scene.tweens.add({ ...config, onComplete: resolve }));

        const delay = (ms: number) => new Promise((resolve) => this.scene.time.delayedCall(ms, resolve));

        // Step 1: Fade out currentStats
        await tween({
            targets: this.currentStats,
            alpha: 0,
            duration: 500,
            ease: "Power2",
        });

        // Step 2: Pan currentItemTexture to the center of the window
        await tween({
            targets: this.currentItemTexture,
            x: 0,
            duration: 300,
            ease: "Power2",
        });

        // Step 3: Scale down, move up slightly, and reduce alpha
        await tween({
            targets: this.currentItemTexture,
            scaleX: this.currentItemTexture.scaleX * 0.6,
            scaleY: this.currentItemTexture.scaleY * 0.6,
            y: this.currentItemTexture.y - 120,
            alpha: 0.6,
            duration: 500,
            ease: "Power2",
        });

        // Step 4: Flick newStats into view
        await tween({
            targets: this.newStats,
            alpha: 1,
            y: this.newStats.y - 60,
            duration: 200,
            ease: "Power2",
        });

        // Step 5: Delay and show buttons and notice text
        await delay(2000);

        await Promise.all([
            tween({
                targets: [this.buttons, this.noticeText],
                alpha: 1,
                duration: 500,
                ease: "Power2",
            }),
            tween({
                targets: this.newStats,
                y: this.newStats.y - 60,
                duration: 500,
                ease: "Power2",
            }),
        ]);

        this.setupButtons();
    };

    fadeOut(duration = 500, onComplete?: () => void) {
        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            duration: duration,
            ease: "Power2",
            onComplete,
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
