// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../ota-slots/prefabs/Text";
/* START-USER-IMPORTS */
import PushComponent from "../../components/animations/PushComponent";
/* END-USER-IMPORTS */

export default class CollectMaterials extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // rectangle_1
        const rectangle_1 = scene.add.rectangle(195, 422, 390, 844);
        rectangle_1.isFilled = true;
        rectangle_1.fillColor = 0;
        rectangle_1.fillAlpha = 0.5;
        this.add(rectangle_1);

        // text
        const text = new Text(scene, 200, 294);
        text.text = "Your storage is full";
        this.add(text);

        // text_1
        const text_1 = new Text(scene, 200, 331);
        text_1.text = "collect to reset the storage";
        this.add(text_1);

        // text_2
        const text_2 = new Text(scene, 200, 390);
        text_2.text = "Then you can continue to";
        this.add(text_2);

        // text_3
        const text_3 = new Text(scene, 200, 429);
        text_3.text = "mine the materials";
        this.add(text_3);

        // collect
        const collect = scene.add.sprite(200, 521, "mining", "collect_materials.png");
        this.add(collect);

        this.collect = collect;

        /* START-USER-CTR-CODE */
        new PushComponent(this.collect, this.collectMaterials, this).active();
        /* END-USER-CTR-CODE */
    }

    private collect: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */

    async collectMaterials() {
        this.destroy();
        return;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
