// You can write more code here

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class Text extends Phaser.GameObjects.Text {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 29, y ?? 8, "", {});

        this.setOrigin(0.5, 0.5);
        this.text = "New text";
        this.setStyle({ "fontFamily": "Oxanium" });

        /* START-USER-CTR-CODE */
        /* END-USER-CTR-CODE */
    }

    /* START-USER-CODE */
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
