export const getStoragePercent = (lastClaimedAt: number, now: number) => {
    const totalMinutes = 1440; // 24 hours
    const totalMs = totalMinutes * 60 * 1000;

    // Calculate total seconds for current and last claimed times
    const currentTotalSeconds = Math.floor(now / 1000);
    const lastClaimedTotalSeconds = Math.floor(lastClaimedAt / 1000);

    // Get the difference in seconds
    let diffSeconds = currentTotalSeconds - lastClaimedTotalSeconds;

    // Convert to minutes and remaining seconds
    const diffMinutes = Math.floor(diffSeconds / 60);
    diffSeconds = diffSeconds % 60;

    // Ensure non-negative values
    const safeDiffMinutes = Math.max(0, diffMinutes);
    const safeDiffSeconds = Math.max(0, diffSeconds);

    const diffInMs = (safeDiffMinutes * 60 + safeDiffSeconds) * 1000;
    const storagePercent = (diffInMs / totalMs) * 100;

    return { storagePercent, safeDiffMinutes, safeDiffSeconds };
};
