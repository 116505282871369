import Phaser from "phaser";

export const CUSTOM_EVENTS = Object.freeze({
    GAME_OVER: "GAME_OVER",
    PLAYER_HIT: "PLAYER_HIT",
    PLAYER_SHOOT: "PLAYER_SHOOT",
    PLAYER_DEAD: "PLAYER_DEAD",
    CREEP_DEAD: "CREEP_DEAD",
    CREEP_INIT: "CREEP_INIT",
    ENEMY_SPAWNED: "ENEMY_SPAWNED",
    BOSS_DEAD: "BOSS_DEAD",
    CLAIM_REWARD_SUCCESS: "CLAIM_REWARD_SUCCESS",
    CLAIM_REWARD: "CLAIM_REWARD",
    RETRY_BOSS_FIGHT: "RETRY_BOSS_FIGHT",
    CLAIM_BOSS_REWARD: "CLAIM_BOSS_REWARD",
    BOSS_FIGHT_START: "BOSS_FIGHT_START",
    BOSS_FIGHT_OVER: "BOSS_FIGHT_OVER",
    BOSS_FIGHT_WIN: "BOSS_FIGHT_WIN",
});

/**
 * Uses the native Phaser 3 EventEmitter class to allow communication
 * between the various components in our game.
 *
 * For example, this event bus can be used for notifying the UI when
 * an enemy is destroyed so we can update the score in our game.
 */
export class EventBusComponent extends Phaser.Events.EventEmitter {
    constructor() {
        super();
    }
}
