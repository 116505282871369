/* START OF COMPILED CODE */

import Text from "../Text";
import LootBoxIcon from "../../../shared/prefabs/loot-box/LootBoxIcon";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class LootBox extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 176, y ?? 70);

        // experienceBar
        const experienceBar = scene.add.sprite(-1, 1, "experience_bar", "experience_bar_anim_00000.png");
        experienceBar.scaleX = 0.5;
        experienceBar.scaleY = 0.5;
        this.add(experienceBar);

        // progress
        const progress = new Text(scene, 22, 1);
        progress.text = "0 %";
        progress.setStyle({ "fontSize": "18px" });
        this.add(progress);

        // lootBoxIcon
        const lootBoxIcon = new LootBoxIcon(scene, 146, -23);
        lootBoxIcon.scaleX = 1.1250000002547207;
        lootBoxIcon.scaleY = 1.1250000002547207;
        this.add(lootBoxIcon);

        this.experienceBar = experienceBar;
        this.progress = progress;
        this.lootBoxIcon = lootBoxIcon;

        /* START-USER-CTR-CODE */
        this.lootBoxIcon.init();
        this.lootBoxIcon.on("sync-user", () => {
            this.emit("sync-user");
        });
        /* END-USER-CTR-CODE */
    }

    private experienceBar: Phaser.GameObjects.Sprite;
    private progress: Text;
    private lootBoxIcon: LootBoxIcon;

    /* START-USER-CODE */

    getProgress() {
        return Number(this.progress.text.replace(" %", ""));
    }

    updateProgress(progress: number) {
        const totalProgress = this.getProgress() + progress;
        this.scene.tweens.addCounter({
            from: this.getProgress(),
            to: totalProgress,
            duration: 300,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = Math.round(tween.getValue());
                this.progress.setText(`${value} %`);
            },
        });

        if (totalProgress >= 100) {
            this.scene.tweens.addCounter({
                from: this.lootBoxIcon.getBoxNumber(),
                to: this.lootBoxIcon.getBoxNumber() + 1,
                duration: 300,
                ease: Phaser.Math.Easing.Linear,
                onUpdate: (tween) => {
                    const value = Math.round(tween.getValue());
                    this.lootBoxIcon.setBoxNumber(value);
                },
                onComplete: () => {
                    this.progress.setText("0 %");
                    this.experienceBar.setTexture("experience_bar", "experience_bar_anim_00000.png");
                },
            });

            return;
        }

        const imageNumber = Math.floor(totalProgress / 2);
        const imageName = `experience_bar_anim_000` + (imageNumber < 10 ? `0${imageNumber}` : imageNumber) + ".png";

        this.experienceBar.setTexture("experience_bar", imageName);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
