// You can write more code here

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */
import { setupBackground } from "../helpers/setup-background";
import { SCENE_KEYS } from "../constants";
/* END-USER-IMPORTS */

export default class Preloader extends Phaser.Scene {
    constructor() {
        super("SlotsPreloader");

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // image_1
        const image_1 = this.add.image(195, 422, "slots_bg");
        image_1.visible = false;

        // loading
        const loading = this.add.sprite(195, 422, "loading", "loading_anim_00000.png");
        loading.scaleX = 0.5;
        loading.scaleY = 0.5;
        loading.play("loading");

        this.loading = loading;

        this.events.emit("scene-awake");
    }

    private loading!: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */

    preload() {
        this.editorCreate();
        this.setupBackground();

        // Check if shared assets are already loaded
        if (!this.textures.exists("loot-box-first-texture")) {
            this.load.pack("loot-box-asset-pack", "shared/loot-box-asset-pack.json");
        }

        if (!this.textures.exists("telegram-star-asset-pack")) {
            this.load.pack("telegram-star-asset-pack", "shared/telegram-star-asset-pack.json");
        }

        // Load slots-specific assets
        this.load.pack("ota-slots-asset-pack", "ota-slots/ota-slots-asset-pack.json");
    }

    setupBackground() {
        setupBackground("./ota-slots/assets/slots_bg.png");
    }

    create() {
        //  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
        //  For example, you can define global animations here, so we can use them in other scenes.
        //  Move to the Game. You could also swap this for a Scene Transition, such as a camera fade.
        this.scene.start(SCENE_KEYS.GAME);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
