import { telegramBackendApi } from "../client";
import { components, operations } from "../generated/schema";

export type Platform = operations["PaymentController_paymentList"]["parameters"]["query"]["platform"];
export type PaymentListResponse = components["schemas"]["PaymentListResponseDto"][];

export const getPaymentList = async (platform: Platform): Promise<PaymentListResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/payment", {
        params: {
            query: {
                platform,
            },
        },
    });

    if (error) {
        alert(`The /payment API returned an error: ${(error as Error).message}. Please try again.`);
        console.error("/payment", error);
        return;
    }

    console.log("[getPaymentList]", data);

    return data as PaymentListResponse;
};

type GooglePlayTransactionRequest = Omit<components["schemas"]["GooglePlayTransactionDto"], "packageName">;
export type PaymentSuccessResponse = components["schemas"]["PaymentSuccessResponseDto"];

export const verifyGooglePlayTransaction = async (
    transaction: GooglePlayTransactionRequest,
): Promise<PaymentSuccessResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/payment/iap/google", {
        body: {
            ...transaction,
            packageName: "com.nft5.imota",
        },
    });

    if (error) {
        alert(`The /payment/iap/google API returned an error: ${(error as Error).message}. Please try again.`);
        console.error("/payment/iap/google", error);
        return;
    }

    console.log("[verifyGooglePlayTransaction]", data);

    return data as PaymentSuccessResponse;
};

type AppleReceiptRequest = components["schemas"]["AppleReceiptDto"];

export const verifyAppleIAPTransaction = async (
    receiptData: AppleReceiptRequest,
): Promise<PaymentSuccessResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/payment/iap/apple", {
        body: receiptData,
    });

    if (error) {
        alert(`The /payment/iap/apple API returned an error: ${(error as Error).message}. Please try again.`);
        console.error("[verifyAppleIAPTransaction]", error);
        return;
    }

    return data as PaymentSuccessResponse;
};

type TelegramStarCreateInvoiceRequest = components["schemas"]["TelegramStarCreateInvoiceRequestDto"];
type TelegramStarCreateInvoiceResponse = components["schemas"]["TelegramStarCreateInvoiceResponseDto"];

export const createTelegramStarInvoice = async (
    invoiceData: TelegramStarCreateInvoiceRequest,
): Promise<TelegramStarCreateInvoiceResponse | undefined> => {
    const { data, error } = await telegramBackendApi.POST("/payment/telegram-star/invoice", {
        body: invoiceData,
    });

    if (error) {
        console.error("[createTelegramStarInvoice]", error);
        return;
    }

    return data as TelegramStarCreateInvoiceResponse;
};

export const getTelegramStarPaymentResult = async (
    transactionId: string,
): Promise<PaymentSuccessResponse | undefined> => {
    const { data, error } = await telegramBackendApi.GET("/payment/telegram-star/result", {
        params: {
            query: { transactionId },
        },
    });

    if (error) {
        console.error("[getTelegramStarPaymentResult]", error);
        return;
    }

    return data as PaymentSuccessResponse;
};
