import { AUTO, Scale, Types } from "phaser";

const SIZE = {
    WIDTH: 390,
    HEIGHT: 844,
    MIN_WIDTH: 270,
    MIN_HEIGHT: 480,
    MAX_WIDTH: 1920,
    MAX_HEIGHT: 1080,
} as const;

export const gameConfig: Types.Core.GameConfig = {
    type: AUTO,
    backgroundColor: "#000000",
    scale: {
        mode: Scale.FIT,
        autoCenter: Scale.CENTER_BOTH,
        width: SIZE.WIDTH,
        height: SIZE.HEIGHT,
        min: {
            width: SIZE.MIN_WIDTH,
            height: SIZE.MIN_HEIGHT,
        },
        max: {
            width: SIZE.MAX_WIDTH,
            height: SIZE.MAX_HEIGHT,
        },
        parent: "game-container",
        autoRound: true,
    },
    physics: {
        default: "arcade",
        arcade: {
            debug: false,
            gravity: { y: 0, x: 0 },
        },
    },
    render: {
        pixelArt: false,
        antialias: true,
        antialiasGL: true,
        roundPixels: true,
        mipmapFilter: "LINEAR_MIPMAP_LINEAR",
    },
};
