import Phaser from "phaser";
import { InputComponent } from "../input/InputComponent";

/**
 * Component that allows for horizontal movement in a Phaser 3 game.
 * This component relies on the built in Phaser 3 Arcade Physics system
 * to move the Phaser 3 game objects around the scene. When input is detected
 * (via the InputComponent), this component will update the physics body
 * velocity.
 */
export class HorizontalMovementComponent {
    private gameObject: Phaser.Physics.Arcade.Sprite | Phaser.GameObjects.Container;
    private inputComponent: InputComponent;
    private velocity: number;

    constructor(
        gameObject: Phaser.Physics.Arcade.Sprite | Phaser.GameObjects.Container,
        inputComponent: InputComponent,
        {
            velocity,
            maxVelocity,
        }: {
            velocity: number;
            maxVelocity?: number;
        },
    ) {
        this.gameObject = gameObject;
        this.inputComponent = inputComponent;
        this.velocity = velocity;

        const body = (this.gameObject as Phaser.Physics.Arcade.Sprite).body as Phaser.Physics.Arcade.Body;
        if (!body) {
            return;
        }

        body.setDamping(true);
        body.setDrag(0.99);
        body.setMaxVelocity(maxVelocity || 300);
    }

    reset(): void {
        const body = (this.gameObject as Phaser.Physics.Arcade.Sprite).body as Phaser.Physics.Arcade.Body;
        if (!body) {
            return;
        }
        body.velocity.x = 0;
        body.setAngularAcceleration(0);
    }

    update(): void {
        const body = (this.gameObject as Phaser.Physics.Arcade.Sprite).body as Phaser.Physics.Arcade.Body;

        if (!body) {
            return;
        }

        if (this.inputComponent.leftIsDown) {
            body.velocity.x -= this.velocity;
        } else if (this.inputComponent.rightIsDown) {
            body.velocity.x += this.velocity;
        } else {
            body.setAngularAcceleration(0);
        }
    }
}
