import { CUSTOM_EVENTS, EventBusComponent } from "../events/EventBusComponent";
import { HealthComponent } from "../health/HealthComponent";

/**
 * A simple component for handling when collisions between two different
 * game objects in our game.
 */
export class ColliderComponent {
    public healthComponent: HealthComponent;
    public eventBusComponent: EventBusComponent;

    constructor(healthComponent: HealthComponent, eventBusComponent: EventBusComponent) {
        this.healthComponent = healthComponent;
        this.eventBusComponent = eventBusComponent;
    }

    collideWithEnemyWeapon(damage: number): void {
        if (this.healthComponent.isDead) {
            return;
        }
        this.healthComponent.hit(damage);
    }
}
