// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import TopConsole from "../prefab/mining/TopConsole";
import BossConsole from "../prefab/mining/BossConsole";
import HealthFighter from "../prefab/boss/HealthFighter";
import RetryConsole from "../prefab/boss/RetryConsole";
import WinningConsole from "../prefab/boss/WinningConsole";
import Text from "../../../shared/prefabs/Text";
/* START-USER-IMPORTS */
import { sleep } from "@/lib/utils";
import { CUSTOM_EVENTS, EventBusComponent } from "../components/events/EventBusComponent";
import Player from "../prefab/Player";
import Boss from "../prefab/Boss";
import NextChapter from "../prefab/boss/NextChapter";
import { FightBossResponse, fightMiningBoss } from "@/lib/api/telegram-backend/methods/mining-boss";
import { EventBus } from "@/lib/event-bus";
/* END-USER-IMPORTS */

export default class BossFighter extends Phaser.Scene {
    constructor() {
        super("MiningBoss");

        /* START-USER-CTR-CODE */
        this.eventBusComponent = new EventBusComponent();
        /* END-USER-CTR-CODE */
    }

    editorCreate(): void {
        // bg
        const bg = this.add.image(195, 422, "chapter_01_bg");
        bg.visible = false;

        // topConsole
        const topConsole = new TopConsole(this, 195, 81);
        this.add.existing(topConsole);

        // bossConsole
        const bossConsole = new BossConsole(this, 195, 425);
        this.add.existing(bossConsole);

        // healthFighter
        const healthFighter = new HealthFighter(this, 195, 193);
        this.add.existing(healthFighter);

        // retryConsole
        const retryConsole = new RetryConsole(this, 195, 686);
        this.add.existing(retryConsole);
        retryConsole.visible = false;

        // winningConsole
        const winningConsole = new WinningConsole(this, 195, 425);
        this.add.existing(winningConsole);
        winningConsole.visible = false;

        // fightStatus
        const fightStatus = new Text(this, 204, 289);
        this.add.existing(fightStatus);
        fightStatus.visible = false;
        fightStatus.text = "Game begins in 3...";
        fightStatus.setStyle({
            color: "#EBF29A",
            fontFamily: "Saiba",
            fontSize: "32px",
            stroke: "#EBF29A",
        });

        this.bg = bg;
        this.topConsole = topConsole;
        this.bossConsole = bossConsole;
        this.healthFighter = healthFighter;
        this.retryConsole = retryConsole;
        this.winningConsole = winningConsole;
        this.fightStatus = fightStatus;

        this.events.emit("scene-awake");
    }

    private bg!: Phaser.GameObjects.Image;
    private topConsole!: TopConsole;
    private bossConsole!: BossConsole;
    private healthFighter!: HealthFighter;
    private retryConsole!: RetryConsole;
    private winningConsole!: WinningConsole;
    private fightStatus!: Text;

    /* START-USER-CODE */

    // Write your code here
    private eventBusComponent: EventBusComponent;
    private player: Player;
    private boss: Boss;
    private bossPlayerCollider: Phaser.Physics.Arcade.Collider;
    private playerBossOverlap: Phaser.Physics.Arcade.Collider;
    private bossFightData: FightBossResponse;

    create() {
        this.editorCreate();
        this.setupBackground();
        this.topConsole.init(this.eventBusComponent, true);

        this.startBossFight();

        // Listen for scene destroy event
        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            this.shutdown();
        });
    }

    shutdown() {
        // Clean up any resources
        this.eventBusComponent.removeAllListeners();
        this.eventBusComponent.destroy();

        this.player.destroy();
        this.boss.destroy();

        if (this.bossPlayerCollider) {
            this.bossPlayerCollider.destroy();
        }
        if (this.playerBossOverlap) {
            this.playerBossOverlap.destroy();
        }
    }

    // Function to generate a list of damage values
    generateDamageList = (totalDamage: number, { minDamage, maxDamage }: { minDamage: number; maxDamage: number }) => {
        if (!totalDamage) {
            return [{ value: 0, crit: false, hide: false }];
        }

        // If total damage is less than minDamage, return single hit
        if (totalDamage < minDamage) {
            return [{ value: totalDamage, crit: false, hide: false }];
        }

        const n = 2; // Number of hits to split damage into
        const damageList = [];
        let remainingDamage = totalDamage;

        // Generate n-1 random damage values
        for (let i = 0; i < n - 1; i++) {
            const maxPossible = Math.min(maxDamage, remainingDamage - minDamage * (n - i - 1));
            const minPossible = Math.max(minDamage, Math.min(minDamage, remainingDamage - maxDamage * (n - i - 1)));
            const damage = Phaser.Math.Between(minPossible, maxPossible);
            const isCrit = damage >= (minDamage + maxDamage) / 2;

            damageList.push({ value: damage, crit: isCrit, hide: false });
            remainingDamage -= damage;
        }

        // Last hit uses remaining damage
        const isCrit = remainingDamage >= (minDamage + maxDamage) / 2;
        damageList.push({ value: remainingDamage, crit: isCrit, hide: false });

        return damageList;
    };

    showCountDownText() {
        this.fightStatus.setVisible(true);
        EventBus.emit(CUSTOM_EVENTS.BOSS_FIGHT_START);

        return new Promise<void>((resolve) => {
            const countdownSeconds = 3;
            let currentSeconds = countdownSeconds;

            const updateText = () => {
                this.fightStatus.setScale(1);
                this.fightStatus.alpha = 1;

                if (currentSeconds >= 0) {
                    if (currentSeconds === 0) {
                        this.fightStatus.text = "GO!";
                    } else {
                        this.fightStatus.text = `Game begins in ${currentSeconds}...`;
                    }
                    currentSeconds--;
                } else {
                    this.fightStatus.text = "Player's Turn";
                    resolve();
                    return;
                }
            };

            // Set initial text
            updateText();

            // Create timer event that fires every second
            this.time.addEvent({
                delay: 1000,
                repeat: countdownSeconds,
                callback: updateText,
            });
        });
    }

    async startBossFight() {
        const player = new Player(this, -150, 480);
        this.add.existing(player);
        this.player = player;

        // Add tween to fly player in from left with optimized animation
        this.tweens.add({
            targets: player,
            x: 75, // Final x position
            duration: 800, // Reduced duration for smoother animation
            onComplete: () => {
                this.player.playPlayerIdleAnimation();
            },
        });

        const boss = new Boss(this, this.scale.width + 50, this.player.y - 25);

        this.add.existing(boss);
        this.boss = boss;

        boss.init(this.eventBusComponent);

        const bossFightData = await fightMiningBoss();
        this.bossFightData = bossFightData as FightBossResponse;
        if (!bossFightData) {
            this.scene.start("MiningGame");
            return;
        }

        await this.showCountDownText();

        player.init(this.eventBusComponent, bossFightData.bossHealth);
        player.startMining();

        boss.spawn(bossFightData.bossHealth);

        this.healthFighter.init(this.player.healthComponent, this.boss.healthComponent);

        this.retryConsole.init(this.eventBusComponent);
        this.eventBusComponent.once(CUSTOM_EVENTS.RETRY_BOSS_FIGHT, this.reset, this);

        this.winningConsole.init(this.eventBusComponent);
        this.eventBusComponent.once(CUSTOM_EVENTS.CLAIM_BOSS_REWARD, this.claim, this);

        const attacks = bossFightData.attacks.map((attack) => Number(attack));

        const userWeapon = this.userManager.getWeapon();
        const minAttack = userWeapon?.minAtk || Math.min(...attacks) / 4;

        let currentAttack = 0;
        let bossAttackPlayer = false;

        let totalDamage = 0;
        let currentDamage = 0;
        let damageList = this.generateDamageList(attacks[0], {
            minDamage: minAttack,
            maxDamage: userWeapon?.maxAtk || 0,
        });

        // boss attack player
        this.bossPlayerCollider = this.physics.add.collider(this.player, this.boss, ((
            playerGameObject: Player,
            bossGameObject: Boss,
        ) => {
            if (!bossGameObject.active || !playerGameObject.active) {
                return;
            }

            bossAttackPlayer = true;
            bossGameObject.weaponComponent.attackPlayer();
        }) as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback);

        // player attack boss
        this.playerBossOverlap = this.physics.add.overlap(this.player.weaponGameObjectGroup, this.boss, ((
            bossGameObject: Boss,
            projectileGameObject: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody,
        ) => {
            if (!bossGameObject.active) {
                if (bossGameObject.healthComponent.isDead) {
                    this.player.reset();
                    EventBus.emit(CUSTOM_EVENTS.BOSS_FIGHT_WIN);
                    this.fightStatus.text = "You Won!";
                    this.fightStatus.setColor("#68DEBB");
                }
                return;
            }

            if (currentAttack === attacks.length) return;

            this.player.weaponComponent.destroyBullet(projectileGameObject, {
                scale: 0.5,
                animationKey: "explosion",
            });

            if (damageList.length > 0 && damageList[currentDamage]) {
                this.boss.colliderComponent.collideWithEnemyWeapon(damageList[currentDamage], bossAttackPlayer);

                totalDamage += damageList[currentDamage].value;
                currentDamage++;
            }

            if (totalDamage >= attacks[currentAttack]) {
                if (currentAttack < attacks.length) {
                    this.bossConsole.playBulletAnimation(currentAttack);
                }
                currentAttack++;
                currentDamage = 0;
                totalDamage = 0;

                damageList = this.generateDamageList(attacks[currentAttack], {
                    minDamage: minAttack,
                    maxDamage: userWeapon?.maxAtk || 0,
                });
            }
            // if user run out of attacks and boss is not dead, run next turn and boss will attack player
            if (currentAttack === attacks.length && !bossFightData.won) {
                this.boss.colliderComponent.collideWithEnemyWeapon({ value: 0, crit: false, hide: true }, true);
                this.player.pauseMining();
                this.time.delayedCall(2000, () => {
                    this.boss.horizontalMovementComponent.resume();
                    this.fightStatus.text = "Boss Turn";
                });
                return;
            }
        }) as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback);

        // player get hit by boss
        this.eventBusComponent.on(CUSTOM_EVENTS.PLAYER_HIT, (bossObject: Boss) => {
            if (this.player.healthComponent.isDead) {
                bossObject.weaponComponent.pauseAttack();
                EventBus.emit(CUSTOM_EVENTS.BOSS_FIGHT_OVER);
                this.fightStatus.text = "Game Over!";
                this.fightStatus.setColor("#d42f3a");
                return;
            }

            this.player.gameObject.play({
                key: this.player.hurtAnimationKey,
            });

            this.player.healthComponent.hit(this.player.healthComponent.startingLife);
        });

        this.eventBusComponent.on(CUSTOM_EVENTS.BOSS_DEAD, () => {
            this.winningConsole.visible = true;
        });

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAYER_DEAD, () => {
            this.retryConsole.visible = true;
        });

        // refresh mining boss data
        this.miningBossManager.syncWithBackend();
    }

    reset() {
        if (true) {
            this.scene.start("MiningGame");
            return;
        }
        // destroy player and boss
        this.shutdown();

        // reset boss console and health fighter
        this.bossConsole.reset();
        this.healthFighter.reset();
        this.retryConsole.visible = false;
        this.winningConsole.visible = false;

        // reset event bus
        this.eventBusComponent = new EventBusComponent();

        this.startBossFight();
    }

    claim() {
        const bossFightData = this.bossFightData;
        if (!bossFightData) {
            return;
        }

        const isLastRound = Boolean(bossFightData.won && bossFightData.newLevel);

        this.winningConsole.visible = false;

        if (isLastRound) {
            const popupContainer = new NextChapter(this, 195, 422);
            popupContainer.setAlpha(0);
            popupContainer.setDepth(4);
            this.add.existing(popupContainer);
            this.tweens.add({
                targets: popupContainer,
                alpha: { from: 0, to: 1 },
                duration: 150,
            });

            popupContainer.once("destroy", () => {
                this.scene.start("MiningGame");
            });
        } else {
            this.boss.runaway();
            this.time.delayedCall(3000, () => {
                this.scene.start("MiningGame");
            });
        }
    }

    setupBackground() {
        const chapterConfig = this.chapterManager.getData();
        if (chapterConfig) {
            this.bg.setTexture(chapterConfig.background.boss);
        }
        this.bg.visible = true;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
