import { getMiningBossFightInfo, type BossFightInfoResponse } from "@/lib/api/telegram-backend/methods/mining-boss";
import { DataManager } from "./data-manager";

export class MiningBossManager extends DataManager<BossFightInfoResponse> {
    public key = "miningBoss";

    async syncWithBackend() {
        const miningBoss = await getMiningBossFightInfo();
        if (miningBoss) {
            this.setData(miningBoss);
        }

        return miningBoss;
    }
}
