import {
    GemColor,
    GemShape,
    ItemRarity,
    WeaponDisplayName,
    WeaponType,
    WeaponId,
    GemHexColor,
} from "../types/loot-box";

export const rarityToWeaponType: Record<ItemRarity, WeaponType> = {
    n: "magnum",
    r: "rifle",
    sr: "sniper-rifle",
    ssr: "bazooka",
} as const;

export const weaponTypeToName: Record<WeaponType, WeaponDisplayName> = {
    rifle: "Rifle",
    "sniper-rifle": "Sniper",
    magnum: "Magnum",
    bazooka: "Bazooka",
} as const;

export const rarityToGemColor: Record<ItemRarity, GemColor> = {
    n: "white",
    r: "green",
    sr: "red",
    ssr: "purple",
} as const;

export const rarityToGemHexColor: Record<ItemRarity, GemHexColor> = {
    n: "#D9D9D9",
    r: "#44D435",
    sr: "#FF1F1FE3",
    ssr: "#9747FF",
};

export const slotNumberToGemShape: Record<number, GemShape> = {
    1: "comet",
    2: "nova",
    3: "nebula",
    4: "quasar",
    5: "astrion",
} as const;

export const weaponTypeToWeaponId: Record<WeaponType, WeaponId> = {
    magnum: "mangun",
    rifle: "rifle",
    "sniper-rifle": "sniper",
    bazooka: "bazooka",
} as const;
