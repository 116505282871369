// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Text from "../../../../ota-slots/prefabs/Text";
/* START-USER-IMPORTS */
import { NextChapterConfig } from "../../chapters";
/* END-USER-IMPORTS */

export default class NextChapter extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 195, y ?? 422);

        // bg
        const bg = scene.add.rectangle(0, 0, 390, 844);
        bg.isFilled = true;
        bg.fillColor = 0;
        bg.fillAlpha = 0.7;
        this.add(bg);

        // nextBoss
        const nextBoss = scene.add.image(0, 22, "chapter_01_info", "next_boss.png");
        this.add(nextBoss);

        // nextCreep
        const nextCreep = scene.add.image(0, -219, "chapter_01_info", "next_creep.png");
        this.add(nextCreep);

        // text
        const text = new Text(scene, 0, -332);
        text.text = "Next chapter";
        text.setStyle({ fontSize: "12px" });
        this.add(text);

        // chapterId
        const chapterId = new Text(scene, 0, -307);
        chapterId.text = "Chapter 2";
        chapterId.setStyle({ fontSize: "12px" });
        this.add(chapterId);

        // nextCreepName
        const nextCreepName = new Text(scene, 0, -136);
        nextCreepName.text = "{creep_name}";
        nextCreepName.setStyle({ color: "#68DEBB", stroke: "#68DEBB" });
        this.add(nextCreepName);

        // nextBossName
        const nextBossName = new Text(scene, 0, 98);
        nextBossName.text = "{boss_name}";
        nextBossName.setStyle({ color: "#68DEBB", stroke: "#68DEBB" });
        this.add(nextBossName);

        // text_4
        const text_4 = new Text(scene, 0, -108);
        text_4.text = "Damage point: {}";
        text_4.setStyle({ fontSize: "12px" });
        this.add(text_4);

        // text_5
        const text_5 = new Text(scene, 0, -85);
        text_5.text = "Damage point: {}";
        text_5.setStyle({ fontSize: "12px" });
        this.add(text_5);

        // text_6
        const text_6 = new Text(scene, 0, 129);
        text_6.text = "Damage point: {}";
        text_6.setStyle({ fontSize: "12px" });
        this.add(text_6);

        // text_7
        const text_7 = new Text(scene, 0, 154);
        text_7.text = "Damage point: {}";
        text_7.setStyle({ fontSize: "12px" });
        this.add(text_7);

        // text_8
        const text_8 = new Text(scene, 2, 271);
        text_8.text = "tap anywhere to continue...";
        text_8.setStyle({ fontSize: "12px" });
        this.add(text_8);

        this.bg = bg;

        /* START-USER-CTR-CODE */
        bg.setInteractive();
        bg.once("pointerdown", () => {
            this.destroy();
        });
        if (!this.config) {
            // TODO: user win the game
            return;
        }
        chapterId.text = this.config.name;
        nextBossName.text = this.config.boss.name;
        nextBoss.setTexture(this.config.boss.texture[0], this.config.boss.texture[1]);
        nextCreepName.text = this.config.creep.name;
        nextCreep.setTexture(this.config.creep.texture[0], this.config.creep.texture[1]);
        /* END-USER-CTR-CODE */
    }

    private bg: Phaser.GameObjects.Rectangle;

    /* START-USER-CODE */

    get config(): NextChapterConfig {
        return this.scene.chapterManager.getData()!.nextChapter as NextChapterConfig;
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
