import { EventBus, EVENTS } from "@/lib/event-bus";
import { CUSTOM_EVENTS, EventBusComponent } from "../events/EventBusComponent";
import { WeaponId } from "@/shared/types/loot-box";

export class AudioComponent {
    game: Phaser.Game;
    enabled: boolean = true;
    eventBusComponent: typeof EventBus;

    constructor(game: Phaser.Game) {
        this.game = game;
        this.eventBusComponent = EventBus;

        this.eventBusComponent.on(EVENTS.CURRENT_SCENE_READY, this.playBgSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.PLAYER_SHOOT, this.playShootSound, this);
        this.eventBusComponent.on(CUSTOM_EVENTS.CLAIM_REWARD, this.claimRewardSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.BOSS_FIGHT_START, this.playBossFightStartSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.BOSS_FIGHT_OVER, this.playBossFightOverSound, this);

        this.eventBusComponent.on(CUSTOM_EVENTS.BOSS_FIGHT_WIN, this.playBossFightWinSound, this);
    }

    disableSound() {
        this.enabled = false;
        this.game.sound.stopAll();
    }

    enableSound() {
        this.enabled = true;
        this.playBgSound();
    }

    playBgSound() {
        this.game.sound.play("bg", {
            volume: 0.4,
            loop: true,
        });
    }

    playBossFightStartSound() {
        if (!this.enabled) {
            return;
        }
        this.game.sound.play("boss_fight_start", {
            volume: 0.5,
        });
    }

    playBossFightOverSound() {
        if (!this.enabled) {
            return;
        }
        this.game.sound.play("boss_fight_over", {
            volume: 0.5,
        });
    }

    playBossFightWinSound() {
        if (!this.enabled) {
            return;
        }
        this.game.sound.play("boss_fight_win", {
            volume: 0.5,
        });
    }

    playShootSound(weaponId: WeaponId) {
        if (!this.enabled) {
            return;
        }
        this.game.sound.play(`player_${weaponId}_shoot`, {
            volume: 0.2,
        });
    }

    claimRewardSound() {
        if (!this.enabled) {
            return;
        }
        this.game.sound.play("coin_recieved", {
            volume: 0.6,
        });
    }
}
