import { InputComponent } from "./InputComponent";

/**
 * A custom `InputComponent` that is used for the `Fighter` enemy
 * type. This class is responsible for building out the simple AI
 * that will be used for moving the enemy game object in the game.
 *
 * For the `Fighter` enemy, the enemy will just moved in a straight
 * path down the screen and will fire their weapon.
 */
export class BossInputComponent extends InputComponent {
    constructor() {
        super();
        this._left = true;
        this._shoot = true;
    }

    // set rightIsDown(val: boolean) {
    //     console.log("rightIsDown", val);
    //     this._right = val;
    // }

    // set leftIsDown(val: boolean) {
    //     console.log("leftIsDown", val);
    //     this._left = val;
    // }

    update() {
        //
    }
}
