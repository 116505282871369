import { Events } from "phaser";

// Used to emit events between React components and Phaser scenes
// https://newdocs.phaser.io/docs/3.70.0/Phaser.Events.EventEmitter
export const EventBus = new Events.EventEmitter();

export const EVENTS = {
    AUTH_INIT_DATA: "auth-init-data",
    READY_FOR_AUTH_DATA: "ready-for-auth-data",
    CURRENT_SCENE_READY: "current-scene-ready",
    GO_BACK: "go-back",
    OPEN_REMIS_WHEEL: "open-remis-wheel",
};
