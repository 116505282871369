// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
import Boss from "../Boss";
import Text from "../../../../ota-slots/prefabs/Text";
import ChargingCountdown from "./ChargingCountdown";
/* START-USER-IMPORTS */
import PushComponent from "../../components/animations/PushComponent";
/* END-USER-IMPORTS */

export default class BoostMining extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 204, y ?? 121);

        // image_1
        const image_1 = scene.add.image(4, 0, "boss_base", "Boss.png");
        this.add(image_1);

        // boss
        const boss = new Boss(scene, 5, -4);
        boss.scaleX = 0.3;
        boss.scaleY = 0.3;
        this.add(boss);

        // text_2
        const text_2 = new Text(scene, 5, 5);
        text_2.text = "Boss";
        text_2.setStyle({ fontSize: "14px" });
        this.add(text_2);

        // bossLevel
        const bossLevel = new Text(scene, 4, 22);
        bossLevel.text = "Level 1";
        bossLevel.setStyle({ fontSize: "11px" });
        this.add(bossLevel);

        // frame_427318440_png
        const frame_427318440_png = scene.add.image(-7, 47, "boss_charger", "Frame 427318440.png");
        this.add(frame_427318440_png);

        // chargingCountdown
        const chargingCountdown = new ChargingCountdown(scene, 10, 47);
        this.add(chargingCountdown);

        // fightButton
        const fightButton = scene.add.image(12, 46, "boss_charger", "fight_button.png");
        fightButton.visible = false;
        this.add(fightButton);

        this.bossLevel = bossLevel;
        this.chargingCountdown = chargingCountdown;
        this.fightButton = fightButton;

        /* START-USER-CTR-CODE */
        this.setInteractive(new Phaser.Geom.Rectangle(-50, -50, 100, 150), Phaser.Geom.Rectangle.Contains);
        this.on("pointerdown", () => {
            if (this.scene.userManager.getData()) {
                this.scene.scene.start("MiningBossCharger");
            }
        });
        new PushComponent(
            fightButton,
            () => {
                this.scene.scene.start("MiningBossCharger");
            },
            this,
        ).active();
        this.chargingCountdown.text.setStyle({ fontSize: "10px" });
        /* END-USER-CTR-CODE */
    }

    private bossLevel: Text;
    private chargingCountdown: ChargingCountdown;
    private fightButton: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    updateBossFightStatus() {
        const fightButton = this.fightButton;
        const chargingCountdown = this.chargingCountdown;
        this.chargingCountdown.updateBossFightStatus(() => {
            fightButton.visible = true;
            chargingCountdown.visible = false;
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
