// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import Text from "./Text";
import { claimMiningReward } from "@/lib/api/telegram-backend/methods/mining";
/* END-USER-IMPORTS */

export default class ClaimPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // background
        const background = scene.add.rectangle(195, 422, 390, 844);
        background.isFilled = true;
        background.fillColor = 0;
        background.fillAlpha = 0.5;
        this.add(background);

        this.background = background;

        /* START-USER-CTR-CODE */

        /* END-USER-CTR-CODE */
    }

    private background: Phaser.GameObjects.Rectangle;

    /* START-USER-CODE */

    async claimReward() {
        const win = this.scene.add.sprite(
            195,
            468, // 97
            "you_win",
            "you_win_coins_anim_00000.png",
        );

        win.scaleX = 0.4;
        win.scaleY = 0.4;
        win.setDepth(1);
        win.play("you_win");
        this.add(win);

        const response = await claimMiningReward();

        if (!response) {
            this.destroy();
            return;
        }

        this.scene.registry.set("user", response.user);
        const coinAmount = response.reward.otaGold;
        const rmxAmount = response.reward.rmx;

        // winAmountText
        const coinAmountText = new Text(this.scene, 195, 485);
        coinAmountText.setOrigin(0.5, 0.5);
        coinAmountText.text = "0";
        coinAmountText.setStyle({
            color: "#f3e192",
            fontSize: "24px",
            stroke: "#f3e192",
        });
        this.add(coinAmountText);

        const rmxAmountText = new Text(this.scene, 195, 540);
        rmxAmountText.setOrigin(0.5, 0.5);
        rmxAmountText.text = "0";
        rmxAmountText.setStyle({
            color: "#f3e192",
            fontSize: "24px",
            stroke: "#f3e192",
        });
        this.add(rmxAmountText);

        this.scene.tweens.addCounter({
            from: 0,
            to: coinAmount,
            duration: 500,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = tween.getValue().toFixed(3);
                coinAmountText.setText(value);
            },
            onComplete: () => {
                const coinImage = this.scene.add.image(
                    195 + coinAmountText.width / 2 + 30,
                    485,
                    "mining",
                    "Coin4 1.png",
                );
                this.add(coinImage);
            },
        });

        this.scene.tweens.addCounter({
            from: 0,
            to: rmxAmount,
            duration: 600,
            ease: Phaser.Math.Easing.Linear,
            onUpdate: (tween) => {
                const value = tween.getValue().toFixed(3);
                rmxAmountText.setText(value);
            },
            onComplete: () => {
                const rmxImage = this.scene.add.image(
                    195 + rmxAmountText.width / 2 + 30,
                    540,
                    "mining",
                    "artifacts_05@2x.png",
                );
                this.add(rmxImage);

                this.background.setInteractive();
                this.background.on("pointerdown", () => {
                    this.destroy();
                });
            },
        });
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
