import { DEPTH_PRIORITY } from "@/ota-slots/constants";

import NewItem from "./NewItem";
import UpgradeItem from "./UpgradeItem";
import DowngradeItem from "./DowngradeItem";
import { InventoryItem } from "@/shared/types/loot-box";
import { compareItemAttackPower } from "@/shared/utils/loot-box";

/* START OF COMPILED CODE */

/* START-USER-IMPORTS */

/* END-USER-IMPORTS */

export default class LootBoxPopup extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // window
        const window = scene.add.image(195, 645, "overlay");
        window.scaleX = 1.0085186738855443;
        window.scaleY = 1.0085186738855443;
        this.add(window);

        /* START-USER-CTR-CODE */
        // Write your code here.
        this.setDepth(DEPTH_PRIORITY.DISPLAY_POPUP);
        /* END-USER-CTR-CODE */
    }

    /* START-USER-CODE */

    open(rewardItem: InventoryItem) {
        const currentSlotItem = this.scene.userManager.getInventoryItemBySlot(rewardItem.slot);
        console.log({ currentSlotItem, rewardItem, inventory: this.scene.userManager.getInventoryItems() });
        // If there's no inventory item for this reward item slot, then this is a new item for this slot
        if (!currentSlotItem) {
            const ui = new NewItem(this.scene, 195, 197);
            ui.setItem(rewardItem);
            this.add(ui);
            ui.fadeIn();
            ui.once("collect", () => {
                ui.fadeOut(500, () => {
                    this.emit("collect");
                    this.destroy();
                });
            });
            return;
        }

        // Determine whether the new item is better than the current one
        const itemComparisonResult = compareItemAttackPower(currentSlotItem, rewardItem);

        if (itemComparisonResult === "upgrade") {
            const ui = new UpgradeItem(this.scene, 195, 197);
            ui.setComparisonItems(currentSlotItem, rewardItem);
            this.add(ui);

            ui.once("sell", () => {
                ui.fadeOut(500, () => {
                    this.emit("sell");
                    this.destroy();
                });
            });

            ui.once("equip", () => {
                ui.fadeOut(500, () => {
                    this.emit("equip");
                    this.destroy();
                });
            });

            this.scene.time.delayedCall(1000, () => {
                ui.startTransitionAnimation();
            });
        } else if (itemComparisonResult === "downgrade" || itemComparisonResult === "neutral") {
            const ui = new DowngradeItem(this.scene, 195, 197);
            ui.setComparisonItems(currentSlotItem, rewardItem);
            this.add(ui);

            ui.once("sell", () => {
                ui.fadeOut(500, () => {
                    this.emit("sell");
                    this.destroy();
                });
            });

            this.scene.time.delayedCall(1000, () => {
                ui.startTransitionAnimation();
            });
        }
    }
    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
