// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class SpinButton extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 0, y ?? 0);

        // spin_button_back_png
        const spin_button_back_png = scene.add.image(195, 67, "buttons_interface", "spin_button_back.png");
        spin_button_back_png.scaleX = 0.5;
        spin_button_back_png.scaleY = 0.5;
        this.add(spin_button_back_png);

        // spinButton
        const spinButton = scene.add.sprite(195, 75, "buttons_interface", "spin_button_04.png");
        spinButton.scaleX = 0.5;
        spinButton.scaleY = 0.5;
        this.add(spinButton);

        this.spinButton = spinButton;

        /* START-USER-CTR-CODE */
        this.setSpinButtonInteractive();
        this.spinButton.on("pointerdown", this.onButtonDown, this);
        this.spinButton.on("pointerup", this.onButtonUp, this);
        // this.spinButton.on("pointerout", this.onButtonUp, this);
        /* END-USER-CTR-CODE */
    }

    private spinButton: Phaser.GameObjects.Sprite;

    /* START-USER-CODE */
    isHolding: boolean;
    holdTimer: Phaser.Time.TimerEvent | null;

    clearHoldTimer() {
        if (this.holdTimer) {
            this.holdTimer.remove();
            this.holdTimer = null;
        }
    }

    onButtonDown() {
        // @ts-ignore
        if (!this.scene.isSlotsGameReady) return;
        // Start a timer to check for hold duration
        this.holdTimer = this.scene.time.addEvent({
            delay: 1000, // Duration to consider it a hold (in milliseconds)
            callback: this.onHoldAction,
            callbackScope: this,
            loop: false,
        });
    }

    onButtonUp() {
        if (this.holdTimer) {
            this.clearHoldTimer();
            if (this.isHolding) {
                this.isHolding = false;
                this.emit("auto-spin-stop");
                this.spinButton.setTexture("buttons_interface", "spin_button_04.png");
                this.setSpinButtonUninteractive();
                return;
            }
            this.onClickAction();
        }
    }

    onHoldAction() {
        console.log("Button is being held down", this.isHolding);
        if (this.isHolding) return;
        // Stop the hold timer if it exists
        this.clearHoldTimer();
        // Perform action for hold
        this.isHolding = true;
        this.setSpinButtonUninteractive();
        this.spinButton.play({ key: "spin_button_idle", repeat: 0 });
        this.spinButton.once("animationcomplete", () => {
            this.spinButton.stop();
            this.spinButton.setFrame(0);
            this.spinButton.setTexture("buttons_interface", "spin_button_stop_01.png");
            this.setSpinButtonInteractive();
            this.emit("auto-spin");
        });
    }

    onClickAction() {
        console.log("Button clicked", this.isHolding);
        // Perform action for click

        this.spinButton.play("spin_button_pressed");

        this.scene.time.delayedCall(0, async () => {
            this.setSpinButtonUninteractive();
            this.emit("normal-spin");
        });
    }

    public resetSpinButton() {
        this.setSpinButtonInteractive();
        this.spinButton.stop();
        this.spinButton.setTexture("buttons_interface", "spin_button_01.png");
    }

    public getIsInteractive() {
        return this.spinButton.input?.enabled;
    }

    public setSpinButtonInteractive() {
        console.log("Setting spin button interactive");
        this.spinButton.setInteractive();
    }

    public setSpinButtonUninteractive() {
        console.log("Setting spin button uninteractive");
        this.spinButton.disableInteractive();
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
