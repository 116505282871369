// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
import PickBonus from "@/ota-slots/scenes/PickBonus";
import { PickBonusType } from "@/ota-slots/utils/random-pick-bonus";
/* END-USER-IMPORTS */

export default class SpaceShip extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 145, y ?? 30);

        // fire
        const fire = scene.add.sprite(53, 48, "spaceship_fire", "spaceship_fire_01@2x.png");
        fire.scaleX = 0.25;
        fire.scaleY = 0.25;
        fire.play("spaceship_fire");
        this.add(fire);

        // spaceShip
        const spaceShip = scene.add.sprite(52, 6, "spaceship");
        spaceShip.scaleX = 0.35;
        spaceShip.scaleY = 0.35;
        this.add(spaceShip);

        this.fire = fire;
        this.spaceShip = spaceShip;

        /* START-USER-CTR-CODE */
        this.spaceShip.setInteractive();
        this.spaceShip.on("pointerdown", this.handlePointerDown, this);
        /* END-USER-CTR-CODE */
    }

    private fire: Phaser.GameObjects.Sprite;
    private spaceShip: Phaser.GameObjects.Sprite;
    public index: number = 0;
    public type: "grand" | "mini" | "minor" | "major" | "upgrade_chance" | "double_grand" | "" = "";

    /* START-USER-CODE */
    public state: "idle" | "played" | "upgraded" = "idle";

    handlePointerDown = () => {
        if (this.state === "idle") {
            (this.scene as PickBonus).handlePointerDown(this);
        }
    };

    handleUpgradePickBonusType(pickType: PickBonusType): PickBonusType {
        switch (pickType) {
            case "mini":
                return "minor";
            case "minor":
                return "major";
            case "major":
                return "grand";
            case "grand":
                return "double_grand";
            default:
                return pickType;
        }
    }

    playAnimation = () => {
        if (!this.type) return;

        this.fire.setVisible(false);
        this.spaceShip.play(this.type);
    };

    upgradeAnimation = () => {
        if (!this.type || this.type === "upgrade_chance" || this.type === "double_grand") {
            return;
        }

        const upgradeType = this.handleUpgradePickBonusType(this.type);
        this.type = upgradeType;
        this.spaceShip.play(upgradeType);
    };

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
