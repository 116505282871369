import { MiningBossManager } from "./shared/registry/mining-boss";
import { ChapterManager } from "./shared/registry/chapter";
import { UserManager } from "./shared/registry/user";
import { SettingsManager } from "./shared/registry/settings";

// Extend Phaser.Scene to include our plugin
declare module "phaser" {
    interface Scene {
        miningBossManager: MiningBossManager;
        chapterManager: ChapterManager;
        userManager: UserManager;
        settingsManager: SettingsManager;
    }
}

export const gamePlugins = {
    scene: [
        {
            key: "MiningBossManager",
            plugin: MiningBossManager,
            mapping: "miningBossManager",
        },
        {
            key: "ChapterManager",
            plugin: ChapterManager,
            mapping: "chapterManager",
        },
        {
            key: "UserManager",
            plugin: UserManager,
            mapping: "userManager",
        },
        {
            key: "SettingsManager",
            plugin: SettingsManager,
            mapping: "settingsManager",
        },
    ],
} as const;
