import WebFont from "webfontloader";
import { Loader } from "phaser";

export class WebFontFile extends Loader.File {
    private _config?: WebFont.Config;

    constructor(loader: Loader.LoaderPlugin, key: string, config?: WebFont.Config) {
        super(loader, {
            type: "webfont",
            key: key,
        });

        this._config = config;
    }

    load(): void {
        const config: WebFont.Config = {};

        if (this._config) {
            Object.assign(config, this._config);
        } else {
            config.custom = {
                families: [this.key],
            };
        }

        config.active = () => this.loader.nextFile(this, true);
        config.inactive = () => this.loader.nextFile(this, false);

        WebFont.load(config);
    }
}
