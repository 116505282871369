// You can write more code here

/* START OF COMPILED CODE */

import Phaser from "phaser";
/* START-USER-IMPORTS */
/* END-USER-IMPORTS */

export default class BossStackItem extends Phaser.GameObjects.Container {
    constructor(scene: Phaser.Scene, x?: number, y?: number) {
        super(scene, x ?? 33, y ?? 22);

        // image_1
        const image_1 = scene.add.image(-8.263091514381074, -11.601647950221889, "boss_stack", "Line 10.png");
        this.add(image_1);

        // checked
        const checked = scene.add.image(-23.263091514381074, -12.601647950221889, "boss_stack", "Frame 427318368.png");
        this.add(checked);

        // boss
        const boss = scene.add.image(-23.263091514381074, -10.601647950221889, "boss_stack", "Frame 427318371.png");
        this.add(boss);

        this.checked = checked;
        this.boss = boss;

        /* START-USER-CTR-CODE */
        // Write your code here.
        /* END-USER-CTR-CODE */
    }

    private checked: Phaser.GameObjects.Image;
    private boss: Phaser.GameObjects.Image;

    /* START-USER-CODE */

    init(checked: boolean) {
        this.boss.setVisible(!checked);
        this.checked.setVisible(checked);
    }

    /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
